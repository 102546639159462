import React, { FC } from 'react'
import { CylinderFormFieldsKey } from '@contracts/job'
import {
  useDCylinderTypeId,
  useDFormCylinderId,
  useDFormOptionList,
  useDFormTypeJobId,
  useDPhotoMarkColorId,
  useDPhotoMarkSizeId,
  useDPhotoMarkTypeId,
  useDTravelLengthColorId,
} from '@services/dynamic'
import { FormInput, FormSelect, Tooltip } from 'yolo-design/components'
import { Question } from 'yolo-design/icons'
import { restrictToNumbersAndDots } from '@utils/input'

const CylinderFormFields: FC<{ name: CylinderFormFieldsKey; props?: any }> = ({
  name,
  props,
}) => {
  const jobFormId = useDFormTypeJobId() || ''
  const cylinderId = useDFormCylinderId(jobFormId) || ''
  const cylinderTypeKeyId = useDCylinderTypeId({ formId: cylinderId }) || ''
  const photoMarkSizeKeyId = useDPhotoMarkSizeId({ formId: cylinderId }) || ''
  const photoMarkTypeKeyId = useDPhotoMarkTypeId({ formId: cylinderId }) || ''
  const photoMarkColorKeyId = useDPhotoMarkColorId({ formId: cylinderId }) || ''
  const travelLengthColorKeyId =
    useDTravelLengthColorId({ formId: cylinderId }) || ''

  const cylinderTypeOptions = useDFormOptionList(cylinderTypeKeyId)
  const photoMarkSizeOptions = useDFormOptionList(photoMarkSizeKeyId)
  const photoMarkTypeOptions = useDFormOptionList(photoMarkTypeKeyId)
  const photoMarkColorOptions = useDFormOptionList(photoMarkColorKeyId)
  const travelLengthColorOptions = useDFormOptionList(travelLengthColorKeyId)

  return (
    <>
      {name === 'cylinder_type' && (
        <FormSelect
          {...props}
          required
          name={'cylinder_type'}
          placeHolder="Select From Option"
          title="Cylinder Type"
          optionsSelect={cylinderTypeOptions}
          antdSelectProps={{ bordered: true }}
        />
      )}
      {name === 'cylinder_length' && (
        <FormInput
          {...props}
          required
          placeHolder="0mm"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'cylinder_length'}
          title={'Cylinder Length'}
        />
      )}
      {name === 'cylinder_circumference' && (
        <FormInput
          {...props}
          required
          placeHolder="0mm"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'cylinder_circumference'}
          title={
            <div className="d-flex align-items-center">
              <span className="text-label-small fw-medium text-dark">
                Cylinder Circumference
              </span>
              <Tooltip
                className="ms-4"
                placement="right"
                title="Circumference of the cylinder is the measurement of the boundary of the cylinder"
              >
                <Question size="small" weight="slim" />
              </Tooltip>
            </div>
          }
        />
      )}
      {name === 'job_width' && (
        <FormInput
          {...props}
          required
          placeHolder="0mm"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'job_width'}
          title={'Job Width'}
        />
      )}
      {name === 'no_of_steps' && (
        <FormInput
          {...props}
          required
          placeHolder="1"
          inputType="number"
          name={'no_of_steps'}
          title={
            <div className="d-flex align-items-center">
              <span className="text-label-small fw-medium text-dark">
                No of Steps
              </span>
              <Tooltip className="ms-4" placement="right" title="No of Steps">
                <Question size="small" weight="slim" />
              </Tooltip>
            </div>
          }
        />
      )}
      {name === 'job_height' && (
        <FormInput
          {...props}
          required
          placeHolder="0mm"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'job_height'}
          title={'Job Height'}
        />
      )}
      {name === 'no_of_repeat' && (
        <FormInput
          {...props}
          required
          placeHolder="1"
          inputType="number"
          name={'no_of_repeat'}
          title={
            <div className="d-flex align-items-center">
              <span className="text-label-small fw-medium text-dark">
                No of Repeat
              </span>
              <Tooltip className="ms-4" placement="right" title="Dummy">
                <Question size="small" weight="slim" />
              </Tooltip>
            </div>
          }
        />
      )}
      {name === 'shrinkage' && (
        <FormInput
          {...props}
          required
          placeHolder="11mm"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'shrinkage'}
          title={'Shrinkage'}
        />
      )}
      {name === 'total_printing_width' && (
        <FormInput
          {...props}
          placeHolder="00mm"
          inputType="number"
          readOnly
          name={'total_printing_width'}
          title={
            <div className="d-flex align-items-center">
              <span className="text-label-small fw-medium text-dark">
                Total Printing Width
              </span>
              <Tooltip
                className="ms-4"
                placement="right"
                title="(Job Width * No of Steps) + 15"
              >
                <Question size="small" weight="slim" />
              </Tooltip>
            </div>
          }
        />
      )}
      {name === 'photocell_mark_size' && (
        <FormSelect
          {...props}
          required
          name={`photocell_mark_size`}
          placeHolder="Select From Option"
          title="Photocell Mark Size"
          // antdSelectProps={{
          //   dropdownRender:(menu) =>{

          //     return  <>
          //       {menu}
          //       <Space
          //           className="border-1 border-top w-100 border-grey-200 d-flex align-items-center cursor-pointer p-8"
          //           onClick={() => {
          //             // setIsCreateVendorOrCustomerModalOpen(true);
          //             // setDropdownVisible(false);
          //           }}

          //         >
          //       <Button  size='large'   type='ghost' icon={Add} label='Add Size'/>
          //       </Space>

          //       </>

          //   }
          // }}
          optionsSelect={photoMarkSizeOptions}
        />
      )}
      {name === 'photocell_mark_color' && (
        <FormSelect
          {...props}
          required
          name={`photocell_mark_color`}
          placeHolder="Select From Option"
          title="Photocell Mark Color"
          optionsSelect={photoMarkColorOptions}
        />
      )}
      {name === 'photocell_mark_type' && (
        <FormSelect
          {...props}
          required
          name={`photocell_mark_type`}
          placeHolder="Select From Option"
          title="Photocell Mark Type"
          optionsSelect={photoMarkTypeOptions}
        />
      )}
      {name === 'travel_length_color' && (
        <FormSelect
          {...props}
          required
          name={`travel_length_color`}
          placeHolder="Select From Option"
          title="Travel Length Color"
          optionsSelect={travelLengthColorOptions}
        />
      )}
      {name === 'photocell_travel_length' && (
        <FormInput
          {...props}
          required
          placeHolder="0mm"
          inputType="text"
          name={'photocell_travel_length'}
          title={'Photocell Travel Length'}
        />
      )}
    </>
  )
}

export default CylinderFormFields
