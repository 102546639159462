import { queries } from '@constants/queryKeys'
import { IDFormKeyValue } from '@contracts/models/dynamic'
import useMapFieldKey from '@hooks/useMapFieldKey'
import {
  useCreateDFormKeyValue,
  useDColorId,
  useDFormColorId,
  useDFormKeyValue,
  useDFormProcessId,
  useDFormTypeJobId,
  useDeleteDListItem,
  useUpdateDFormKeyValue,
  useUpsertDListItem,
} from '@services/dynamic'
import { useQueryClient } from '@tanstack/react-query'
import { Form } from 'antd'
import nProgress from 'nprogress'
import { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FormWrapper } from 'yolo-design/components'
import Add from './Add'
import ColorItem from './Item'
import { convertKgToMtr } from '@utils/formula'

const Colors: FC<{ data: IDFormKeyValue }> = ({ data }) => {
  const [form] = Form.useForm()
  const colorsList = data?.colors || []
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const [isCreating, setIsCreating] = useState(false)

  const editId = searchParams.get('edit_id') || ''
  const editField = searchParams.get('edit_field') || ''
  const editData = colorsList?.find((f: any) => String(f.id) === editId)?.data
  const editInkId = editData?.ink?.form_entity
  const editCylinderId = colorsList?.find((f: any) => String(f.id) === editId)
    ?.data?.cylinder?.form_entity

  const jobId = searchParams.get('job') || ''
  const addColor = (searchParams.get('add_color') || '') === 'true'

  const formTypeId = useDFormTypeJobId() || ''
  const colorFormId = useDFormColorId(formTypeId) || ''
  const processFormId = useDFormProcessId(formTypeId) || ''
  const mapFieldKey = useMapFieldKey({ formId: colorFormId })
  const mapProcessFieldKey = useMapFieldKey({ formId: processFormId })
  const colorKeyId = useDColorId({ formId: colorFormId }) || ''

  const { data: jobData, refetch } = useDFormKeyValue(
    {
      enabled: !!jobId,
      staleTime: 0,
    },
    { formId: jobId }
  )

  const handleSuccess = () => {
    nProgress.start()

    queryClient
      .invalidateQueries({
        queryKey: queries.dynamic.form_key_value(jobId).queryKey,
      })
      .finally(() => {
        nProgress.done()
        setIsCreating(false)
      })
  }

  const updateForm = useUpdateDFormKeyValue()

  const updateBobbinMeter = (totalGSM: number) => {
    updateForm.mutate(
      {
        data: mapProcessFieldKey({
          bobbin_meter: convertKgToMtr({
            flimWidth: Number(jobData?.cylinder_details?.job_width),
            outputKg: jobData?.process_and_details.bobbin_weight,
            totalGSM: totalGSM,
          }),
        }),
        formId: jobData?.process_and_details?.id,
      },
      {
        onSuccess: (res) => {
          queryClient.setQueryData<IDFormKeyValue>(
            queries.dynamic.form_key_value(jobId).queryKey,
            (prev) => ({
              ...prev,
              process_and_details: {
                ...res,
                id: jobData?.process_and_details?.id,
              },
            })
          )
        },
      }
    )
  }

  const createFormKeyValue = useCreateDFormKeyValue({
    onSuccess: (data: any) => {
      if (
        !!jobData?.process_and_details?.id &&
        !!jobData?.cylinder_details?.id
      ) {
        updateBobbinMeter(data?.total_thickness_gsm)
      }

      searchParams.delete('edit_id')
      searchParams.delete('edit_field')
      searchParams.delete('add_color')
      setSearchParams(searchParams)
      handleSuccess()
    },
    onSettled: () => {
      setIsCreating(false)
    },
  })

  const upsertListItem = useUpsertDListItem({
    onSuccess: async () => {
      const { data: jobData } = await refetch()

      if (
        !!jobData?.process_and_details?.id &&
        !!jobData?.cylinder_details?.id
      ) {
        updateBobbinMeter(jobData?.bill_of_materials?.total_thickness_gsm)
      }

      searchParams.delete('edit_id')
      searchParams.delete('edit_field')
      searchParams.delete('add_color')
      setSearchParams(searchParams)
      handleSuccess()
    },
  })

  const deleteListItem = useDeleteDListItem({
    onSuccess: async () => {
      const { data: jobData } = await refetch()

      if (
        !!jobData?.process_and_details?.id &&
        !!jobData?.cylinder_details?.id
      ) {
        updateBobbinMeter(jobData?.bill_of_materials?.total_thickness_gsm)
      }

      handleSuccess()
    },
  })

  // const reorder = useChangeOrder({
  //   onSuccess: () => {
  //     queryClient
  //       .invalidateQueries({
  //         queryKey: queries.dynamic.form_key_value(jobId).queryKey,
  //       })
  //       .finally(() => {
  //         nProgress.done()
  //       })
  //   },
  //   onMutate: (res) => {
  //     queryClient.setQueriesData(
  //       queries.dynamic.form_key_value(jobId).queryKey,
  //       (prev: Record<string, any>) => {
  //         if (prev) {
  //           const colors = prev.color?.colors
  //           const ind = colors?.findIndex((f: any) => f.id === res.id)

  //           if (ind !== -1) {
  //             colors[ind].data.order = res.position
  //             prev.color.colors = colors
  //           }

  //           return prev
  //         } else {
  //           return prev
  //         }
  //       }
  //     )
  //   },
  // })

  const handleSubmit = (val: any) => {
    let postData

    if (data?.id) {
      const updateDataRaw = colorsList?.find(
        (f: any) => String(f.id) === editId
      )
      const updateData = {
        id: updateDataRaw?.id,
        data: {
          ink: updateDataRaw?.data?.ink?.id,
          cylinder: updateDataRaw?.data?.cylinder?.id,
          order: updateDataRaw?.data?.order,
          description: updateDataRaw?.data?.description,
        },
      }

      if (editField === 'ink') {
        postData = {
          ...updateData,
          data: {
            ...updateData?.data,
            ink: val?.ink,
            description: val?.description,
          },
        }
      } else if (editField === 'cylinder') {
        postData = {
          ...updateData,
          data: { ...updateData?.data, cylinder: val?.cylinder },
        }
      } else if (editField === 'order') {
        postData = {
          ...updateData,
          data: { ...updateData?.data, order: val?.order },
        }
      } else {
        postData = { data: val }
      }

      upsertListItem.mutate({
        data: postData,
        path: { entityId: data?.id, keyId: colorKeyId },
      })
    } else {
      postData = {
        colors: [val],
      }

      setIsCreating(true)
      createFormKeyValue.mutate({
        data: mapFieldKey(postData),
        formId: colorFormId,
        params: {
          parent_id: jobId,
        },
      })
    }
  }

  const handleDelete = (id: string) => {
    nProgress.start()

    deleteListItem.mutate({ id })
  }

  // const handleReorder: OnDragEndResponder = (result) => {
  //   if (
  //     result.destination?.index !== undefined &&
  //     result.destination?.index !== null
  //   ) {
  //     nProgress.start()
  //     reorder.mutate({
  //       id: result.draggableId,
  //       position: result.destination?.index,
  //     })
  //   }
  // }

  useEffect(() => {
    if (editInkId) {
      form.setFieldValue('ink', editInkId)
      form.setFieldValue('description', editData?.description)
      form.setFieldValue('order', editData?.order)
    }

    if (editCylinderId) {
      form.setFieldValue('cylinder', editCylinderId)
      form.setFieldValue('order', editData?.order)
    }

    if (editId === 'order') {
      form.setFieldValue('order', editData?.order)
    }
  }, [editInkId, editCylinderId])

  useEffect(() => {
    form.resetFields()
  }, [addColor])

  return (
    <FormWrapper
      antdFormProps={{ form, className: 'w-100', onFinish: handleSubmit }}
    >
      <div className="d-flex flex-column p-24 w-100">
        {addColor && (
          <Add
            colorsList={colorsList}
            isUpdate={true}
            loading={
              createFormKeyValue.isLoading ||
              isCreating ||
              upsertListItem.isLoading
            }
          />
        )}
        {/* <Add
            isUpdate={!!data?.id && colorsList?.length !== 0}
            loading={
              createFormKeyValue.isLoading ||
              isCreating ||
              upsertListItem.isLoading
            }
          /> */}
        {/* <DragDropContext onDragEnd={handleReorder}>
          <Droppable droppableId="colors">
            {(provided) => (
              <div
                className="d-flex flex-column"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {colorsList
                  ?.sort((a: any, b: any) => a?.data?.order - b?.data?.order)
                  ?.map((el: any, i: number, arr: Array<any>) => (
                    <Draggable
                      key={el.id}
                      draggableId={el.id}
                      index={el?.data?.order}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <ColorItem
                            colorsList={arr}
                            handleDelete={handleDelete}
                            loading={upsertListItem.isLoading}
                            index={i}
                            key={el.id}
                            data={el}
                            provided={provided}
                            snapshot={snapshot}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext> */}
        <div className="d-flex flex-column">
          {colorsList
            ?.sort((a: any, b: any) => a?.data?.order - b?.data?.order)
            ?.map((el: any, i: number, arr: Array<any>) => (
              <ColorItem
                colorsList={arr}
                handleDelete={handleDelete}
                loading={upsertListItem.isLoading}
                index={i}
                key={el.id}
                data={el}
              />
            ))}
        </div>
      </div>
    </FormWrapper>
  )
}

export default Colors
