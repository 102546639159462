import React, { FC, useEffect, useState } from 'react'
import { FormWrapper, Select } from 'yolo-design/components'
import BOMItem from './Item'
import { IDFormKeyValue } from '@contracts/models/dynamic'
import {
  useChangeOrder,
  useCreateDFormKeyValue,
  useDBOMKeyId,
  useDFormBOMId,
  useDFormKeyValue,
  useDFormOptionList,
  useDFormProcessId,
  useDFormTypeJobId,
  useDPrintingTypeId,
  useDeleteDListItem,
  useUpdateDFormKeyValue,
  useUpsertDListItem,
} from '@services/dynamic'
import { useQueryClient } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import useMapFieldKey from '@hooks/useMapFieldKey'
import { queries } from '@constants/queryKeys'
import { Form } from 'antd'
import { useRMFlimAdhesiveList } from '@services/product'
import Add from './Add'
import nProgress from 'nprogress'
import { reorderBOM } from '@utils/dynamic'
import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
} from 'react-beautiful-dnd'
import { RAW_MATERIAL_FORM_CODE } from '@constants/dynamic'
import { convertKgToMtr } from '@utils/formula'

const BillOfMaterial: FC<{ data: IDFormKeyValue }> = ({ data }) => {
  const jobFormId = useDFormTypeJobId() || ''
  const [search, setSearch] = useState('')
  const bomId = useDFormBOMId(jobFormId) || ''
  const [printType, setPrintType] = useState<string>()
  const printingTypeKeyId = useDPrintingTypeId({ formId: bomId }) || ''
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isCreating, setIsCreating] = useState(false)

  const bomList = data?.bom
  const [form] = Form.useForm()

  const printingTypeOptions = useDFormOptionList(printingTypeKeyId)

  const jobId = searchParams.get('job') || ''
  const addBom = (searchParams.get('add_bom') || '') === 'true'
  const editId = searchParams.get('edit_id')

  const { data: jobData, refetch } = useDFormKeyValue(
    {
      enabled: !!jobId,
      staleTime: 0,
    },
    { formId: jobId }
  )

  const formTypeId = useDFormTypeJobId() || ''
  const bomFormId = useDFormBOMId(formTypeId) || ''
  const bomKeyId = useDBOMKeyId({ formId: bomFormId }) || ''
  const mapFieldKey = useMapFieldKey({ formId: bomFormId })
  const processFormId = useDFormProcessId(formTypeId) || ''
  const mapProcessFieldKey = useMapFieldKey({ formId: processFormId })

  const flimAdhesiveList = useRMFlimAdhesiveList({ search })

  const handleSuccess = () => {
    nProgress.start()

    queryClient
      .invalidateQueries({
        queryKey: queries.dynamic.form_key_value(jobId).queryKey,
      })
      .finally(() => {
        nProgress.done()
        setIsCreating(false)
      })
  }

  const updateForm = useUpdateDFormKeyValue({
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: queries.dynamic.form_key_value(jobId).queryKey,
        })
        .finally(() => {
          nProgress.done()
          setIsCreating(false)
        })
    },
    onError: () => {
      setIsCreating(false)
    },
  })

  const updateBobbinMeter = (totalGSM: number) => {
    updateForm.mutate(
      {
        data: mapProcessFieldKey({
          bobbin_meter: convertKgToMtr({
            flimWidth: Number(jobData?.cylinder_details?.job_width),
            outputKg: jobData?.process_and_details.bobbin_weight,
            totalGSM: totalGSM,
          }),
        }),
        formId: jobData?.process_and_details?.id,
      },
      {
        onSuccess: (res) => {
          queryClient.setQueryData<IDFormKeyValue>(
            queries.dynamic.form_key_value(jobId).queryKey,
            (prev) => ({
              ...prev,
              process_and_details: {
                ...res,
                id: jobData?.process_and_details?.id,
              },
            })
          )
        },
      }
    )
  }

  const createFormKeyValue = useCreateDFormKeyValue({
    onSuccess: async () => {
      const { data: jobData } = await refetch()
      if (
        !!jobData?.process_and_details?.id &&
        !!jobData?.cylinder_details?.id
      ) {
        updateBobbinMeter(jobData?.bill_of_materials?.total_thickness_gsm)
      }

      searchParams.delete('edit_id')
      searchParams.delete('add_bom')
      setSearchParams(searchParams)
      handleSuccess()
    },
    onError: () => {
      setIsCreating(false)
    },
  })

  const upsertListItem = useUpsertDListItem({
    onSuccess: async () => {
      const { data: jobData } = await refetch()
      if (
        !!jobData?.process_and_details?.id &&
        !!jobData?.cylinder_details?.id
      ) {
        updateBobbinMeter(jobData?.bill_of_materials?.total_thickness_gsm)
      }

      searchParams.delete('edit_id')
      searchParams.delete('add_bom')
      setSearchParams(searchParams)
      handleSuccess()
    },
    onError: () => {
      setIsCreating(false)
    },
  })

  const deleteListItem = useDeleteDListItem({
    onSuccess: async () => {
      const { data: jobData } = await refetch()
      if (
        !!jobData?.process_and_details?.id &&
        !!jobData?.cylinder_details?.id
      ) {
        updateBobbinMeter(jobData?.bill_of_materials?.total_thickness_gsm)
      }
      handleSuccess()
    },
  })

  const handleSubmit = (val: any) => {
    let postData

    const updateData = bomList?.find((f: any) => String(f.id) === editId)

    const layerDetails = flimAdhesiveList.data?.data?.find(
      (f) => String(f.form_entity) === val?.layer
    )

    const layerData = {
      layer: val?.layer,
      code: layerDetails?.form_code,
    }

    if (data?.id) {
      if (editId) {
        postData = {
          ...updateData,
          data: { ...updateData?.data, ...layerData },
        }
      } else {
        postData = {
          data: layerData,
        }
      }

      upsertListItem.mutate({
        data: postData,
        path: { entityId: data?.id, keyId: bomKeyId },
      })
    } else {
      postData = {
        bom: [layerData],
        printing_type: printingTypeOptions[0]?.value || '1',
      }
      setIsCreating(true)

      createFormKeyValue.mutate({
        data: mapFieldKey(postData),
        formId: bomFormId,
        params: {
          parent_id: jobId,
        },
      })
    }
  }

  const reorder = useChangeOrder({
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: queries.dynamic.form_key_value(jobId).queryKey,
        })
        .finally(() => {
          nProgress.done()
        })
    },
    onMutate: (res) => {
      queryClient.setQueriesData(
        queries.dynamic.form_key_value(jobId).queryKey,
        (prev: Record<string, any>) => {
          if (prev) {
            const bom = prev.bill_of_materials?.bom
            const ind = bom?.findIndex((f: any) => f.id === res.id)

            if (ind !== -1) {
              bom[ind].data.order = res.position
              prev.bill_of_materials.bom = bom
            }

            return prev
          } else {
            return prev
          }
        }
      )
    },
  })

  const handleDelete = (id: string) => {
    deleteListItem.mutate({ id })
  }

  const handleUpdatePrintType = (val: string) => {
    const postData = { printing_type: val }
    if (data?.id) {
      nProgress.start()
      updateForm.mutate({ data: mapFieldKey(postData), formId: data?.id })
    }
  }

  const handleReorder: OnDragEndResponder = (result) => {
    if (
      result.destination?.index !== undefined &&
      result.destination?.index !== null
    ) {
      nProgress.start()
      reorder.mutate({
        id: result.draggableId,
        position: result.destination?.index,
      })
    }
  }

  useEffect(() => {
    form.resetFields()
  }, [addBom])

  useEffect(() => {
    form.setFieldValue(
      'layer',
      bomList?.find((f: any) => String(f.id) === editId)?.data?.layer?.id
    )
  }, [editId])

  useEffect(() => {
    if (printingTypeOptions) {
      setPrintType(
        String(data?.printing_type?.code || printingTypeOptions[0]?.value)
      )
    }
  }, [printingTypeOptions])

  return (
    <div className="d-flex flex-column position-relative w-100">
      <div className="d-flex w-100 align-items-center px-24 pt-24">
        <span className="text-title-small fw-medium text-grey-700">
          Type of Printing:
        </span>
        <Select
          value={printType}
          bordered={false}
          options={printingTypeOptions}
          onChange={(value) => {
            handleUpdatePrintType(value)
          }}
          disabled={!data?.id}
          loading={updateForm.isLoading}
        />
      </div>
      <DragDropContext onDragEnd={handleReorder}>
        <FormWrapper
          antdFormProps={{ form, className: 'w-100', onFinish: handleSubmit }}
        >
          <Droppable droppableId="bom-layers">
            {(provided) => (
              <div
                ref={provided.innerRef}
                style={{ height: 'calc(100vh - 110px)' }}
                className="d-flex flex-column overflow-scroll p-24"
                {...provided.droppableProps}
              >
                {addBom && (
                  <div className="d-flex flex-column w-100 rounded-16 border border-grey-200 p-16 mb-16">
                    <Add
                      setSearch={setSearch}
                      flimAdhesiveList={flimAdhesiveList}
                      loading={
                        createFormKeyValue.isLoading ||
                        isCreating ||
                        upsertListItem.isLoading
                      }
                    />
                  </div>
                )}
                {reorderBOM(bomList, data?.printing_type?.code)?.map(
                  (el: any, i: number) => (
                    <React.Fragment key={el.id}>
                      {el?.data?.code === RAW_MATERIAL_FORM_CODE['ink'] ? (
                        <BOMItem
                          setSearch={setSearch}
                          flimAdhesiveList={flimAdhesiveList}
                          data={el}
                          index={i}
                          handleDelete={handleDelete}
                          loading={
                            createFormKeyValue.isLoading ||
                            isCreating ||
                            upsertListItem.isLoading
                          }
                        />
                      ) : (
                        <Draggable draggableId={el.id} index={el?.data?.order}>
                          {(provided, snapshot) => (
                            <div
                              key={el.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <BOMItem
                                setSearch={setSearch}
                                flimAdhesiveList={flimAdhesiveList}
                                data={el}
                                index={i}
                                handleDelete={handleDelete}
                                loading={
                                  createFormKeyValue.isLoading ||
                                  isCreating ||
                                  upsertListItem.isLoading
                                }
                                provided={provided}
                                snapshot={snapshot}
                              />
                            </div>
                          )}
                        </Draggable>
                      )}
                    </React.Fragment>
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </FormWrapper>
      </DragDropContext>
      <div className="d-flex position-absolute bg-light bottom-0 start-0 w-100 text-title-small fw-medium justify-content-between border-top border-grey-400 py-12 px-24">
        <span className="text-grey-700">Total Thickness:</span>
        <span className="text-dark">{data?.total_thickness_gsm || 0} GSM</span>
      </div>
    </div>
  )
}

export default BillOfMaterial
