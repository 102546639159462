import JobColorPrint from '@components/Print/JobColorV2'
import { useDFormKeyValue } from '@services/dynamic'
import { useOrgInfo } from '@services/org'
import { Divider } from 'antd'
import cn from 'classnames'
import { FC, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { Button, Popover } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { Edit, KababMenu, Print, Trash } from 'yolo-design/icons'
import EditItem from './Edit'
import FormFields from './FormFields'

const ColorItem: FC<{
  data: any
  index: number
  handleDelete: (id: string) => void
  loading: boolean
  colorsList: Array<any>
}> = ({ data, index, handleDelete, loading, colorsList }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isPrinting, setIsPrinting] = useState(false)
  const printRef = useRef(null)
  const jobId = searchParams.get('job') || ''

  const { data: jobData } = useDFormKeyValue(
    {
      enabled: !!jobId,
    },
    { formId: jobId }
  )

  const { data: orgData } = useOrgInfo()

  const jobCode = jobData?.job?.job_code

  const orgName = orgData?.name

  const handlePrint = useReactToPrint({
    documentTitle: data.cylinder_code + '-' + jobCode,
    content: () => printRef.current,
    pageStyle: '@page { size: 80mm 50mm; margin: 0mm;}',
    onBeforeGetContent: () => {
      setIsPrinting(true)
    },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
  })

  const inkData = data?.data?.ink
  const cylinderData = data?.data?.cylinder

  const inkFields = [
    {
      label: 'Ink Type',
      value: inkData?.ink_ink_type?.label,
    },
    {
      label: 'Grade',
      value: inkData?.ink_ink_grade,
    },
    {
      label: 'Color Code',
      value: inkData?.ink_color_code,
    },
  ]

  const cylinderFields = [
    {
      label: 'Length',
      value: cylinderData?.cylinder_cylinder_length,
    },
    {
      label: 'Cylinder Use',
      value: cylinderData?.cylinder_cylinder_use?.label,
    },
    {
      label: 'Cylinder Type',
      value: cylinderData?.cylinder_cylinder_type?.label,
    },
  ]

  const editId = searchParams.get('edit_id') || ''
  const editField = searchParams.get('edit_field') || ''

  const menuContent = (
    <div className="d-flex flex-column w-max-content px-4">
      <Button
        color={Color.neutral_color.black}
        antdButtonProps={{
          className: 'mb-12',
          onClick: handlePrint,
          loading: isPrinting,
        }}
        icon={Print}
        type="ghost"
      >
        Print
      </Button>
      <Button
        color={Color.neutral_color.black}
        antdButtonProps={{
          className: 'mb-12',
          onClick: () => {
            searchParams.delete('add_color')
            searchParams.set('edit_field', 'ink')
            searchParams.set('edit_id', data?.id)
            setSearchParams(searchParams)
          },
        }}
        icon={Edit}
        type="ghost"
      >
        Edit Color
      </Button>
      <Button
        color={Color.neutral_color.black}
        antdButtonProps={{
          className: 'mb-12',
          onClick: () => {
            searchParams.set('edit_field', 'cylinder')
            searchParams.set('edit_id', data?.id)
            setSearchParams(searchParams)
          },
        }}
        icon={Edit}
        type="ghost"
      >
        Edit Cylinder
      </Button>

      <Button
        antdButtonProps={{ onClick: () => handleDelete(data?.id), loading }}
        color={Color.indicators.error}
        icon={Trash}
        type="ghost"
      >
        Delete
      </Button>
    </div>
  )

  const displayFields = (el: any, i: number) => (
    <div
      key={i}
      style={{ width: `${100 / cylinderFields.length}%` }}
      className={cn('d-flex flex-column', {
        'border-start ps-12 border-grey-200': !(i === 0),
        'pe-12': i === 0,
      })}
    >
      <span className="text-label-small fw-medium text-grey-700">
        {el.label}
      </span>
      <span className="text-body-medium fw-medium text-dark mt-8">
        {el.value || '-'}
      </span>
    </div>
  )

  const handleCancel = () => {
    searchParams.delete('edit_id')
    searchParams.delete('edit_field')
    setSearchParams(searchParams)
  }

  return (
    <>
      <div
        className={cn(
          'd-flex flex-column position-relative w-100 bg-white rounded-16 p-16 w-100 border border-grey-200 mb-16'
        )}
      >
        {!editId && (
          <div className="position-absolute top-0 end-0 mt-16 me-16">
            <Popover
              placement="bottomRight"
              title={null}
              trigger={'click'}
              content={menuContent}
              arrow={false}
              align={{ offset: [0, -10] }}
            >
              <KababMenu
                className="cursor-pointer"
                size="medium"
                weight="slim"
              />
            </Popover>
          </div>
        )}
        <>
          {editId === String(data?.id) && editField === 'ink' ? (
            <>
              <EditItem name="ink" handleCancel={handleCancel} />
            </>
          ) : (
            <>
              <div className="d-flex flex-column w-100">
                {/* <div className="d-flex">
                <div {...(provided ? provided.dragHandleProps : {})}>
                  <DragVertical
                    color={Color.background.dark}
                    className="me-4"
                  />
                </div>
                <span className="text-title-small fw-medium text-grey-700 mb-16">
                  Color {index + 1}
                </span>
              </div> */}
                {editId === String(data?.id) && editField === 'order' ? (
                  <div className="d-flex flex-column w-100">
                    <div className="d-flex w-100">
                      <FormFields name="order" />
                    </div>
                    <div className="d-flex w-100 gap-2 mb-16">
                      <Button
                        antdButtonProps={{
                          onClick: () => {
                            searchParams.delete('edit_id')
                            searchParams.delete('edit_field')
                            setSearchParams(searchParams)
                          },
                        }}
                        type="outlined"
                        block
                        size="large"
                      >
                        Cancel
                      </Button>
                      <Button
                        antdButtonProps={{
                          htmlType: 'submit',
                        }}
                        type="solid"
                        block
                        size="large"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex w-100 align-items-center">
                    <span className="pe-12 d-flex">
                      Order : &nbsp;&nbsp;
                      <b className=" text-body-large">
                        {data?.data?.order || '-'}
                      </b>{' '}
                    </span>
                    <Button
                      antdButtonProps={{
                        className: 'd-flex',
                        onClick: () => {
                          searchParams.delete('add_color')
                          searchParams.set('edit_id', data?.id)
                          searchParams.set('edit_field', 'order')
                          setSearchParams(searchParams)
                        },
                      }}
                      type="ghost"
                      icon={Edit}
                      size="extra_small"
                    ></Button>
                  </div>
                )}

                {!!inkData && (
                  <>
                    <Divider className="my-4" plain>
                      <span className="text-label-extra-small text-grey-500 fw-semibold">
                        LINKED INK
                      </span>
                    </Divider>
                    <div className="d-flex w-100">
                      {/* <Image
                width={60}
                height={84}
                className="rounded-8 overflow-hidden object-fit-cover"
                src={inkData?.ink_image}
                alt={inkData?.name || inkData?.group_name}
              /> */}
                      <div
                        style={{ height: '84px' }}
                        className="d-flex flex-column   justify-content-around"
                      >
                        <span className="text-title-medium text-dark fw-semibold">
                          {inkData?.name || inkData?.group_name}
                        </span>
                        <div className="d-flex w-100 text-label-medium fw-medium text-grey-700">
                          <span className="pe-12">
                            Code: {inkData?.ink_sku_code || '-'}
                          </span>
                          <span className="ps-12 border-start border-grey-400">
                            Location: {inkData?.ink_location || '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex w-100 mt-12 w-100 pt-12 border-top border-grey-200">
                      {inkFields.map((el, i) => displayFields(el, i))}
                    </div>
                  </>
                )}
              </div>
              {!!data?.data?.description && (
                <div className="d-flex w-100 flex-column bg-grey-100 px-16 py-8 rounded-8 mt-20">
                  <span className="text-body-small fw-bold">DESCRIPTION</span>
                  <span className=" text-body-medium mt-4">
                    {data?.data?.description}
                  </span>
                </div>
              )}
            </>
          )}
        </>
        {editId === String(data?.id) && editField === 'cylinder' ? (
          <EditItem
            colorsList={colorsList}
            name="cylinder"
            handleCancel={handleCancel}
          />
        ) : (
          <div className="d-flex flex-column w-100">
            <Divider className="my-12" plain>
              <span className="text-label-extra-small text-grey-500 fw-semibold">
                LINKED CYLINDER
              </span>
            </Divider>
            <div className="d-flex w-100">
              {/* <div className="d-flex">
                <Image
                  width={60}
                  height={84}
                  className="rounded-8 overflow-hidden object-fit-cover"
                  src={cylinderData?.cylinder_image}
                  alt={cylinderData?.cylinder_name}
                />
              </div> */}
              <div
                style={{ height: '84px' }}
                className="d-flex flex-column justify-content-around"
              >
                <span className="text-title-medium text-dark fw-semibold">
                  {cylinderData?.cylinder_name}
                </span>
                <div className="d-flex w-100 text-label-medium fw-medium text-grey-700">
                  <span className="pe-12">
                    Code: {cylinderData?.cylinder_cylinder_code}
                  </span>
                  <span className="ps-12 border-start border-grey-400">
                    Location: {cylinderData?.cylinder_location || '-'}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex w-100 mt-12 w-100 pt-12 border-top border-grey-200">
              {cylinderFields.map((el, i) => displayFields(el, i))}
            </div>
          </div>
        )}
      </div>

      <div className="d-none">
        <JobColorPrint
          data={jobData}
          colorData={data?.data}
          orgName={orgName}
          elRef={printRef}
          position={index + 1}
        />
      </div>
    </>
  )
}

export default ColorItem
