import { QueryClientConfig } from '@tanstack/react-query'
import { message } from 'antd'

export const ENV = {
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  ORG_API_BASE_URL: import.meta.env.VITE_ORG_API_BASE_URL,
  WEB_BASE_URL: import.meta.env.VITE_WEB_BASE_URL,
  ACCOUNTS_WEB_BASE_URL: import.meta.env.VITE_ACCOUNTS_WEB_BASE_URL,
  INVOICE_WEB_BASE_URL: import.meta.env.VITE_INVOICE_WEB_BASE_URL,
  WEB_DOMAIN: import.meta.env.VITE_WEB_DOMAIN,
  ENV_NAME: import.meta.env.VITE_ENV_NAME,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  INVOICE_API_BASE_URL: import.meta.env.VITE_INVOICE_API_BASE_URL,
  MIX_PANEL_TOKEN: import.meta.env.VITE_MIX_PANEL_TOKEN,
}

export const API_CONFIG = {
  timeout: 5 * 60 * 1000, // 5 Minutes
  baseURL: ENV.API_BASE_URL,
}

export const QUERY_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      ...(ENV.ENV_NAME === 'local'
        ? {
            refetchOnWindowFocus: false,
            staleTime: 1 * 60 * 1000, // 1 Minute
          }
        : {
            refetchOnWindowFocus: true,
            staleTime: 0, // 0 Minute
          }),

      retry: (_, error: any) => {
        const statusCode = error?.response?.status
        const excludeStatus = [401, 404, 403, 500]

        return !excludeStatus.includes(statusCode)
      },
    },
    mutations: {
      onError: (error: any) => {
        const Errors = error.response.data.error || {
          message: [error?.response?.data?.message]?.flat(),
        }
        const SingleError: Record<string, any> = Object.values(Errors)[0] as any
        if (Array.isArray(SingleError)) {
          message.error(SingleError[0])
        } else {
          message.error(Object.values(SingleError)[0])
        }
      },
    },
  },
}

export const MASTERS_STALE_TIME: number = 1 * 60 * 60 * 60 // 1 hour

export const SENTRY_APP_NAME = 'Manufacture'
