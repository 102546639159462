import React, { FC, ReactNode } from 'react'
import cn from 'classnames'
import { ChevronRight } from 'yolo-design/icons'
import { specKeys } from '@contracts/job'

type propTypes = {
  label: string
  subLabel: string | ReactNode
  active?: boolean
  specKey: specKeys
  handleSelect: (key: string) => void
}

const Item: FC<propTypes> = ({
  label,
  subLabel,
  active = false,
  specKey,
  handleSelect,
}) => {
  return (
    <div
      className={cn('d-flex w-100 px-24 cursor-pointer', {
        'bg-washed-blue': active,
      })}
      onClick={() => handleSelect(specKey)}
    >
      <div className="d-flex w-100 justify-content-between py-16 border-bottom border-grey-200">
        <div className="d-flex flex-column pe-8">
          <span className="text-title-medium fw-semibold text-dark">
            {label}
          </span>
          <span className="text-label-small fw-medium text-grey-700 mt-8">
            {subLabel}
          </span>
        </div>
        <div className="d-flex">
          <ChevronRight size="medium" weight="slim" />
        </div>
      </div>
    </div>
  )
}

export default Item
