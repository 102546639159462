import mixpanel from 'mixpanel-browser'
import { ENV } from './app.config'
class MixpanelService {
  constructor() {
    mixpanel.init(ENV.MIX_PANEL_TOKEN)
  }
  trackEvent(eventName: string, properties?: Record<string, any>) {
    mixpanel.track(eventName, properties)
  }
  userEvent(eventName: string) {
    mixpanel.identify(eventName)
  }
  userDetailsEvent(properties?: any) {
    mixpanel.people.set(properties)
  }
  userDeleteEvent() {
    mixpanel.reset()
  }
}

const mixpanelService = new MixpanelService()
export default mixpanelService
