import { FC, useState } from 'react'
import { useRMCylinderList, useRMInkList } from '@services/product'
import {
  Button,
  FormInput,
  FormInputArea,
  FormSelect,
} from 'yolo-design/components'
import SelectItem from '../SelectItem'
import { Divider } from 'antd'
import { NavLink, useParams } from 'react-router-dom'
import { Add } from 'yolo-design/icons'
import { ENV } from '@config/app.config'
import { useFormDetailsByCode } from '@services/dynamic'

const FormFields: FC<{
  name: 'ink' | 'cylinder' | 'description' | 'order'
  cylFilterIds?: Array<string>
  clyInclude?: string
  inkInclude?: string
}> = ({ name, cylFilterIds, inkInclude, clyInclude }) => {
  const [inkSearch, setInkSearch] = useState('')
  const [cylinderSearch, setCylinderSearch] = useState('')
  const { orgId } = useParams()

  const inkList = useRMInkList(
    { search: inkSearch, include: inkInclude },
    { enabled: name === 'ink' }
  )
  const cylinderList = useRMCylinderList(
    {
      search: cylinderSearch,
      include: clyInclude,
    },
    { enabled: name === 'cylinder' }
  )

  const { data: cylinderFormIdData } = useFormDetailsByCode({}, { code: 5 })

  return (
    <>
      {name === 'ink' && (
        <FormSelect
          name={'ink'}
          placeHolder="Add Ink by entering ink name or code"
          optionsSelect={inkList?.data?.data?.map((el) => ({
            ...el,
            value: el.form_entity,
            name: el.name || el.group_name,
            label: (
              <SelectItem
                image={el.ink_image}
                name={el.name || el.group_name}
                sku={el.ink_sku_code}
              />
            ),
          }))}
          antdSelectProps={{
            allowClear: true,
            showSearch: true,
            onSearch: (val) => setInkSearch(val),
            loading: inkList.isLoading,
            filterOption: false,
            optionLabelProp: 'name',
          }}
        />
      )}
      {name === 'description' && (
        <FormInputArea
          rules={[
            {
              max: 100,
            },
          ]}
          maxLength={100}
          name={'description'}
          placeholder="Description"
        />
      )}
      {name === 'order' && (
        <FormInput
          title="Order"
          antdInputProps={{ size: 'small' }}
          andtFormItemProps={{
            className: 'w-100',
          }}
          inputType="number"
          name={'order'}
          required
        />
      )}
      {name === 'cylinder' && (
        <FormSelect
          required
          name={'cylinder'}
          placeHolder="Add Cylinder by entering Cylinder name or code"
          optionsSelect={cylinderList?.data?.data
            ?.filter((f) => f.id && !cylFilterIds?.includes(f.id))
            ?.map((el) => ({
              ...el,
              value: el.form_entity,
              name: el.cylinder_name,
              label: (
                <SelectItem
                  image={el.cylinder_image}
                  name={el.cylinder_name}
                  sku={el.cylinder_cylinder_code}
                />
              ),
            }))}
          antdSelectProps={{
            showSearch: true,
            onSearch: (val) => setCylinderSearch(val),
            loading: cylinderList.isLoading,
            filterOption: false,
            optionLabelProp: 'name',
            dropdownRender: (menu) => (
              <>
                {menu}
                <Divider className="my-12" />
                <div className="d-flex w-100 px-8 pb-8">
                  <NavLink
                    to={
                      ENV.INVOICE_WEB_BASE_URL +
                      `/${orgId}/inventory/dynamic/${cylinderFormIdData?.form_config}/create/?form=${cylinderFormIdData?.id}&formType=${cylinderFormIdData?.form_type_config}`
                    }
                    target="_blank"
                  >
                    <Button type="ghost" size="small" icon={Add}>
                      Add New Cylinder
                    </Button>
                  </NavLink>
                </div>
              </>
            ),
          }}
        />
      )}
    </>
  )
}

export default FormFields
