import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import Item from './Item'
import Cylinder from './Content/Cylinder'
import { DRAWER_HEADER_HEIGHT, NESTED_DRAWER_WIDTH } from '@constants/index'
import { Button, Drawer } from 'yolo-design/components'
import { Add, DoubleChevronRight, Print } from 'yolo-design/icons'
import Colors from './Content/Colors'
import BillOfMaterial from './Content/BillOfMaterial'
import Process from './Content/Process'
import cn from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { specKeys } from '@contracts/job'
import {
  useDFormCylinderId,
  useDFormKeyValue,
  useDFormProcessId,
  useDFormTypeJobId,
} from '@services/dynamic'
import JobColorPrint from '@components/Print/JobColorV2'
import { useReactToPrint } from 'react-to-print'
import { useOrgInfo } from '@services/org'

const Specification: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isPrinting, setIsPrinting] = useState(false)
  const printRef = useRef(null)

  const specId = searchParams.get('spec') as specKeys
  const jobId = searchParams.get('job') || ''

  const { data, isLoading } = useDFormKeyValue(
    {
      enabled: !!jobId,
    },
    { formId: jobId }
  )

  const { data: orgData } = useOrgInfo()

  const orgName = orgData?.name

  const jobFormId = useDFormTypeJobId() || ''
  const cylinderFormId = useDFormCylinderId(jobFormId)
  const processFormId = useDFormProcessId(jobFormId)

  const jobCode = data?.job?.job_code

  const handlePrint = useReactToPrint({
    documentTitle: jobCode,
    pageStyle: '@page { size: 80mm 50mm; margin: 0mm;}',
    content: () => printRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true)
    },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
  })

  const specDetails: Record<
    specKeys,
    { name: string; element: () => ReactNode; onAddClick?: () => void }
  > = {
    cylinder: {
      name: 'Cylinder Specification',
      element: () => (
        <Cylinder
          data={data?.cylinder_details}
          cylinderFormId={cylinderFormId}
          isLoading={isLoading}
        />
      ),
    },
    color: {
      name: 'Colour',
      onAddClick: () => {
        searchParams.set('add_color', 'true')
        searchParams.delete('edit_field')
        searchParams.delete('edit_id')
        setSearchParams(searchParams)
      },
      element: () => <Colors data={data?.color} />,
    },
    'bill-of-materials': {
      name: 'Bill of Material',
      onAddClick: () => {
        searchParams.set('add_bom', 'true')
        searchParams.delete('edit_id')
        setSearchParams(searchParams)
      },
      element: () => <BillOfMaterial data={data?.bill_of_materials} />,
    },
    'process-details': {
      name: 'Process',

      element: () => (
        <Process
          data={data?.process_and_details}
          processFormId={processFormId}
        />
      ),
    },
  }

  const activeSpecDetails = specDetails[specId || '']

  const colorsList = data?.color?.colors || []

  const list: Array<{
    specKey: specKeys
    label: string
    subLabel: string | ReactNode
  }> = [
    {
      specKey: 'cylinder',
      label: 'Cylinder Details',
      subLabel: 'Cylinder Type, Cylinder Dimensions, Job Measurements ',
    },
    {
      specKey: 'color',
      label: 'Colour',
      subLabel: (
        <div className="d-flex text-label-small fw-medium text-dark">
          <span className="pe-8">
            No of Colours : {data?.color?.colors?.length || 0}
          </span>
        </div>
      ),
    },
    {
      specKey: 'bill-of-materials',
      label: 'Bill of Materials',
      subLabel: (
        <div className="d-flex text-label-small fw-medium text-dark">
          <span className="pe-8">
            No of Materials : {data?.bill_of_materials?.bom?.length || 0}
          </span>
        </div>
      ),
    },
    {
      specKey: 'process-details',
      label: 'Process & Details',
      subLabel: 'Winding Directions, Wastage, Photocell, Slitting',
    },
  ]

  const handleSelect = (key: string) => {
    searchParams.set('spec', key)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (!(specId === 'cylinder' || specId === 'process-details')) {
      searchParams.delete('edit_id')
      setSearchParams(searchParams)
    }
  }, [specId])

  return (
    <>
      <div className="d-flex flex-column w-100">
        {list.map((el) => (
          <Item
            key={el.specKey}
            specKey={el.specKey}
            label={el.label}
            subLabel={el.subLabel}
            active={el.specKey === specId}
            handleSelect={handleSelect}
          />
        ))}
      </div>

      <Drawer
        contentWrapperStyle={{
          boxShadow: 'none',
          borderLeft: '1px solid var(--yd-grey-500)',
        }}
        customHeader
        mask={false}
        titleProps={{}}
        onClose={() => {
          searchParams.delete('spec')
          setSearchParams(searchParams)
        }}
        width={NESTED_DRAWER_WIDTH}
        open={!!specId && !!jobId}
      >
        <div className="d-flex flex-column w-100">
          <div
            style={{ height: DRAWER_HEADER_HEIGHT }}
            className="d-flex px-24 justify-content-between align-items-center border-bottom border-grey-400"
          >
            <div
              className={cn('d-flex w-100', {
                'border-end border-grey-400': !!activeSpecDetails?.onAddClick,
              })}
            >
              <div className="d-flex align-items-center pe-16">
                <DoubleChevronRight
                  onClick={() => {
                    searchParams.delete('spec')
                    setSearchParams(searchParams)
                  }}
                  className="cursor-pointer"
                  size="medium"
                  weight="slim"
                />
              </div>
              <span className="text-title-large fw-medium ps-16 border-start border-grey-400">
                {activeSpecDetails?.name}
              </span>
            </div>
            {!!activeSpecDetails?.onAddClick && (
              <div className="d-flex ps-16 align-items-center">
                {activeSpecDetails.name === 'Colour' && (
                  <Button
                    antdButtonProps={{
                      disabled: !colorsList.length || isPrinting,
                      className: 'me-8',
                      onClick: handlePrint,
                    }}
                    icon={Print}
                    type="outlined"
                  >
                    Print
                  </Button>
                )}
                <Button
                  antdButtonProps={{ onClick: activeSpecDetails?.onAddClick }}
                  icon={Add}
                  type="outlined"
                >
                  New Layer
                </Button>
              </div>
            )}
          </div>
          <div
            style={{
              height: 'calc(100vh - 52px)',
            }}
            className="d-flex overflow-scroll"
          >
            {activeSpecDetails?.element()}
          </div>
        </div>
      </Drawer>

      <div className="d-none w-100">
        <div ref={printRef} className="d-flex flex-column w-100">
          {colorsList?.map((el: any, i: number) => (
            <JobColorPrint
              key={el.id}
              data={data}
              colorData={el?.data}
              orgName={orgName}
              position={i + 1}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Specification
