import { useMutation } from '@tanstack/react-query'
import * as API from './api'
import { UseMutationType } from '@contracts/query'
import { IRegister, IRegisterRes } from '@contracts/models/user'

export const useRegister: UseMutationType<IRegisterRes, IRegister> = (
  options
) => useMutation(API.postRegister, options)

export const useLogout: UseMutationType<
  { message: string },
  Record<any, any>
> = (options) => useMutation(API.postLogout, options)
