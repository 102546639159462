import { queries } from '@constants/queryKeys'
import {
  UseInfiniteQueryDataType,
  UseMutationType,
  UseQueryDataType,
} from '@contracts/query'
import useInfiniteQueryRef from '@hooks/useInfiniteQueryRef'
import * as API from './api'
import { PaginatedResponse } from '@contracts/models/shared'
import { useMutation, useQuery } from '@tanstack/react-query'

export const useDispatchList: UseInfiniteQueryDataType<
  any,
  {
    page_size?: number
    search?: string
    orgId?: string
    dispatch_status?: string
  }
> = (options, { page_size = 10, orgId, search, dispatch_status }) => {
  return useInfiniteQueryRef<any>({
    queryKey: queries.dispatch.list(page_size, search, orgId, dispatch_status)
      .queryKey,
    queryFn: ({ signal, pageParam }) =>
      API.getDispatchList(signal, {
        page: pageParam,
        page_size,
        search,
        status: dispatch_status,
      }),
    ...options,
  })
}

export const useWorkOrderSearchList: UseQueryDataType<
  PaginatedResponse<any>,
  { search?: string; page?: number; page_size?: number }
> = (options, { search = '', page = 1, page_size = 30 }) =>
  useQuery({
    queryKey: queries.dispatch.work_by_id({ search, page, page_size }).queryKey,
    queryFn: () => API.getWorkJobSearchList({ search, page, page_size }),
    ...options,
  })

export const useCreateDispatchData: UseMutationType<any, any> = (options) =>
  useMutation({
    mutationFn: API.postDispatchData,
    ...options,
  })

export const useChangeDispatchStatus: UseMutationType<
  any,
  { data: { id: any; dispatch_status: any; dispatch_date: any } }
> = (options) => useMutation({ mutationFn: API.putDispatchData, ...options })

export const useDispatchData: UseQueryDataType<any, { id: number }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.dispatch.dispatch_info(id).queryKey,
    queryFn: () => API.getDisatchDataById(id),
    ...options,
  })

export const useDeleteDispatchItem: UseMutationType<any, { id: string }> = (
  options
) => useMutation(API.deleteDispatchItem, options)

export const useDeleteDispatch: UseMutationType<any, { data: { id: any } }> = (
  options
) => useMutation({ mutationFn: API.deleteDispatchData, ...options })
