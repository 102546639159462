import { ENV } from '@config/app.config'
import { IPurIndentReq, IPurIndentRes } from '@contracts/models/purchase'
import { PaginatedResponse } from '@contracts/models/shared'
import { DELETE, GET, POST, PUT } from '@lib/AxiosClient'

export const getPurchaseIndent = (
  signal: AbortSignal | undefined,
  params: {
    page: number
    page_size: number
    search?: string
    status?: string
  }
): Promise<PaginatedResponse<IPurIndentRes>> => {
  return GET({
    url: ENV.INVOICE_API_BASE_URL + `bills/purchase-indent/`,
    signal,
    params,
  })
}

export const getPurchaseIndentInfo = (
  id: string | number
): Promise<IPurIndentRes> => {
  return GET({
    url: ENV.INVOICE_API_BASE_URL + `bills/purchase-indent/${id}/`,
  })
}

export const postPurchaseIndent = (
  data: IPurIndentReq
): Promise<{ message: string }> =>
  POST({
    url: ENV.INVOICE_API_BASE_URL + 'bills/purchase-indent/',
    data,
  })

export const putPurchaseIndent = (data: {
  id: string
  data: IPurIndentReq
}): Promise<{ message: string }> =>
  PUT({
    url: ENV.INVOICE_API_BASE_URL + `bills/purchase-indent/${data?.id}/`,
    data: data.data,
  })

export const deletePurchaseIndent = (data: {
  id: string
}): Promise<{ message: string }> =>
  DELETE({
    url: ENV.INVOICE_API_BASE_URL + `bills/purchase-indent/${data?.id}/`,
  })
