import { getPort, setPort } from '@utils/auth'
import { formDecimalInputRestriction } from '@utils/input'
import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  FormInput,
  FormWrapper,
  Modal,
  Text,
} from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { Scanner } from 'yolo-design/icons'
import { RadioList } from 'yolo-design/organisms'

const PouchCreateTable = ({
  dispatch_detail,
  isPouch,
  date,
  form,
  createDispatchData,
  printFunc,
  setPrintLabelData,
}: {
  isPouch: boolean
  date: string
  dispatch_detail: any
  form: any
  createDispatchData: any
  printLabelData: any
  setPrintLabelData: any
  printFunc: any
}) => {
  const [isOpen, setIsOpen] = useState<boolean>()
  const [portList, setPortList] = useState<any>()
  const [selectPort, setSelectPort] = useState<any>()
  const [printEnable, setPrintEnable] = useState(true)
  const handleFinish = (e: any) => {
    createDispatchData?.mutate(
      {
        data: {
          serial_no: e?.sl_no,
          enable_packing: isPouch,
          pouch_per_packet: e?.pouch_to_packet,
          packet_per_bundle: e?.packet_to_bundle,
          tare_weight: e?.tar || 0,
          gross_weight: e?.gross,
          dispatch_date: date,
          dispatch_type: Number(dispatch_detail?.dispatch_type),
          dispatch_no: dispatch_detail?.dispatch_no,
          work_order: dispatch_detail?.work_order,
          job: dispatch_detail?.job_id,
          work_order_no: dispatch_detail?.work_order_no,
          extra_pouch: e?.extra_to_pouch || 0,
          job_name: dispatch_detail?.job_name,
          job_code: dispatch_detail?.job_code,
          is_exists: dispatch_detail?.dispatch_items?.length ? true : false,
          customer: dispatch_detail?.customer,
          job_image: dispatch_detail?.job_image,
          output_quantity: dispatch_detail?.output_quantity,
        },
      },
      {
        onSuccess: (ell: any) => {
          const data = ell?.dispatch_items?.filter(
            (el: any) => String(el?.serial_no) === String(e?.sl_no)
          )
          if (printEnable) {
            setPrintLabelData(...data)

            setTimeout(() => {
              printFunc()
              setPrintLabelData(null)
            }, 500)
          }
        },
      }
    )
  }

  const handleConnect = async () => {
    const isPort = getPort()
    if (isPort) {
      // await reconnectSerialPort()

      const url = `https://localhost:2306/api/continuity/serial/read?portName=${isPort}&baudRate=9600`
      try {
        const response = await fetch(url, { method: 'GET' })
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`)
        }
        const json = await response.json()

        form.setFieldValue('gross', Number(json?.data).toFixed(2))
      } catch (error) {
        // console.log('');
      }
    } else {
      const url = 'https://localhost:2306/api/continuity/serial/devices'
      try {
        const response = await fetch(url, { method: 'GET' })
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`)
        }
        const json = await response.json()
        // const list = [
        //   {
        //     "name": "Arduino Uno (COM3)",
        //     "serialPort": "COM3",
        //     "description": "Arduino Uno",
        //     "manufacturer": "Arduino LLC (www.arduino.cc)",
        //     "deviceId": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "pnpDeviceID": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "baudRate": 9600
        //   },{
        //     "name": "Arduino Uno (COM3)",
        //     "serialPort": "COM4",
        //     "description": "Arduino Uno",
        //     "manufacturer": "Arduino LLC (www.arduino.cc)",
        //     "deviceId": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "pnpDeviceID": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "baudRate": 9600
        //   },{
        //     "name": "Arduino Uno (COM3)",
        //     "serialPort": "COM5",
        //     "description": "Arduino Uno",
        //     "manufacturer": "Arduino LLC (www.arduino.cc)",
        //     "deviceId": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "pnpDeviceID": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "baudRate": 9600
        //   },{
        //     "name": "Arduino Uno (COM3)",
        //     "serialPort": "COM6",
        //     "description": "Arduino Uno",
        //     "manufacturer": "Arduino LLC (www.arduino.cc)",
        //     "deviceId": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "pnpDeviceID": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "baudRate": 9600
        //   },{
        //     "name": "Arduino Uno (COM3)",
        //     "serialPort": "COM7",
        //     "description": "Arduino Uno",
        //     "manufacturer": "Arduino LLC (www.arduino.cc)",
        //     "deviceId": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "pnpDeviceID": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "baudRate": 9600
        //   }
        // ]
        setPortList(json?.devices)
        if (json?.devices?.length) {
          setIsOpen(true)
        }
      } catch (error) {
        // console.error(error.message);
      }

      // try {
      //   const newPort = await (navigator as any).serial.getPorts()
      //   // await newPort.open({ baudRate: 9600 })
      //   // const newReader = newPort.readable?.getReader()
      //   // const portInfo = newPort
      //   console.log(newPort);

      // } catch (error) {
      //   // message.error('Connection to serial port failed')
      // }
    }
  }

  useEffect(() => {
    if (!dispatch_detail?.dispatch_items?.length) {
      form.setFieldValue('sl_no', 1)
    }
  }, [])

  return (
    <div>
      <FormWrapper
        antdFormProps={{
          className: 'd-flex flex-column w-100 px-12 pt-16 overflow-scroll',
          form,
          onFinish: handleFinish,
        }}
      >
        <Row className="d-flex  align-items-center" gutter={15}>
          <Col span={2}>
            <FormInput
              antdInputProps={{ onInput: formDecimalInputRestriction }}
              name={'sl_no'}
              required
              title="SL No"
            />
          </Col>

          {isPouch && (
            <Col span={4}>
              {' '}
              <FormInput
                name={'pouch_to_packet'}
                antdInputProps={{ onInput: formDecimalInputRestriction }}
                required
                title="Pouch to Packet"
              />{' '}
            </Col>
          )}
          {isPouch && (
            <Col span={4}>
              {' '}
              <FormInput
                name={'extra_to_pouch'}
                antdInputProps={{ onInput: formDecimalInputRestriction }}
                title="Extra Pouches"
              />{' '}
            </Col>
          )}

          {isPouch && (
            <Col span={4}>
              {' '}
              <FormInput
                antdInputProps={{ onInput: formDecimalInputRestriction }}
                name={'packet_to_bundle'}
                required
                title="Packet to Bundle"
              />
            </Col>
          )}

          <Col span={2}>
            <FormInput
              antdInputProps={{ onInput: formDecimalInputRestriction }}
              name={'tar'}
              title="Tare"
            />
          </Col>

          <Col span={4}>
            <FormInput
              antdInputProps={{ onInput: formDecimalInputRestriction }}
              name={'gross'}
              suffixDetails={{
                label: <Scanner onClick={handleConnect} />,
              }}
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  handleConnect()
                }
              }}
              required
              title={
                <>
                  <Text category="label" size="small" value={'Gross Wt '} />
                </>
              }
              tooltipContent="Press Space Key to auto read data from Weight Machine"
            />
          </Col>

          <Col>
            <Button
              antdButtonProps={{
                htmlType: 'submit',
                loading: createDispatchData?.isLoading,
                className: 'mt-12',
              }}
              size="large"
              backgroundColor={Color?.blue?.washed_blue}
              color={Color?.blue?.just_blue}
              label="Add Entry"
            />
          </Col>
          <Col>
            <Checkbox
              handleCheckboxChange={() => setPrintEnable(!printEnable)}
              setIsChecked={setPrintEnable}
              isChecked={printEnable}
              label="Print Label"
            />
          </Col>
        </Row>
      </FormWrapper>
      <Modal
        cancelButtonDetails={{}}
        okButtonDetails={{}}
        open={portList ? isOpen : false}
        onCancel={() => {
          setIsOpen(false)
        }}
        title={'Select your port'}
      >
        <div className="w-100 ">
          <RadioList
            list={portList?.map((el: any) => {
              return {
                value: el?.serialPort,
                label: el?.name,
              }
            })}
            selectedOption={selectPort}
            setSelectedOption={(el) => setSelectPort(String(el))}
          />
          <div className="d-flex mt-12 px-12 justify-content-end gap-3">
            <Button
              antdButtonProps={{
                onClick: () => {
                  setIsOpen(false)
                },
              }}
              type="outlined"
              label="Cancel"
            />
            <Button
              antdButtonProps={{
                onClick: () => {
                  setPort(selectPort)
                  setIsOpen(false)
                },
                disabled: selectPort ? false : true,
              }}
              label="Save"
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PouchCreateTable
