import LaminationWorkOrder from '@components/Print/WorkOrder/LaminationWorkOrder'
import PolyWorkOrder from '@components/Print/WorkOrder/PolyWorkOrder'
import PouchWorkOrder from '@components/Print/WorkOrder/PouchWorkOrder'
import PrintingWorkOrder from '@components/Print/WorkOrder/PrintingWorkOrder'
import SlittingWorkOrder from '@components/Print/WorkOrder/SlittingWorkOrder'
import { useDWorkOrder } from '@services/dynamic'
import { Dropdown } from 'antd'
import React, { useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { Button } from 'yolo-design/components'
import { Print as PrintIcon } from 'yolo-design/icons'

const Print = () => {
  const [isPrinting, setIsPrinting] = useState(false)
  const printCardRef = useRef(null)
  const laminationCardRef = useRef(null)
  const slitCardRef = useRef(null)
  const polyCardRef = useRef(null)
  const pouchCardRef = useRef(null)

  const [searchParams] = useSearchParams()

  // const jobId = searchParams.get('job') || ''
  const workId = searchParams.get('work') || ''

  // const { data: job } = useDFormKeyValue(
  //   {
  //     enabled: !!jobId,
  //   },
  //   { formId: jobId }
  // )

  const {
    data: workData,
    // isLoading: workLoading
  } = useDWorkOrder(
    {
      enabled: !!workId,
    },
    { formId: workId }
  )

  // const data =
  //   !!workId && workLoading
  //     ? {}
  //     : workData?.is_completed
  //     ? workData?.job_data
  //     : job

  const stepCodes = workData?.steps?.map((el: any) => el.code) || []

  const menuItems: {
    key: string
    label: string
    disabled: boolean
    showmenu: string
  }[] = [
    {
      key: 'printing',
      label: 'Printing Card',
      disabled: isPrinting,
      showmenu: String(stepCodes?.includes(1)),
    },
    {
      key: 'lamination',
      label: 'Lamination Card',
      disabled: isPrinting,
      showmenu: String(stepCodes?.includes(2)),
    },
    {
      key: 'slitting',
      label: 'Slitting Card',
      disabled: isPrinting,
      showmenu: String(stepCodes?.includes(3)),
    },
    {
      key: 'pouch',
      label: 'Pouching Card',
      disabled: isPrinting,
      showmenu: String(stepCodes?.includes(4)),
    },
    {
      key: 'poly',
      label: 'Poly Card',
      disabled: isPrinting,
      showmenu: String(stepCodes?.includes(5)),
    },
  ]?.filter((f) => f.showmenu === 'true')

  const printPrintCard = useReactToPrint({
    documentTitle: 'print-card',
    pageStyle: '@page { size: 170mm 215mm; margin: 4mm; }',
    content: () => printCardRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true)
    },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
  })

  const printLaminationCard = useReactToPrint({
    documentTitle: 'lamination-card',
    pageStyle: '@page { size: 170mm 215mm; margin: 4mm; }',
    content: () => laminationCardRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true)
    },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
  })

  const printSlitCard = useReactToPrint({
    documentTitle: 'slit-card',
    pageStyle: '@page { size: 170mm 215mm; margin: 4mm; }',
    content: () => slitCardRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true)
    },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
  })

  const printPloyCard = useReactToPrint({
    documentTitle: 'poly-card',
    pageStyle: '@page { size: 150mm 215mm; margin: 4mm; }',
    content: () => polyCardRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true)
    },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
  })

  const printPouchCard = useReactToPrint({
    documentTitle: 'pouch-card',
    pageStyle: '@page { size: 170mm 215mm; margin: 4mm; }',
    content: () => pouchCardRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true)
    },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
  })

  return (
    <>
      <Dropdown
        placement="bottomRight"
        menu={{
          items: menuItems,
          onClick: (e) => {
            const obj: Record<string, any> = {
              printing: printPrintCard,
              slitting: printSlitCard,
              lamination: printLaminationCard,
              poly: printPloyCard,
              pouch: printPouchCard,
            }

            obj[e.key]?.()
          },
        }}
      >
        <Button
          icon={PrintIcon}
          antdButtonProps={{
            loading: isPrinting,
            disabled: menuItems.length === 0,
          }}
          type="outlined"
        >
          Print
        </Button>
      </Dropdown>
      <div className="d-none">
        <PolyWorkOrder elRef={polyCardRef} />
        <PouchWorkOrder elRef={pouchCardRef} />
        <LaminationWorkOrder elRef={laminationCardRef} />
        <SlittingWorkOrder elRef={slitCardRef} />
        <PrintingWorkOrder elRef={printCardRef} />
      </div>
    </>
  )
}

export default Print
