import { queries } from '@constants/queryKeys'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import * as API from './api'
import { UseInfiniteQueryDataType, UseQueryDataType } from '@contracts/query'
import { IAllProduct } from '@contracts/models/product'
import { IDFormKeyValue } from '@contracts/models/dynamic'
import { RAW_MATERIAL_FORM_CODE } from '@constants/dynamic'
import { PaginatedResponse } from '@contracts/models/shared'
import useInfiniteQueryRef from '@hooks/useInfiniteQueryRef'

export const useItemProductList: UseInfiniteQueryDataType<
  PaginatedResponse<any>,
  { page?: number; page_size?: number; search?: string }
> = (options, { page = 1, page_size = 20, search }) =>
  useInfiniteQueryRef<any>({
    queryKey: queries.product.list({ page, page_size, search }).queryKey,
    queryFn: ({ signal }) =>
      API.getProductList(signal, { page, page_size, search }),
    ...options,
  })

export const useAllProductList: UseQueryDataType<
  PaginatedResponse<IAllProduct>,
  { page?: number; page_size?: number; search?: string }
> = (options, { page = 1, page_size = 30, search }) =>
  useQuery({
    queryKey: queries.product.all_products({ page, page_size, search })
      .queryKey,
    queryFn: ({ signal }) =>
      API.getAllProductList(signal, { page, page_size, search }),
    ...options,
  })

export const useRawMaterialList: UseQueryDataType<
  PaginatedResponse<IDFormKeyValue>,
  { form: string; search?: string; page_size?: number; include?: string }
> = (options, { form, search, page_size = 30, include }) =>
  useQuery({
    queryKey: queries.product.item_list(form, search, include).queryKey,
    queryFn: () => API.getRawMaterialList({ form, search, page_size, include }),
    enabled: !!form,
    ...options,
  })

export const useRMInkList = (
  {
    search,
    include,
  }: {
    search?: string
    include?: string
  },
  options: UseQueryOptions<any>
) =>
  useRawMaterialList(options, {
    form: [
      String(RAW_MATERIAL_FORM_CODE['ink']),
      String(RAW_MATERIAL_FORM_CODE['group']),
    ].join(','),
    search,
    include,
  })

export const useGetProductDetails: UseQueryDataType<any, { id: number }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.product.info(id).queryKey,
    queryFn: () => API.getProductDetails({ id }),
    ...options,
  })

export const useRMCylinderList = (
  {
    search,
    include,
  }: {
    search?: string
    include?: string
  },
  options: UseQueryOptions<any>
) =>
  useRawMaterialList(options, {
    form: String(RAW_MATERIAL_FORM_CODE['cylinder']),
    search,
    include,
  })

export const useRMFlimAdhesiveList = ({ search }: { search?: string }) =>
  useRawMaterialList(
    {},
    {
      form: [
        String(RAW_MATERIAL_FORM_CODE['flim']),
        String(RAW_MATERIAL_FORM_CODE['adhesive']),
        String(RAW_MATERIAL_FORM_CODE['poly']),
        String(RAW_MATERIAL_FORM_CODE['granule']),
        String(RAW_MATERIAL_FORM_CODE['solvent']),
      ].join(','),
      search,
    }
  )
