import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  Button,
  FormInput,
  FormWrapper,
  Modal,
  Table,
} from 'yolo-design/components'
import {
  useCreateSelectOption,
  useDeleteSelectOption,
  useEditSelectOption,
  useSelectFields,
} from '@services/dynamic'
import { useQueryClient } from '@tanstack/react-query'
import { queries } from '@constants/queryKeys'

const EditOptions = () => {
  const queryClient = useQueryClient()
  const [addOption, setAddOption] = useState(false)
  const [editOption, setEditOption] = useState()
  const [searchParams] = useSearchParams()
  const [form] = Form.useForm()

  const formFilter = searchParams.get('form') || 'all'
  const formQuery = formFilter === 'all' ? undefined : formFilter
  const editFieldId = searchParams.get('edit_field') || ''
  const { data } = useSelectFields({}, { formId: formQuery })
  const selectedField = data?.find((f) => f.id === editFieldId)

  const createOption = useCreateSelectOption({
    onSuccess: () => {
      setAddOption(false)
      return queryClient.invalidateQueries({
        queryKey: queries.dynamic.get_static_select_fields({
          formId: formQuery,
        }).queryKey,
      })
    },
  })
  const updateOption = useEditSelectOption({
    onSuccess: () => {
      setEditOption(undefined)
      return queryClient.invalidateQueries({
        queryKey: queries.dynamic.get_static_select_fields({
          formId: formQuery,
        }).queryKey,
      })
    },
  })
  const deleteOption = useDeleteSelectOption({
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: queries.dynamic.get_static_select_fields({
          formId: formQuery,
        }).queryKey,
      })
    },
  })

  const handleFinish = (values: { name: string }) => {
    if (editOption !== undefined) {
      updateOption.mutate({
        fieldId: editFieldId,
        code: editOption,
        data: { name: values.name },
      })
    }

    if (addOption) {
      createOption.mutate({
        fieldId: editFieldId,
        data: { name: values.name },
      })
    }
  }

  useEffect(() => {
    if (editOption) {
      form.setFieldsValue({
        name: selectedField?.option?.find((f) => f.code === editOption)?.name,
      })
    }
  }, [editOption])

  useEffect(() => {
    if (addOption) {
      form.resetFields()
    }
  }, [addOption])

  return (
    <>
      <div className="d-flex flex-column px-12 py-16 ">
        <div className="d-flex w-100 justify-content-between mb-12 align-items-center">
          <span className="text-body-large fw-semibold ">Options</span>
          <Button
            antdButtonProps={{
              onClick: () => {
                setAddOption(true)
              },
            }}
            size="small"
            type="solid"
          >
            Add Option
          </Button>
        </div>
        <Table
          rowKey={'code'}
          columns={[
            {
              title: 'Label',
              dataIndex: 'name',
            },
            {
              title: 'Action',
              dataIndex: 'action',
              width: 200,
              render: (_, col) => (
                <div className="d-flex gap-2">
                  <Button
                    antdButtonProps={{
                      onClick: () => {
                        setEditOption(col?.code)
                      },
                      disabled:
                        deleteOption.isLoading || createOption.isLoading,
                    }}
                    size="extra_small"
                    type="outlined"
                  >
                    Edit
                  </Button>
                  <Button
                    antdButtonProps={{
                      className: 'text-error-red border-error-red',
                      onClick: () => {
                        deleteOption.mutate({
                          fieldId: editFieldId,
                          code: col?.code,
                        })
                      },
                      disabled: deleteOption.isLoading,
                    }}
                    size="extra_small"
                    type="outlined"
                  >
                    Delete
                  </Button>
                </div>
              ),
            },
          ]}
          dataSource={selectedField?.option}
        />
      </div>

      <Modal
        open={addOption || editOption !== undefined}
        cancelButtonDetails={{}}
        okButtonDetails={{}}
        title={addOption ? 'Add Option' : 'Edit Option'}
      >
        <FormWrapper
          antdFormProps={{
            form,
            onFinish: handleFinish,
            className: 'd-flex flex-column w-100 px-16',
          }}
        >
          <FormInput name={'name'} title="Name" required />
          <div className="d-flex w-100 gap-2">
            <Button
              antdButtonProps={{
                onClick: () => {
                  setAddOption(false)
                  setEditOption(undefined)
                },
                disabled: createOption.isLoading || updateOption.isLoading,
              }}
              block
              type="outlined"
            >
              Cancel
            </Button>
            <Button
              antdButtonProps={{
                htmlType: 'submit',
                loading: createOption.isLoading || updateOption.isLoading,
              }}
              block
            >
              Save
            </Button>
          </div>
        </FormWrapper>
      </Modal>
    </>
  )
}

export default EditOptions
