import { createQueryKeys } from '@lukemorales/query-key-factory'

export const purchaseQueries = createQueryKeys('purchase', {
  list: (q: { page_size: number; search?: string; status?: string }) => [
    q.page_size,
    q.search,
    q.status,
  ],
  info: (id: string | number) => [id],
})
