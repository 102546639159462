import React, { FC, useEffect, useRef } from 'react'
import { Col, Divider, Row } from 'antd'
import { Button } from 'yolo-design/components'
import FormFields from './FormFields'
import cn from 'classnames'
import { useSearchParams } from 'react-router-dom'

const Add: FC<{
  loading: boolean
  isUpdate: boolean
  colorsList?: Array<any>
}> = ({ loading, isUpdate, colorsList }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const itemRef = useRef<HTMLDivElement>(null)

  const addColor = searchParams.get('add_color')

  useEffect(() => {
    if (addColor === 'true') {
      itemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [])

  return (
    <div
      ref={itemRef}
      className="d-flex flex-column w-100 rounded-16 p-16 w-100 border border-grey-200 mb-16"
    >
      <div className="d-flex flex-column w-100">
        <span className="text-title-small fw-medium text-grey-700 mb-16">
          Color
        </span>
        <Row gutter={[0, 0]} className="w-100 px-0">
          <Col span={24}>
            <FormFields name="ink" />
          </Col>
          <Col span={24}>
            <FormFields name="description" />
          </Col>
          <Col span={24}>
            <Divider className="mt-0 mb-12" plain>
              <span className="text-label-extra-small text-grey-500 fw-semibold">
                LINKED CYLINDER
              </span>
            </Divider>
          </Col>
          <Col span={24}>
            <FormFields
              cylFilterIds={colorsList?.map((el) => el?.data?.cylinder?.id)}
              name="cylinder"
            />
          </Col>
          {isUpdate && (
            <Col className="pe-8" span={12}>
              <Button
                block
                type="outlined"
                antdButtonProps={{
                  htmlType: 'button',
                  disabled: loading,
                  onClick: () => {
                    searchParams.delete('add_color')
                    setSearchParams(searchParams)
                  },
                }}
              >
                Cancel
              </Button>
            </Col>
          )}
          <Col className={cn({ 'ps-8': isUpdate })} span={isUpdate ? 12 : 24}>
            <Button
              block
              type="solid"
              antdButtonProps={{ htmlType: 'submit', loading }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Add
