import React from 'react'
import cn from 'classnames'
import styles from './print.module.scss'
import dayjs from 'dayjs'
import { businessAvatarFive } from 'yolo-design/assets'
const DispatchPouchPrint = ({
  refDetail,
  dispatchData,
}: {
  refDetail: any
  dispatchData: any
}) => {
  const getAddress = (addressDetail: any) => {
    const address = addressDetail || {}
    const address1 = address.address1 || ''
    const address2 = address.address2 ? `,${address.address2}` : ''
    const city = address.city || ''
    const state = address.state_name ? `${address.state_name}` : ''
    const pincode = address.pincode ? `-${address.pincode}` : ''
    const country = address.country_name ? `,${address.country_name}` : ''
    const Address = {
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      pincode: pincode,
      country: country,
    }
    return Address
  }
  const orgShippingDetails = dispatchData?.organization_address?.find(
    (el: any) => Number(el?.address_type) === Number(1)
  )

  const orgBillingAddressFirst = `${getAddress(orgShippingDetails)?.address1}${
    getAddress(orgShippingDetails)?.address2
  }`
  const orgBillingAddressSecond = `${getAddress(orgShippingDetails)?.city},${
    getAddress(orgShippingDetails)?.state
  }${getAddress(orgShippingDetails)?.pincode}${
    getAddress(orgShippingDetails)?.country
  }`
  const data = dispatchData?.dispatch_items?.map((el: any) => {
    return {
      sl_no: el?.serial_no,
      bundle_no: el?.bundle_no,
      packet_from: el?.packet_no_from,
      packet_to: el?.packet_no_to,
      packets: el?.packet_per_bundle,
      pouch_per_packet: el?.pouch_per_packet,
      total_pouch: el?.total_pouch,
      gross_weight: el?.gross_weight,
      tare_weight: el?.tare_weight,
      net_weight: el?.net_weight,
    }
  })
  const totalPouches = data
    ?.map((el: any) => el?.total_pouch)
    ?.reduce((acc: any, cur: any) => acc + cur, 0)
  const totalPackets = data
    ?.map((el: any) => el?.packets)
    ?.reduce((acc: any, cur: any) => acc + cur, 0)

  return (
    <div>
      <table
        ref={refDetail}
        className={cn(
          styles.custom_table,
          'bg-white w-100 border text-body-medium fw-medium'
        )}
      >
        <colgroup>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
          <col width={'5%'}></col>
        </colgroup>
        <thead>
          <tr>
            <td colSpan={24}>
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex position-relative w-100">
                  <div
                    style={{
                      transform: 'translateY(-50%)',
                      width: 90,
                      height: 90,
                    }}
                    className="d-flex me-12 align-items-center position-absolute top-50 start-0"
                  >
                    {true && (
                      <img
                        src={
                          dispatchData?.organization_logo || businessAvatarFive
                        }
                        alt={'name'}
                        width={90}
                        height={90}
                        className="object-fit-contain"
                      />
                    )}
                  </div>
                  <div className="d-flex w-100 justify-content-center">
                    <div className="d-flex  w-100 flex-column align-items-center justify-content-center mt-8">
                      <span className="text-heading-medium text-break fw-bold">
                        {dispatchData?.organization_name}
                      </span>
                      <span
                        style={{ width: '447px' }}
                        className="text-body-small text-center text-break text-grey-700 fw-medium"
                      >
                        {`${orgBillingAddressFirst},${orgBillingAddressSecond}`}
                      </span>
                      <span className="text-heading-small fw-medium mt-8">
                        {'POUCH PACKING LIST'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={24}>
              <div className="d-flex  w-100 flex-column">
                <div className="d-flex w-100  border border-opacity-50 border-end-0 border-start-0 border-grey-400 justify-content-between">
                  <div className="border w-50 border-top-0 border-start-0 border-opacity-50  border-grey-400 border-bottom-0 ">
                    <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-top-0 border-grey-400 justify-content-between py-4">
                      <span className="text-label-small fw-semibold">
                        JOB NAME
                      </span>
                      <span className="text-label-small pe-4 fw-semibold">
                        {dispatchData?.job_name}
                      </span>
                    </div>
                    <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-bottom-0 border-top-0 border-grey-400 justify-content-between py-4">
                      <span className="text-label-small fw-semibold">
                        CUSTOMER
                      </span>
                      <span className="text-label-small pe-4 fw-semibold">
                        {dispatchData?.customer_name}
                      </span>
                    </div>
                  </div>

                  <div className="border w-50 border-top-0 border-start-0 border-end-0 border-grey-400 border-bottom-0 ">
                    <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-top-0 border-grey-400 justify-content-between py-4">
                      <span className="text-label-small ps-4 fw-semibold">
                        DISPATCH DOC NO
                      </span>
                      <span className="text-label-small pe-4 fw-semibold">
                        {dispatchData?.dispatch_no}
                      </span>
                    </div>
                    <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-bottom-0 border-top-0 border-grey-400 justify-content-between py-4">
                      <span className="text-label-small fw-semibold ps-4">
                        DISPATCH DOC DATE
                      </span>
                      <span className="text-label-small fw-semibold pe-4">
                        {dayjs(dispatchData?.dispatch_date).format(
                          'DD,MMM,YYYY'
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="fw-semibold">
            <td colSpan={2}>SL.</td>
            <td colSpan={3}>Bundle No</td>
            <td colSpan={3}>Pkt No From</td>
            <td colSpan={3}>Pkt No to</td>
            <td colSpan={3}>Packets</td>
            <td colSpan={3}>Pouch Per Pkt</td>
            <td colSpan={3}>Total Pouches</td>
          </tr>
          {data?.map((ell: any) => (
            <tr key={ell.sl_no}>
              <td colSpan={2}>{ell.sl_no}</td>
              <td colSpan={3}>
                <span className="text-center">{ell.bundle_no}</span>
              </td>
              <td colSpan={3} className="text-center">
                {ell.packet_from}
              </td>
              <td colSpan={3} className="text-center">
                {ell.packet_to}
              </td>
              <td colSpan={3} className="text-center">
                {ell.packets}
              </td>
              <td colSpan={3} className="text-center">
                {ell.pouch_per_packet}
              </td>
              <td colSpan={3} className="text-center">
                {ell.total_pouch}
              </td>
            </tr>
          ))}
          <tr className="fw-bold">
            <td colSpan={2}></td>
            <td colSpan={9}>Total </td>
            <td colSpan={3} className="text-center">
              {totalPackets}
            </td>
            <td colSpan={3} className="text-center"></td>
            <td colSpan={3} className="text-center">
              {totalPouches}
            </td>
          </tr>

          <tr>
            <td className="text-start" colSpan={21}>
              Created By:
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default DispatchPouchPrint
