import { ENV } from '@config/app.config'
import { ITempOrg, IUserInfo, IOrg, IUploadRes } from '@contracts/models/org'
import { GET, POST, PUT } from '@lib/AxiosClient'

export const postTempOrg = (data: Partial<ITempOrg>): Promise<ITempOrg> =>
  POST({
    url: ENV.ORG_API_BASE_URL + 'core/temp-organization/',
    data,
  })

export const putTempOrg = (
  data: Partial<ITempOrg> & { id: number }
): Promise<ITempOrg> =>
  PUT({
    url: ENV.ORG_API_BASE_URL + `core/temp-organization/${data.id}/`,
    data,
  })

export const getTempOrg = (): Promise<ITempOrg> =>
  GET({
    url: ENV.ORG_API_BASE_URL + 'core/temp-organization-retrieve/',
  })

export const getOrgUserInfo = (): Promise<IUserInfo> =>
  GET({ url: ENV.ORG_API_BASE_URL + 'core/user-info/' })

export const postCreateOrg = (data: { tempId: number }): Promise<IOrg> =>
  POST({
    url:
      ENV.ORG_API_BASE_URL +
      `core/organization/${data.tempId}/?code=MANUFACTURER`,
  })

export const postUpload = (data: FormData): Promise<IUploadRes> =>
  POST({
    url: 'organization/file-upload/',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

export const getOrgInfo = (): Promise<IOrg> =>
  GET({
    url: ENV.ORG_API_BASE_URL + 'core/organization-info/',
  })
