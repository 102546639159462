import { ENV } from '@config/app.config'
import { IDFormKeyValue } from '@contracts/models/dynamic'
import { IAllProduct, IITemProduct } from '@contracts/models/product'
import { PaginatedResponse } from '@contracts/models/shared'
import { GET } from '@lib/AxiosClient'

// export const getProductList = (): Promise<IITemProduct[]> =>
//   GET({
//     url: 'item/product/',
//   })

export const getProductList = (
  signal: AbortSignal | undefined,
  params: {
    page: number
    page_size: number
    search?: string
  }
): Promise<PaginatedResponse<IITemProduct[]>> =>
  GET({
    url: 'item/product/',
    params,
    signal,
  })

export const getAllProductList = (
  signal: AbortSignal | undefined,
  params: {
    page: number
    page_size: number
    search?: string
  }
): Promise<PaginatedResponse<IAllProduct>> =>
  GET({
    url: ENV.INVOICE_API_BASE_URL + 'item/product-list/',
    params,
    signal,
  })

export const getRawMaterialList = (params: {
  form: string
  search: string | undefined
  page_size: number
  include?: string
}): Promise<PaginatedResponse<IDFormKeyValue>> =>
  GET({
    url: 'item/raw-material/',
    params,
  })

export const getProductDetails = ({
  id,
}: {
  id: number
}): Promise<IITemProduct> =>
  GET({
    url: `item/product/${id}/`,
  })
