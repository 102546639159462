import React, { Suspense } from 'react'
import LazyLoadProgress from './LazyLoadProgress'

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<LazyLoadProgress />}>
      <Component {...props} />
    </Suspense>
  )

export default Loadable
