import { createQueryKeys } from '@lukemorales/query-key-factory'

export const productQueries = createQueryKeys('product', {
  list: (q: { page: number; page_size: number; search?: string }) => [
    q.page,
    q.page_size,
    q.search,
  ],
  item_form: (type) => [type],
  item_list: (form, search, include) => [form, search, include],
  item_key: (form) => [form],
  item_option: (id) => [id],
  all_products: (q: { page: number; page_size: number; search?: string }) => [
    q.page,
    q.page_size,
    q.search,
  ],
  info: (id) => [id],
})
