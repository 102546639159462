import { ENV } from '@config/app.config'
import { useEffect, useState } from 'react'

export const TOKEN_KEY = 'Passcode'
export const ORG_KEY = 'OrgId'

export const DRAWER_WIDTH = 488
export const NESTED_DRAWER_WIDTH = 540
export const SIDE_LIST_WIDTH = '440px'
export const DRAWER_HEADER_HEIGHT = '52px'

export const SIGNIN_LINK = `${ENV.ACCOUNTS_WEB_BASE_URL}/signin/manufacture`

export const IMAGE_EXTENSIONS = ['jpg', 'png', 'jpeg', 'webp']

export default function useDebounce(value: string, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}
