import React, { FC } from 'react'
import { Link, LinkProps, useParams } from 'react-router-dom'

const RouteLink: FC<
  { addOrg?: boolean } & LinkProps & React.RefAttributes<HTMLAnchorElement>
> = ({ addOrg = false, to, children, ...props }) => {
  const { orgId } = useParams()
  const modifiedTo = addOrg ? `/${orgId}/${to}` : to

  return (
    <Link {...props} to={modifiedTo}>
      {children}
    </Link>
  )
}

export default RouteLink
