import { RefObject, useCallback, useEffect, useRef } from 'react'
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { getNextPageParam } from '@utils/query'
import { PaginatedResponse } from '@contracts/models/shared'

type returnType<T> = UseInfiniteQueryResult<PaginatedResponse<T> | any> & {
  lastItemRef: (el: any) => void
  list?: T[]
}

export default function useInfiniteQueryRef<T>(
  options: UseInfiniteQueryOptions<PaginatedResponse<T>>,
  containerRef?: RefObject<any>
): returnType<T> {
  const observer = useRef<any>()
  const queryClient = useQueryClient()
  const query = useInfiniteQuery({
    ...options,
    getNextPageParam,
  })

  const list: T[] | undefined = query.data?.pages
    ?.map((e: any) => e.data)
    ?.flat()

  const lastItemRef = useCallback(
    (el: any) => {
      if (query.isLoading) return
      if (observer.current) {
        observer?.current?.disconnect()
      }
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && query.hasNextPage) {
            query.fetchNextPage()
          }
        },
        {
          root: containerRef?.current,
        }
      )

      if (el) observer.current.observe(el)
    },
    [query.isLoading, query.hasNextPage]
  )

  useEffect(() => {
    queryClient.cancelQueries([options.queryKey])
  }, options.queryKey)

  return { ...query, lastItemRef, list }
}
