export const SALUTATION_LIST = [
  {
    id: '1',
    label: 'Ms.',
  },
  {
    id: '2',
    label: 'Mr.',
  },
]

export const REGISTRATION_TYPE_CODE = {
  registered: 1,
  'un-registered': 2,
}

export const ADDRESS_TYPE = {
  billing: 1,
  shipping: 2,
}

export const BUSSINESS_TYPE_CODE = {
  manufacturer: 2,
}

export const PRINTING_DIRECTION_CODE = {
  readable: 1,
  un_readable: 2,
}

export const INSPECTION_CODE = {
  yes: 1,
  no: 2,
}

export const UNWINDING_DIRECTION_CODE = {
  readable: 1,
  un_readable: 2,
}
