import ProcessFormFields from '@components/Customer/FormFields/ProcessFormFields'
import EditFieldItem from '@components/ItemEdit'
import {
  PROCESS_POUCH_GUESSET_CODE,
  PROCESS_POUCH_HOLES_CODE,
  PROCESS_SLIT_TYPE_CODE,
} from '@constants/dynamic'
import { queries } from '@constants/queryKeys'
import { ProcessFormFieldsKey } from '@contracts/job'
import { IDFormKeyValue } from '@contracts/models/dynamic'
import useMapFieldKey from '@hooks/useMapFieldKey'
import useRouteNavigate from '@hooks/useRouteNavigate'
import { useDFormKeyValue, useUpdateDFormKeyValue } from '@services/dynamic'
import { useQueryClient } from '@tanstack/react-query'
import {
  convertKgToMtr,
  getPouchWeight,
  getPrintingDirection,
} from '@utils/formula'
import { Form } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Button, Drawer, FormWrapper } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { ShortArrowLeft } from 'yolo-design/icons'

const Process: FC<{
  data: IDFormKeyValue
  processFormId: string | undefined
}> = ({ data, processFormId = '' }) => {
  const [editItem, setEditItem] = useState<ProcessFormFieldsKey>()
  const [form] = Form.useForm()

  const mapFieldKey = useMapFieldKey({ formId: processFormId })

  const { customerId } = useParams()
  const routeNavigate = useRouteNavigate()
  const [searchParams] = useSearchParams()
  const queryClient = useQueryClient()

  const tabId = searchParams.get('tab')
  const specId = searchParams.get('spec')
  const jobId = searchParams.get('job') || ''

  const tabQuery = tabId ? `&tab=${tabId}` : ''
  const specQuery = specId ? `&spec=${specId}` : ''
  const jobQuery = jobId ? `&job=${jobId}` : ''

  const handleClose = () => {
    setEditItem(undefined)
  }

  const updateForm = useUpdateDFormKeyValue({
    onSuccess: (res) => {
      queryClient.setQueryData<IDFormKeyValue>(
        queries.dynamic.form_key_value(jobId).queryKey,
        (prev) => ({ ...prev, process_and_details: { ...res, id: data?.id } })
      )
      handleClose()
    },
  })

  const { data: jobInfo } = useDFormKeyValue(
    {
      enabled: !!jobId,
      staleTime: 0,
    },
    { formId: jobId }
  )

  const noOfLayerPass = jobInfo?.bill_of_materials?.bom?.filter((f: any) =>
    [3, 7, 6].includes(f?.data?.code)
  )?.length

  const handleSubmit = (val: Record<ProcessFormFieldsKey, any>) => {
    const postData = val

    const keysList = Object.keys(val)

    if (keysList.includes('pouch_type')) {
      if (!PROCESS_POUCH_GUESSET_CODE.includes(Number(val?.pouch_type))) {
        postData.guest_width = 0
      }
    }

    if (keysList.includes('punch')) {
      if (!val.punch) {
        postData.no_of_holes = 0
        postData.punch = 0
      }
    }

    if (editItem === 'bobbin_weight') {
      const bobbinMeter = convertKgToMtr({
        flimWidth: Number(jobInfo?.cylinder_details?.job_width),
        outputKg: val.bobbin_weight,
        totalGSM: jobInfo?.bill_of_materials?.total_thickness_gsm,
      })

      postData.bobbin_meter = bobbinMeter
    }

    if (editItem === 'unwinding_direction') {
      postData.printing_direction = getPrintingDirection({
        unwindingDirection: Number(val?.unwinding_direction),
        inspection: data?.inspection?.code,
        noOfLayerPass,
      })
    }

    if (editItem === 'inspection') {
      postData.printing_direction = getPrintingDirection({
        unwindingDirection: data?.unwinding_direction?.code,
        inspection: Number(val?.inspection),
        noOfLayerPass,
      })
    }

    if (data?.id) {
      updateForm.mutate({ data: mapFieldKey(postData), formId: data?.id })
    }
  }

  const pouchWeight = getPouchWeight({
    gsm: jobInfo?.bill_of_materials?.total_thickness_gsm,
    job_height: jobInfo?.cylinder_details?.job_height,
    job_width: jobInfo?.cylinder_details?.job_width,
    repeat: jobInfo?.cylinder_details?.no_of_repeat,
  })

  useEffect(() => {
    if (!data?.id && processFormId) {
      routeNavigate(
        `customer/${customerId}/job/${processFormId}/process/create?${jobQuery}${tabQuery}${specQuery}`,
        {
          addOrg: true,
        }
      )
    }
  }, [data, processFormId])

  useEffect(() => {
    if (editItem) {
      if (
        [
          'printing_direction',
          'inspection',
          'slit_type',
          'core_type',
          'pouch_type',
          'punch',
          'seal_type',
          'unwinding_direction',
        ].includes(editItem)
      ) {
        if (data?.[editItem]?.code) {
          form.setFieldsValue({ [editItem]: String(data?.[editItem]?.code) })

          if (['unwinding_direction', 'inspection'].includes(editItem)) {
            form.setFieldsValue({
              printing_direction: getPrintingDirection({
                unwindingDirection: data?.unwinding_direction?.code,
                inspection: data?.inspection?.code,
                noOfLayerPass,
              }),
            })
          }
        }
        return
      }

      form.setFieldsValue(data)
    }
  }, [editItem])

  return (
    <>
      <div className="d-flex w-100 flex-column py-16 px-24">
        <span className="text-title-large fw-medium text-grey-700 mb-16">
          Process
        </span>
        <EditFieldItem
          label="PRINTING DIRECTION"
          value={data?.printing_direction?.name}
          onEdit={
            ![1, 2].includes(data?.unwinding_direction?.code)
              ? () => setEditItem('printing_direction')
              : undefined
          }
        />
        <EditFieldItem
          label="UNWINDING DIRECTION"
          value={data?.unwinding_direction?.name}
          onEdit={() => setEditItem('unwinding_direction')}
        />
        <EditFieldItem
          label="INSPECTION"
          value={data?.inspection?.name}
          onEdit={() => setEditItem('inspection')}
        />
        <EditFieldItem
          label="PRINT WASTE"
          value={`${data?.print_waste}%`}
          onEdit={() => setEditItem('print_waste')}
        />
        <EditFieldItem
          label="LAMINATION WASTE"
          value={`${data?.lamination_waste}%`}
          onEdit={() => setEditItem('lamination_waste')}
        />
        <EditFieldItem
          label="TRIM / SLITTING WASTE"
          value={`${data?.trim_slitting_waste}%`}
          onEdit={() => setEditItem('trim_slitting_waste')}
        />
        <span className="text-title-large fw-medium text-grey-700 mb-16 mt-12">
          Slit & Dispatch
        </span>
        <EditFieldItem
          label="SLIT TYPE"
          value={data?.slit_type?.name}
          onEdit={() => setEditItem('slit_type')}
        />
        {String(data?.slit_type?.code) ===
          PROCESS_SLIT_TYPE_CODE['role_form'] && (
          <>
            <EditFieldItem
              label="CORE TYPE"
              value={data?.core_type?.name}
              onEdit={() => setEditItem('core_type')}
            />
            <EditFieldItem
              label="BOBBIN WEIGHT"
              value={data?.bobbin_weight}
              onEdit={() => setEditItem('bobbin_weight')}
            />
            <EditFieldItem
              label="BOBBIN OD"
              value={data?.bobbin_od}
              onEdit={() => setEditItem('bobbin_od')}
            />
            <EditFieldItem
              label="BOBBIN METER"
              value={data?.bobbin_meter}
              // onEdit={() => setEditItem('bobbin_meter')}
            />
          </>
        )}
        {String(data?.slit_type?.code) ===
          PROCESS_SLIT_TYPE_CODE['pouch_form'] && (
          <>
            <EditFieldItem
              label="POUCH HEIGHT"
              value={data?.pouch_height}
              onEdit={() => setEditItem('pouch_height')}
            />
            <EditFieldItem label="POUCH FLOP" value={data?.pouch_flop} />
            <EditFieldItem
              label="POUCH WIDTH"
              value={data?.pouch_width}
              onEdit={() => setEditItem('pouch_width')}
            />
            <EditFieldItem label="POUCH WEIGHT" value={pouchWeight} />
            <EditFieldItem
              label="NO OF POUCHES PER KG"
              value={
                pouchWeight
                  ? Number(1000 / Number(pouchWeight || 1)).toFixed(0)
                  : 0
              }
            />
            <EditFieldItem
              label="POUCH TYPE"
              value={data?.pouch_type?.name}
              onEdit={() => setEditItem('pouch_type')}
            />
            <EditFieldItem
              label="GUEST WIDTH"
              value={data?.guest_width}
              onEdit={
                PROCESS_POUCH_GUESSET_CODE.includes(
                  Number(data?.pouch_type?.code)
                )
                  ? () => setEditItem('guest_width')
                  : undefined
              }
            />
            <EditFieldItem
              label="SEAL WIDTH"
              value={data?.seal_width}
              onEdit={() => setEditItem('seal_width')}
            />
            <EditFieldItem
              label="PUNCH"
              value={data?.punch?.name}
              onEdit={() => setEditItem('punch')}
            />
            <EditFieldItem
              label="SEAL TYPE"
              value={data?.seal_type?.name}
              onEdit={() => setEditItem('seal_type')}
            />
            <EditFieldItem
              label="NO OF HOLES"
              value={data?.no_of_holes}
              onEdit={
                PROCESS_POUCH_HOLES_CODE.includes(Number(data?.punch?.code))
                  ? () => setEditItem('no_of_holes')
                  : undefined
              }
            />
          </>
        )}
      </div>

      <Drawer open={!!editItem} customHeader onClose={handleClose}>
        <div className="d-flex w-100 py-16 px-24 justify-content-between border-bottom border-grey-400 align-items-center">
          <Button
            type="ghost"
            size="large"
            icon={ShortArrowLeft}
            color={Color.background.dark}
            antdButtonProps={{
              onClick: handleClose,
              disabled: updateForm.isLoading,
            }}
          >
            Back
          </Button>
          <Button
            antdButtonProps={{
              onClick: () => form.submit(),
              loading: updateForm.isLoading,
            }}
            type="ghost"
            size="large"
          >
            Save
          </Button>
        </div>
        <div className="d-flex flex-column py-20 px-24 w-100">
          <FormWrapper antdFormProps={{ form, onFinish: handleSubmit }}>
            {editItem === 'pouch_height' ? (
              <>
                <ProcessFormFields name={'pouch_height'} />
                <ProcessFormFields name={'pouch_flop'} />
              </>
            ) : (
              <>{editItem && <ProcessFormFields name={editItem} />}</>
            )}
          </FormWrapper>
        </div>
      </Drawer>
    </>
  )
}

export default Process
