import React, { FC, RefObject } from 'react'
import styles from './style.module.scss'
import cn from 'classnames'
import { IDFormKeyValue } from '@contracts/models/dynamic'
import { IClientInfo } from '@contracts/models/customer'
import { getPouchWeight } from '@utils/formula'
import { IOrg } from '@contracts/models/org'
import { reorderBOM } from '@utils/dynamic'
import { RAW_MATERIAL_FORM_CODE } from '@constants/dynamic'

const JobInternalSpecificationV2: FC<{
  elRef: RefObject<any>
  data?: IDFormKeyValue
  orgInfo: IOrg | undefined
  customerInfo: IClientInfo | undefined
}> = ({ elRef, data, orgInfo, customerInfo }) => {
  const bomList = data?.bill_of_materials?.bom
  const bom = reorderBOM(bomList, data?.bill_of_materials?.printing_type?.code)

  return (
    <table
      ref={elRef}
      className={cn(
        styles.jis_table,
        'bg-white w-100 border text-body-medium fw-medium'
      )}
    >
      <colgroup>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
      </colgroup>
      <tbody>
        <tr>
          <td colSpan={2} rowSpan={3}></td>
          <td
            colSpan={14}
            className="text-heading-small text-center fw-bold"
            rowSpan={2}
          >
            {orgInfo?.name}
          </td>
          <td colSpan={4} className="text-start">
            Ref. No:
          </td>
        </tr>
        <tr>
          <td colSpan={20} className="text-start">
            Rev. No:
          </td>
        </tr>
        <tr>
          <td colSpan={14} className="text-center fw-bold">
            INTERNAL SPECIFICATION SHEET
          </td>
          <td colSpan={6} className="text-start">
            Rev. Dated:
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={20}></td>
        </tr>
        <tr>
          <td colSpan={8}>Customer</td>
          <td colSpan={12}>{customerInfo?.display_name}</td>
        </tr>
        <tr>
          <td colSpan={8}>Brand</td>
          <td colSpan={12}>{data?.job?.name}</td>
        </tr>
        <tr>
          <td colSpan={8}>Coil Width</td>
          <td colSpan={12}>{data?.cylinder_details?.job_width}+/- 1mm</td>
        </tr>
        <tr>
          <td colSpan={8}>Repeat Length</td>
          <td colSpan={12}>{data?.cylinder_details?.job_height}+/- 1mm</td>
        </tr>
        <tr>
          <td colSpan={8}>Material</td>
          <td className="fw-bold" colSpan={4}>
            Thickness(µ)
          </td>
          <td className="fw-bold" colSpan={4}>
            Grade
          </td>
          <td className="fw-bold" colSpan={4}>
            Source
          </td>
        </tr>
        {bom?.map((el) => {
          const bomType = el?.data?.code
          const layer = el?.data?.layer
          return (
            <React.Fragment key={el.id}>
              {bomType === RAW_MATERIAL_FORM_CODE['ink'] && (
                <tr>
                  <td colSpan={8}>Ink</td>
                  <td colSpan={12}></td>
                </tr>
              )}

              {bomType === RAW_MATERIAL_FORM_CODE['flim'] && (
                <tr>
                  <td colSpan={8}>{layer?.name}</td>
                  <td colSpan={4}>{layer?.micron} µ</td>
                  <td colSpan={4}>{layer?.grade}</td>
                  <td colSpan={4}>{layer?.brand}</td>
                </tr>
              )}

              {bomType === RAW_MATERIAL_FORM_CODE['adhesive'] && (
                <tr>
                  <td colSpan={8}>Adhesive Pass</td>
                  <td colSpan={8}>{layer?.name}</td>
                  <td colSpan={4}>{layer?.brand}</td>
                </tr>
              )}
            </React.Fragment>
          )
        })}
        <tr>
          <td className="fw-bold text-start" colSpan={20}>
            SPECIFICATIONS:
          </td>
        </tr>
        <tr className="fw-bold">
          <td colSpan={2}>S No</td>
          <td colSpan={6}>Parameters</td>
          <td colSpan={3}>UOM</td>
          <td colSpan={3}>Customer Spec</td>
          <td colSpan={3}>Internal</td>
          <td colSpan={3}>Internal Tolerance</td>
        </tr>
        <tr>
          <td colSpan={2}>1</td>
          <td colSpan={6}>Poly Gsm</td>
          <td colSpan={3}>g/sq mtr</td>
          <td colSpan={3}></td>
          <td colSpan={3}></td>
          <td colSpan={3}></td>
        </tr>
        <tr>
          <td colSpan={2}>2</td>
          <td colSpan={6}>Ink Gsm</td>
          <td colSpan={3}>g/sq mtr</td>
          <td colSpan={3}></td>
          <td colSpan={3}></td>
          <td colSpan={3}></td>
        </tr>
        <tr>
          <td colSpan={2}>3</td>
          <td colSpan={6}>Total Gsm</td>
          <td colSpan={3}>g/sq mtr</td>
          <td colSpan={3}></td>
          <td colSpan={3}></td>
          <td colSpan={3}></td>
        </tr>
        <tr>
          <td colSpan={2}>4</td>
          <td colSpan={6}>COF</td>
          <td colSpan={3}>-</td>
          <td colSpan={9}></td>
        </tr>
        <tr>
          <td colSpan={2}>5</td>
          <td colSpan={6}>Core ID</td>
          <td colSpan={3}>mm</td>
          <td colSpan={3}></td>
          <td colSpan={3}></td>
          <td colSpan={3}></td>
        </tr>
        <tr>
          <td colSpan={2}>6</td>
          <td colSpan={6}>Bobbin Weight</td>
          <td colSpan={3}>kg</td>
          <td colSpan={3}></td>
          <td colSpan={3}></td>
          <td colSpan={3}></td>
        </tr>
        <tr>
          <td colSpan={2}>7</td>
          <td colSpan={6}>Core Type</td>
          <td colSpan={3}>-</td>
          <td colSpan={9}>{data?.process_and_details?.core_type?.name}</td>
        </tr>
        <tr>
          <td colSpan={2}>8</td>
          <td colSpan={6}>unwinding Direction</td>
          <td colSpan={3}>-</td>
          <td colSpan={9}>
            {data?.process_and_details?.unwinding_direction?.name}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>9</td>
          <td colSpan={6}>Pouch Type</td>
          <td colSpan={3}>-</td>
          <td colSpan={9}>{data?.process_and_details?.pouch_type?.name}</td>
        </tr>
        <tr>
          <td colSpan={2}>10</td>
          <td colSpan={6}>Pouch Height</td>
          <td colSpan={3}>mm</td>
          <td colSpan={9}>{data?.process_and_details?.pouch_height} mm</td>
        </tr>
        <tr>
          <td colSpan={2}>11</td>
          <td colSpan={6}>Pouch Width</td>
          <td colSpan={3}>mm</td>
          <td colSpan={9}>{data?.process_and_details?.pouch_width} mm</td>
        </tr>
        <tr>
          <td colSpan={2}>12</td>
          <td colSpan={6}>Guesst</td>
          <td colSpan={3}>mm</td>
          <td colSpan={9}>{data?.process_and_details?.guest_width} mm</td>
        </tr>
        <tr>
          <td colSpan={2}>13</td>
          <td colSpan={6}>Flap</td>
          <td colSpan={3}>mm</td>
          <td colSpan={9}></td>
        </tr>
        <tr>
          <td colSpan={2}>14</td>
          <td colSpan={6}>Seal Width</td>
          <td colSpan={3}>mm</td>

          <td colSpan={9}>{data?.process_and_details?.seal_width} mm</td>
        </tr>
        <tr>
          <td colSpan={2}>15</td>
          <td colSpan={6}>Pouch Weight</td>
          <td colSpan={3}>g</td>
          <td colSpan={9}>
            {getPouchWeight({
              gsm: data?.bill_of_materials?.total_thickness_gsm,
              job_height: data?.cylinder_details?.job_height,
              job_width: data?.cylinder_details?.job_width,
              repeat: data?.cylinder_details?.no_of_repeat,
            })}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>16</td>
          <td colSpan={6}>Curing Time</td>
          <td colSpan={3}>Hrs</td>
          <td colSpan={9}></td>
        </tr>
        <tr>
          <td colSpan={2}>17</td>
          <td colSpan={6}>Cylinder Length</td>
          <td colSpan={3}>mm</td>
          <td colSpan={9}>{data?.cylinder_details?.cylinder_length} mm</td>
        </tr>
        <tr>
          <td colSpan={2}>18</td>
          <td colSpan={6}>Cylinder Circum</td>
          <td colSpan={3}>mm</td>
          <td colSpan={9}>
            {data?.cylinder_details?.cylinder_circumference} mm
          </td>
        </tr>
        <tr>
          <td
            style={{
              height: '160px',
            }}
            className="text-start"
            colSpan={20}
          >
            <div className="d-flex align-items-start h-100 fw-bold">
              Remarks:
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={7}>Prepared By</td>
          <td colSpan={7}>Checked By</td>
          <td colSpan={6}>Approved By</td>
        </tr>
      </tbody>
    </table>
  )
}

export default JobInternalSpecificationV2
