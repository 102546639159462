import React, { useEffect, useMemo, useState } from 'react'
import { MainLayout as Layout } from 'yolo-design/layouts'
import { businessAvatarThree, yoloManufactureLogo } from 'yolo-design/assets'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import {
  Add,
  DeliveryChallan,
  LogOut,
  Person,
  Purchase,
  Sale,
  Setting,
  Share,
} from 'yolo-design/icons'
import { Color } from 'yolo-design/constants'
import RouteLink from '@components/RouteLink'
import useRouteNavigate from '@hooks/useRouteNavigate'
import { useOrgUserInfo } from '@services/org'
import { useLogout } from '@services/user'
import { removeOrgId, removePasscode, setOrgId } from '@utils/auth'
import { useJobSearchList } from '@services/dynamic'

const MainLayout = () => {
  const routeNavigate = useRouteNavigate()
  const { orgId } = useParams()
  const [selectedMenu, setSelectedMenu] = useState('')
  const { pathname } = useLocation()
  const [jobSearch, setJobSearch] = useState('')

  const { data: userInfo } = useOrgUserInfo()

  const { data: jobSearchList, isLoading: jobLoading } = useJobSearchList(
    {},
    { search: jobSearch }
  )

  const logout = useLogout({
    onSuccess: () => {
      removePasscode()
      removeOrgId()
      window.location.href = '/'
    },
  })

  const menuItems = useMemo(
    () => [
      {
        key: '1',
        label: (
          <RouteLink addOrg to={`customer`}>
            Customer
          </RouteLink>
        ),
        icon: Sale,
        link: `customer`,
      },
      {
        key: '2',
        label: (
          <RouteLink addOrg to={`work`}>
            Work
          </RouteLink>
        ),
        icon: Add,
        link: `work`,
      },
      {
        key: '4',
        label: (
          <RouteLink addOrg to={`dispatch`}>
            Dispatch
          </RouteLink>
        ),
        icon: DeliveryChallan,
        link: `dispatch`,
      },
      {
        key: '3',
        label: (
          <RouteLink addOrg to={`purchase-indent`}>
            Purchase indent
          </RouteLink>
        ),
        icon: Purchase,
        link: `purchase-indent`,
      },
    ],
    []
  )

  const handleOrgClick = (id: string) => {
    setOrgId(id)
    const pathArray = pathname.split('/')
    pathArray[1] = id
    routeNavigate(pathArray.slice(0, 3).join('/'))
  }

  useEffect(() => {
    setSelectedMenu(
      menuItems.find((f) => f.link === pathname.split('/')[2])?.key || ''
    )
  }, [pathname, menuItems])

  return (
    <Layout
      appMenu={{ appName: 'manufacturer', hide: [] }}
      logo={yoloManufactureLogo}
      onLogoClick={() => routeNavigate(``, { addOrg: true })}
      selectedOrganisationId={orgId}
      onAddOrgClick={() =>
        routeNavigate(`/org/create?orgId=${orgId}&redirectUrl=${pathname}`)
      }
      searchInputProps={{
        onSearch: (val) => setJobSearch(val),
        loading: jobLoading,
        value: 'Search Job by Name / Code',
        optionLabelProp: 'name',
        searchValue: jobSearch,
        options: jobSearchList?.data?.map((el) => ({
          ...el,
          name: el.data.name,
          value: el.data.id,
          label: (
            <RouteLink
              to={`customer/${el?.data?.customer_id?.id}?job=${el.data.id}`}
              addOrg
            >
              <div className="d-flex flex-column py-4">
                <span className="text-body-small">Name: {el.data.name}</span>
                <span className="text-body-small mt-4">
                  Code: {el.data.job_code}
                </span>
              </div>
            </RouteLink>
          ),
        })),
      }}
      profileContent={{
        image: '',
        label: userInfo?.first_name || '',
        email: userInfo?.email,
      }}
      onManageOrganisationClick={() => ''}
      organizationList={
        userInfo?.organization?.map((el) => ({
          id: el.id,
          name: el.name,
          logo: businessAvatarThree,
          gst: el.gst_num || 'Un Registered',
          onClick: () => handleOrgClick(el.id),
        })) || []
      }
      profileMenuList={[
        {
          label: 'View Profile',
          icon: Person,
          onClick: () => routeNavigate(`user-profile`, { addOrg: true }),
        },
        {
          label: 'Settings',
          icon: Setting,
          onClick: () => routeNavigate(`settings`, { addOrg: true }),
        },
        {
          label: 'Share Access',
          icon: Share,
          onClick: () => '',
        },
        {
          label: 'Log out',
          icon: LogOut,
          color: Color.indicators.error,
          onClick: () => {
            logout.mutate({})
          },
        },
      ]}
      menuItems={menuItems}
      selectedMenu={selectedMenu}
    >
      <Outlet />
    </Layout>
  )
}

export default MainLayout
