import TableNameCard from '@components/TableNameCard'
import { ColumnsType } from 'antd/es/table'
import React, { FC } from 'react'
import { Table } from 'yolo-design/components'

const CylinderTable: FC<{
  data: Record<string, any>
}> = ({ data }) => {
  const colorData = data?.color?.colors

  const columns: ColumnsType<any> = [
    {
      title: 'NAME',
      dataIndex: ['data', 'cylinder', 'cylinder_name'],
      render: (val, column) => (
        <TableNameCard
          image={column?.data?.cylinder?.cylinder_image}
          title={val}
          subTitle=""
        />
      ),
    },
    {
      title: 'LOCATION',
      dataIndex: ['data', 'cylinder', 'cylinder_location'],
    },
    {
      title: 'CYLINDER CODE',
      dataIndex: ['data', 'cylinder', 'cylinder_cylinder_code'],
    },
    {
      title: 'CYLINDER USE',
      dataIndex: ['data', 'cylinder', 'cylinder_cylinder_use', 'label'],
    },
    {
      title: 'ASSIGNED COLOR',
      dataIndex: ['data', 'ink', 'name'],
      onCell: () => {
        return {
          className: 'bg-grey-200',
        }
      },
    },
  ]

  return (
    <div className="d-flex flex-column w-100 mt-32">
      <span className="text-title-medium fw-bold">Cylinder</span>
      <Table
        className="mt-12"
        rowKey={'id'}
        columns={columns}
        dataSource={colorData?.map((el: any) => ({
          data: {
            ...(el?.data || {}),
            ink: {
              ...(el?.data?.ink || {}),
              name:
                el?.data?.ink?.name ||
                el?.data?.ink?.group_name ||
                el?.data?.description,
            },
          },
        }))}
      />
    </div>
  )
}

export default CylinderTable
