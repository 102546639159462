import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Middleware from '@middlewares/index'
import MainLayout from '@components/Layout/MainLayout'
import Loadable from '@components/Loadable'
import useSentry from '@hooks/useSentry'
import WorkView from '@pages/Work/View'
import PurchaseIntent from '@pages/PurchaseIntent'
import FormSelectFields from '@pages/Settings/FormSelectFields'
import Settings from '@pages/Settings'
import Dispatch from '@pages/Dispatch'
import DispatchView from '@pages/Dispatch/View'

const Dashboard = Loadable(lazy(() => import('@pages/Dashboard')))
const Home = Loadable(lazy(() => import('@pages/Home')))
const Customer = Loadable(lazy(() => import('@pages/Customer')))
const CustomerView = Loadable(lazy(() => import('@pages/Customer/View')))
const CustomerInfo = Loadable(lazy(() => import('@pages/Customer/Info')))
const CreateJob = Loadable(lazy(() => import('@pages/Customer/Job/Create')))
const Work = Loadable(lazy(() => import('@pages/Work')))
const CreateCylinder = Loadable(
  lazy(() => import('@pages/Customer/Job/Cylinder/Create'))
)
const CreateProcess = Loadable(
  lazy(() => import('@pages/Customer/Job/Process/Create'))
)
const CreateOrg = Loadable(lazy(() => import('@pages/Org/Create')))
const Register = Loadable(lazy(() => import('@pages/User/Register')))

const Routes = () => {
  const { sentryRoutes } = useSentry()

  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Middleware />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        // {
        //   path: 'print',
        //   element: <Print />,
        // },
        // * <-------- MAIN LAYOUT -------->
        {
          path: ':orgId',
          element: <MainLayout />,
          children: [
            {
              index: true,
              element: <Dashboard />,
            },
            {
              path: 'settings',
              element: <Settings />,
              children: [
                {
                  index: true,
                  element: <FormSelectFields />,
                },
                {
                  path: 'form-select',
                  element: <FormSelectFields />,
                },
              ],
            },
            {
              path: 'customer',
              element: <Customer />,
              children: [
                {
                  path: ':customerId',
                  element: <CustomerView />,
                },
                {
                  path: ':customerId/info',
                  element: <CustomerInfo />,
                },
              ],
            },
            {
              path: 'work',
              element: <Work />,
            },
            {
              path: 'dispatch',
              element: <Dispatch />,
            },
            {
              path: 'purchase-indent',
              element: <PurchaseIntent />,
            },
          ],
        },

        {
          path: ':orgId/purchase-indent/frame',
          element: <PurchaseIntent />,
        },

        // * <-------- NO LAYOUT -------->
        {
          path: ':orgId',
          children: [
            {
              path: 'work-form',
              element: <WorkView />,
            },
          ],
        },
        {
          path: ':orgId',
          children: [
            {
              path: 'dispatch-form',
              element: <DispatchView />,
            },
          ],
        },
        // * <-------- LOGO LAYOUT -------->
        {
          path: ':orgId',
          children: [
            {
              path: 'customer/:customerId/job/create/:formId',
              element: <CreateJob />,
            },
            {
              path: 'customer/:customerId/job/:formId/cylinder/create',
              element: <CreateCylinder />,
            },
            {
              path: 'customer/:customerId/job/:formId/process/create',
              element: <CreateProcess />,
            },
          ],
        },
        {
          path: 'org/create',
          element: <CreateOrg />,
        },
        {
          path: 'user/register',
          element: <Register />,
        },
      ],
    },
  ]

  return sentryRoutes(routes)
}

export default Routes
