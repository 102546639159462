import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, Tab, Upload } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { DoubleChevronRight, Print, UploadIcon } from 'yolo-design/icons'
import BasicInfo from './BasicInfo'
import Specification from './Specification'
import { DRAWER_HEADER_HEIGHT } from '@constants/index'
import useRouteNavigate from '@hooks/useRouteNavigate'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  useDFormJobId,
  useDFormKeyValue,
  useDFormTypeJobId,
  useUpdateDFormKeyValue,
} from '@services/dynamic'
import Image from '@components/Image'
import { IDFormKeyValue } from '@contracts/models/dynamic'
import { useReactToPrint } from 'react-to-print'
import { useOrgInfo, useUpload } from '@services/org'
import JobInternalSpecificationV2 from '@components/Print/JobInternalSpecificationV2'
import { useCustomerInfo } from '@services/customer'
import styles from './styles.module.scss'
import cn from 'classnames'
import { getFormData, isLt5M, isValidFormat } from '@utils/upload'
import { useQueryClient } from '@tanstack/react-query'
import { queries } from '@constants/queryKeys'
import useMapFieldKey from '@hooks/useMapFieldKey'
import nProgress from 'nprogress'

const tabKey: Record<string, string> = {
  'job-info': 'job-info',
  specification: 'specification',
}

const JobInfo: FC<{ initialData: IDFormKeyValue | undefined }> = ({
  initialData,
}) => {
  const routeNavigate = useRouteNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { customerId = '' } = useParams()
  const [isPrinting, setIsPrinting] = useState(false)
  const queryClient = useQueryClient()

  const tabId = searchParams.get('tab') || tabKey['job-info']
  const jobId = searchParams.get('job') || ''

  const formTypeId = useDFormTypeJobId() || ''
  const jobFormId = useDFormJobId(formTypeId) || ''
  const mapFieldKey = useMapFieldKey({ formId: jobFormId })

  const { data } = useDFormKeyValue(
    {
      enabled: !!jobId,
      initialData: {
        job: initialData,
      },
      staleTime: 0,
    },
    { formId: jobId }
  )

  const updateForm = useUpdateDFormKeyValue({
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: queries.dynamic.form_key_value_list({
          formId: jobFormId,
          search: '',
          customer: customerInfo,
        }).queryKey,
      })
      queryClient.setQueryData<IDFormKeyValue>(
        queries.dynamic.form_key_value(jobId).queryKey,
        (prev) => ({ ...prev, job: data })
      )
    },
    onSettled: () => {
      nProgress.done()
    },
  })

  const upload = useUpload({
    onSuccess: (data) => {
      updateForm.mutate({
        data: mapFieldKey({ image: data.file }),
        formId: jobId,
      })
    },
    onError: () => {
      nProgress.done()
    },
  })

  const customerInfo = useCustomerInfo(
    { enabled: !!customerId },
    { id: customerId }
  )

  const job = data?.job

  const { data: orgInfo } = useOrgInfo()

  const componentRef = useRef(null)

  const handlePrint = useReactToPrint({
    documentTitle: job?.job_code || 'internal-specification',
    pageStyle: '@page { size: 210mm 297mm; margin: 4mm; }',
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true)
    },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
  })

  // const menuContent = (
  //   <div className="d-flex flex-column w-max-content px-4">
  //     <Button
  //       color={Color.neutral_color.black}
  //       antdButtonProps={{
  //         onClick: handlePrint,
  //         disabled: !job?.id || !orgInfo?.id,
  //         loading: isPrinting,
  //       }}
  //       icon={Download}
  //       type="ghost"
  //     >
  //       Internal Specification
  //     </Button>
  //   </div>
  // )

  useEffect(() => {
    nProgress.configure({ parent: '#drawer-container' })

    return () => {
      nProgress.configure({ parent: 'body' })
    }
  }, [])

  return (
    <>
      <div className="d-flex flex-column w-100 h-100">
        <div
          style={{ height: DRAWER_HEADER_HEIGHT }}
          className="d-flex w-100 px-24 align-items-center justify-content-between border-bottom border-grey-400"
        >
          <div className="d-flex">
            <Button
              color={Color.neutral_color.black}
              size="large"
              type="ghost"
              icon={DoubleChevronRight}
              iconPosition="right"
              antdButtonProps={{
                onClick: () => {
                  if (searchParams.get('viewJob') === 'true') {
                    searchParams.set('viewJob', 'false')
                    setSearchParams(searchParams)
                  } else {
                    routeNavigate(`customer/${customerId}`, { addOrg: true })
                  }
                },
              }}
            >
              Close
            </Button>
          </div>
          {/* <Popover
            placement="bottomRight"
            title={null}
            trigger={'click'}
            content={menuContent}
            arrow={false}
            align={{ offset: [0, -10] }}
          >
            <KababMenu className="cursor-pointer" size="medium" weight="slim" />
          </Popover> */}

          <Button
            color={Color.neutral_color.black}
            antdButtonProps={{
              onClick: handlePrint,
              disabled: true,
              // disabled: !job?.id || !orgInfo?.id,
              loading: isPrinting,
            }}
            icon={Print}
            type="ghost"
          >
            Internal Specification
          </Button>
        </div>
        <div
          style={{ height: 'calc(100vh - 53px)' }}
          className="d-flex flex-column w-100 overflow-scroll"
          id="drawer-container"
        >
          <div className="d-flex w-100 px-24 py-16">
            <div
              className={cn(
                'd-flex w-max-content position-relative',
                styles.up_image
              )}
            >
              <Image
                className="rounded-12 overflow-hidden object-fit-cover"
                height={120}
                width={92}
                src={job?.image}
                alt={job?.name}
              />
              <div
                className={cn(
                  'd-none rounded-12 justify-content-center align-items-center position-absolute w-100 h-100 bg-black text-white',
                  styles.up_overlay
                )}
              >
                <Upload
                  showUploadList={false}
                  beforeUpload={(file) =>
                    isLt5M(file.size) && isValidFormat(file.type)
                  }
                  customRequest={({ file }: any) => {
                    nProgress.start()
                    upload.mutate(getFormData(file))
                  }}
                >
                  <UploadIcon
                    size="large"
                    weight="thick"
                    className="cursor-pointer"
                    color={Color.neutral_color.white}
                  />
                </Upload>
              </div>
            </div>
            <div className="d-flex flex-column ms-16 justify-content-around">
              <span className="text-heading-medium fw-semibold text-dark truncate-text-2">
                {job?.name}
              </span>
              <span className="text-title-small fw-medium text-grey-700">
                Job Code: {job?.job_code}
              </span>
            </div>
          </div>

          <div className="d-flex w-100 px-24">
            <Tab
              items={[
                {
                  key: tabKey['job-info'],
                  label: 'Job Info',
                },
                {
                  key: tabKey['specification'],
                  label: 'Specification',
                },
              ]}
              activeKey={tabId}
              onChange={(key) => {
                searchParams.set('tab', tabKey[key])
                setSearchParams(searchParams)
              }}
            />
          </div>
          <div
            style={{ marginTop: '-17px' }}
            className="d-flex w-100  border-top border-grey-400"
          >
            {tabId === tabKey['job-info'] && <BasicInfo data={job} />}
            {tabId === tabKey['specification'] && <Specification />}
          </div>
        </div>
      </div>
      <div className="w-100 d-none">
        <JobInternalSpecificationV2
          elRef={componentRef}
          data={data}
          orgInfo={orgInfo}
          customerInfo={customerInfo?.data}
        />
        {/* <JobInternalSpecification
          elRef={componentRef}
          data={data}
          orgName={orgInfo?.name || ''}
        /> */}
      </div>
    </>
  )
}

export default JobInfo
