import {
  IBussinessCategory,
  IBussinessType,
  ICountry,
  IRegistrationType,
  IState,
} from '@contracts/models/master'
import { GET } from '@lib/AxiosClient'

export const getCountry = (): Promise<ICountry[]> =>
  GET({
    url: 'core/country/',
    headers: {
      Authorization: null,
    },
  })

export const getStateList = (data: { countryId: number }): Promise<IState[]> =>
  GET({ url: `core/state/${data.countryId}/` })

export const getBusinessType = (): Promise<IBussinessType[]> =>
  GET({
    url: 'core/business-type/',
  })

export const getBusinessCategory = (params: {
  type?: number
}): Promise<IBussinessCategory[]> =>
  GET({
    url: 'core/business-category/',
    params,
  })

export const getRegistrationType = (): Promise<IRegistrationType[]> =>
  GET({ url: 'core/registration-type/' })
export const getNumberGenerator = (): Promise<any> =>
  GET({ url: 'process/number-generator/' })
