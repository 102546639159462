import { Col, Row } from 'antd'
import React, { FC, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, FormSelect } from 'yolo-design/components'
import SelectItem from '../SelectItem'
import { UseQueryResult } from '@tanstack/react-query'
import { PaginatedResponse } from '@contracts/models/shared'
import { IDFormKeyValue } from '@contracts/models/dynamic'

const Add: FC<{
  loading: boolean
  flimAdhesiveList: UseQueryResult<PaginatedResponse<IDFormKeyValue>>
  setSearch: React.Dispatch<React.SetStateAction<string>>
}> = ({ loading, setSearch, flimAdhesiveList }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const bomRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (searchParams.get('add_bom') === 'true') {
      bomRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [])

  return (
    <Row ref={bomRef} gutter={[0, 0]}>
      <Col span={24}>
        <FormSelect
          required
          name={'layer'}
          placeHolder="Add item by entering Name or SKU"
          optionsSelect={flimAdhesiveList?.data?.data?.map((el) => ({
            ...el,
            value: el.form_entity,
            name: el.name,
            label: (
              <SelectItem
                image={
                  el.flim_image ||
                  el.poly_image ||
                  el.adhesive_image ||
                  el.group_image
                }
                name={el.name || el.name || el.name || el.group_name}
                sku={el.sku_code}
              />
            ),
          }))}
          antdSelectProps={{
            showSearch: true,
            onSearch: (val) => {
              setSearch(val)
            },
            loading: flimAdhesiveList?.isLoading,
            filterOption: false,
            optionLabelProp: 'name',
            // onSelect: (key) => {
            //   const layerDetails = flimAdhesiveList.data?.data?.find(
            //     (f) => String(f.id) === key
            //   )

            //   form.setFieldValue('layer_form_code', layerDetails?.form_code)
            // },
          }}
        />
      </Col>
      <Col className="pe-8" span={12}>
        <Button
          block
          type="outlined"
          antdButtonProps={{
            htmlType: 'button',
            disabled: loading,
            onClick: () => {
              searchParams.delete('add_bom')
              searchParams.delete('edit_id')
              setSearchParams(searchParams)
            },
          }}
        >
          Cancel
        </Button>
      </Col>
      <Col className="ps-8" span={12}>
        <Button block type="solid" antdButtonProps={{ htmlType: 'submit' }}>
          Save
        </Button>
      </Col>
    </Row>
  )
}

export default Add
