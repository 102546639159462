import React, { FC, RefObject } from 'react'
import cn from 'classnames'
import { useOrgInfo } from '@services/org'
import Image from '@components/Image'
import { useSearchParams } from 'react-router-dom'
import { usePurchaseIndentInfo } from '@services/purchaseIndent'
import styles from './style.module.scss'

const PurchaseIndent: FC<{ elRef: RefObject<any> }> = ({ elRef }) => {
  const [searchParams] = useSearchParams()
  const { data: orgInfo } = useOrgInfo()

  const viewId = searchParams.get('view_id') || ''

  const { data } = usePurchaseIndentInfo({}, viewId)

  return (
    <table
      ref={elRef}
      className={cn('w-100 text-label-small fw-medium bg-white')}
    >
      <colgroup>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
      </colgroup>
      <thead>
        <tr>
          <td colSpan={20} className="w-100">
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex position-relative w-100">
                <div
                  style={{
                    transform: 'translateY(-50%)',
                  }}
                  className="d-flex me-12 align-items-center position-absolute top-50 start-0"
                >
                  {!!orgInfo?.logo && (
                    <Image
                      src={orgInfo?.logo}
                      alt={orgInfo?.name}
                      width={60}
                      height={60}
                      className="object-fit-contain"
                    />
                  )}
                </div>
                <div className="d-flex w-100 justify-content-center py-8">
                  <div className="d-flex flex-column align-items-center mt-8">
                    <span className="text-title-large fw-medium">
                      {orgInfo?.name}
                    </span>
                    <span className="text-title-medium fw-medium mt-12">
                      Purchase Indent Form
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={20}>
            <div
              style={{ width: '100%' }}
              className="mt-12 d-flex  border border-opacity-50 border-end-0 border-start-0 border-grey-400 justify-content-between"
            >
              <div
                style={{ width: '50%' }}
                className="border border-top-0 border-start-0 border-opacity-50  border-grey-400 border-bottom-0 "
              >
                <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-top-0 border-grey-400 justify-content-between py-4">
                  <span className="text-label-extra-small fw-semibold ps-4">
                    INDENT NO.
                  </span>
                  <span className="text-label-small pe-4">
                    {data?.indent_number}
                  </span>
                </div>
                <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-bottom-0 border-top-0 border-grey-400 justify-content-between py-4">
                  <span className="text-label-extra-small fw-semibold ps-4">
                    INDENT DATE
                  </span>
                  {data?.indent_date ? (
                    <span className="text-label-small pe-4">
                      {new Date(data?.indent_date).toLocaleDateString()}
                    </span>
                  ) : (
                    '-'
                  )}
                </div>
              </div>

              <div
                style={{ width: '50%' }}
                className="border border-top-0 border-start-0 border-end-0 border-grey-400 border-bottom-0 "
              >
                <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-top-0 border-grey-400 justify-content-between py-4">
                  <span className="text-label-extra-small fw-semibold ps-4">
                    CREATED BY
                  </span>
                  <span className="text-label-small pe-4">
                    {data?.created_by}
                  </span>
                </div>
                <div className="d-flex border border-start-0 border-opacity-50 border-end-0 border-bottom-0 border-top-0 border-grey-400 justify-content-between py-4">
                  <span className="text-label-extra-small fw-semibold ps-4">
                    DEPARTMENT
                  </span>
                  <span className="text-label-small pe-4"></span>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={20}>
            <div className="d-flex w-100 mt-12 justify-content-center">
              <span className="fw-medium text-body-medium mb-8">
                Required Item Material
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={20}>
            <table className={cn('w-100 border', styles.jis_table)}>
              <thead className="w-100">
                <tr>
                  <td colSpan={1} width={'50px'} className="fw-bold">
                    S No.
                  </td>
                  <td colSpan={2} width={'100px'} className="fw-bold">
                    Sku Code
                  </td>
                  <td colSpan={9} width={'200px'} className="fw-bold">
                    Item Name
                  </td>
                  <td colSpan={4} width={'50px'} className="fw-bold text-start">
                    Quantity
                  </td>
                  <td colSpan={4} width={'50px'} className="fw-bold text-start">
                    unit
                  </td>
                </tr>
              </thead>
              <tbody>
                {data?.items?.map((el, i) => (
                  <tr key={el.id}>
                    <td colSpan={1}>{i + 1}</td>
                    <td colSpan={2} className="text-start">
                      {el.item_sku}
                    </td>
                    <td colSpan={9} className="text-start">
                      {el.item_name}
                    </td>
                    <td colSpan={4} className="text-start">
                      {el.quantity}
                    </td>
                    <td colSpan={4} className="text-start">
                      {el.unit_name}
                    </td>
                  </tr>
                ))}
                {Array.from({ length: 20 - (data?.items?.length || 0) })
                  ?.fill(1)
                  ?.map((el, i) => (
                    <tr key={'a' + i}>
                      <td colSpan={1}>{(data?.items?.length || 0) + i + 1}</td>
                      <td colSpan={2}></td>
                      <td colSpan={9}></td>
                      <td colSpan={4}></td>
                      <td colSpan={4}></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan={20}>
            <div className="d-flex w-100 mt-12">
              <div
                style={{ width: '25%' }}
                className="d-flex flex-column border px-4 border-end-0"
              >
                <span className="pb-48 mb-16 text-label-extra-small">
                  Department
                </span>
              </div>
              <div
                style={{ width: '25%' }}
                className="d-flex flex-column border px-4 border-end-0"
              >
                <span className="text-label-extra-small">
                  Purchase Received
                </span>
              </div>
              <div
                style={{ width: '25%' }}
                className="d-flex flex-column border px-4 border-end-0"
              >
                <span className="text-label-extra-small ">
                  Verified by <br />
                  (Production Department){' '}
                </span>
              </div>
              <div
                style={{ width: '25%' }}
                className="d-flex flex-column border px-4"
              >
                <span className="text-label-extra-small">
                  Approved by <br />
                  (MD)
                </span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default PurchaseIndent
