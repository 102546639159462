import React, { FC, useEffect, useState } from 'react'
import CylinderFormFields from '@components/Customer/FormFields/CylinderFormFields'
import EditFieldItem from '@components/ItemEdit'
import { queries } from '@constants/queryKeys'
import { CylinderFormFieldsKey } from '@contracts/job'
import { IDFormKeyValue } from '@contracts/models/dynamic'
import useMapFieldKey from '@hooks/useMapFieldKey'
import useRouteNavigate from '@hooks/useRouteNavigate'
import {
  useDFormKeyValue,
  useDFormProcessId,
  useDFormTypeJobId,
  useUpdateDFormKeyValue,
} from '@services/dynamic'
import { useQueryClient } from '@tanstack/react-query'
import { Form } from 'antd'
import { useParams, useSearchParams } from 'react-router-dom'
import { Button, Drawer, FormWrapper } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { ShortArrowLeft } from 'yolo-design/icons'
import { convertKgToMtr, getTotalPrintWidth } from '@utils/formula'

const Cylinder: FC<{
  data: IDFormKeyValue
  cylinderFormId: string | undefined
  isLoading: boolean
}> = ({ data, cylinderFormId = '' }) => {
  const [editItem, setEditItem] = useState<CylinderFormFieldsKey>()
  const routeNavigate = useRouteNavigate()
  const { customerId } = useParams()
  const [searchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const [form] = Form.useForm()

  const formTypeId = useDFormTypeJobId() || ''
  const processFormId = useDFormProcessId(formTypeId) || ''
  const mapFieldKey = useMapFieldKey({ formId: cylinderFormId })
  const mapProcessFieldKey = useMapFieldKey({ formId: processFormId })

  const tabId = searchParams.get('tab')
  const specId = searchParams.get('spec')
  const jobId = searchParams.get('job') || ''

  const { data: jobData } = useDFormKeyValue(
    {
      enabled: !!jobId,
      staleTime: 0,
    },
    { formId: jobId }
  )

  const tabQuery = tabId ? `&tab=${tabId}` : ''
  const specQuery = specId ? `&spec=${specId}` : ''
  const jobQuery = jobId ? `&job=${jobId}` : ''

  const updateForm = useUpdateDFormKeyValue()

  const handleClose = () => {
    setEditItem(undefined)
  }

  const handleSubmit = (val: Record<CylinderFormFieldsKey, any>) => {
    let postData = val

    if (Object.keys(val).includes('no_of_steps')) {
      postData = {
        ...val,
        total_printing_width: getTotalPrintWidth(
          val.no_of_steps,
          data?.job_width
        ),
      }
    }

    if (Object.keys(val).includes('job_width')) {
      postData = {
        ...val,
        total_printing_width: getTotalPrintWidth(
          data?.no_of_steps,
          val?.job_width
        ),
      }

      // Update Bobbin meter in process details
      if (
        !!jobData?.process_and_details?.id &&
        !!jobData?.bill_of_materials?.id
      ) {
        updateForm.mutate(
          {
            data: mapProcessFieldKey({
              bobbin_meter: convertKgToMtr({
                flimWidth: Number(val?.job_width),
                outputKg: jobData?.process_and_details.bobbin_weight,
                totalGSM: jobData?.bill_of_materials?.total_thickness_gsm,
              }),
            }),
            formId: jobData?.process_and_details?.id,
          },
          {
            onSuccess: (res) => {
              queryClient.setQueryData<IDFormKeyValue>(
                queries.dynamic.form_key_value(jobId).queryKey,
                (prev) => ({
                  ...prev,
                  process_and_details: {
                    ...res,
                    id: jobData?.process_and_details?.id,
                  },
                })
              )

              handleClose()
            },
          }
        )
      }
    }

    if (data?.id) {
      updateForm.mutate(
        { data: mapFieldKey(postData), formId: data?.id },
        {
          onSuccess: (res) => {
            queryClient.setQueryData<IDFormKeyValue>(
              queries.dynamic.form_key_value(jobId).queryKey,
              (prev) => ({
                ...prev,
                cylinder_details: { ...res, id: data?.id },
              })
            )

            handleClose()
          },
        }
      )
    }
  }

  useEffect(() => {
    if (!data?.id && cylinderFormId) {
      routeNavigate(
        `customer/${customerId}/job/${cylinderFormId}/cylinder/create?${jobQuery}${tabQuery}${specQuery}`,
        {
          addOrg: true,
        }
      )
    }
  }, [data, cylinderFormId])

  useEffect(() => {
    if (editItem) {
      if (
        [
          'cylinder_type',
          'photocell_mark_size',
          'photocell_mark_color',
          'photocell_mark_type',
          'travel_length_color',
        ].includes(editItem)
      ) {
        form.setFieldsValue({ [editItem]: String(data?.[editItem]?.code) })
        return
      }

      form.setFieldsValue(data)
    }
  }, [editItem])

  return (
    <>
      <div className="d-flex w-100 flex-column py-16 px-24">
        <span className="text-title-large fw-medium text-grey-700 mb-16">
          Specification
        </span>
        <EditFieldItem
          label="CYLINDER TYPE"
          value={data?.cylinder_type?.name}
          onEdit={() => setEditItem('cylinder_type')}
        />
        <EditFieldItem
          label="CYLINDER LENGTH"
          value={data?.cylinder_length}
          onEdit={() => setEditItem('cylinder_length')}
        />
        <EditFieldItem
          label="CYLINDER CIRCUMFERENCE"
          value={data?.cylinder_circumference}
          onEdit={() => setEditItem('cylinder_circumference')}
        />
        <EditFieldItem
          label="JOB WIDTH"
          value={data?.job_width}
          onEdit={() => setEditItem('job_width')}
        />
        <EditFieldItem
          label="JOB HEIGHT"
          value={data?.job_height}
          onEdit={() => setEditItem('job_height')}
        />
        <EditFieldItem
          label="NO OF STEPS"
          value={data?.no_of_steps}
          onEdit={() => setEditItem('no_of_steps')}
        />
        <EditFieldItem
          label="NO OF REPEATS"
          value={data?.no_of_repeat}
          onEdit={() => setEditItem('no_of_repeat')}
        />
        <EditFieldItem
          label="SHRINKAGE"
          value={data?.shrinkage}
          onEdit={() => setEditItem('shrinkage')}
        />
        <EditFieldItem
          hideBorder
          label="TOTAL PRINTING WIDTH"
          value={data?.total_printing_width}
        />
        <span className="text-title-large fw-medium text-grey-700 mb-16 mt-12">
          Photocell
        </span>
        <EditFieldItem
          label="PHOTOCELL MARK SIZE"
          value={data?.photocell_mark_size?.name}
          onEdit={() => setEditItem('photocell_mark_size')}
        />
        <EditFieldItem
          label="PHOTOCELL MARK COLOR"
          value={data?.photocell_mark_color?.name}
          onEdit={() => setEditItem('photocell_mark_color')}
        />
        <EditFieldItem
          label="PHOTOCELL MARK TYPE"
          value={data?.photocell_mark_type?.name}
          onEdit={() => setEditItem('photocell_mark_type')}
        />
        <EditFieldItem
          label="PHOTOCELL TRAVEL LENGTH"
          value={data?.photocell_travel_length}
          onEdit={() => setEditItem('photocell_travel_length')}
        />
        <EditFieldItem
          label="PHOTOCELL TRAVEL COLOR"
          value={data?.travel_length_color?.name}
          onEdit={() => setEditItem('travel_length_color')}
        />
      </div>

      <Drawer open={!!editItem} customHeader onClose={handleClose}>
        <div className="d-flex w-100 py-16 px-24 justify-content-between border-bottom border-grey-400 align-items-center">
          <Button
            type="ghost"
            size="large"
            icon={ShortArrowLeft}
            color={Color.background.dark}
            antdButtonProps={{
              onClick: handleClose,
              disabled: updateForm.isLoading,
            }}
          >
            Back
          </Button>
          <Button
            antdButtonProps={{
              onClick: () => form.submit(),
              loading: updateForm.isLoading,
            }}
            type="ghost"
            size="large"
          >
            Save
          </Button>
        </div>
        <div className="d-flex flex-column py-20 px-24 w-100">
          <FormWrapper antdFormProps={{ form, onFinish: handleSubmit }}>
            {editItem && <CylinderFormFields name={editItem} />}
          </FormWrapper>
        </div>
      </Drawer>
    </>
  )
}

export default Cylinder
