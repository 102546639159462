import React, { FC } from 'react'
import { DragVertical, Edit, KababMenu, Trash } from 'yolo-design/icons'
import cn from 'classnames'
import Image from '@components/Image'
import { Button, Popover } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { useSearchParams } from 'react-router-dom'
import Add from './Add'
import { RAW_MATERIAL_FORM_CODE } from '@constants/dynamic'
import { getGSM } from '@utils/formula'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { UseQueryResult } from '@tanstack/react-query'
import { PaginatedResponse } from '@contracts/models/shared'
import { IDFormKeyValue } from '@contracts/models/dynamic'

const BOMItem: FC<{
  data: any
  index: number
  handleDelete: (id: string) => void
  loading: boolean
  provided?: DraggableProvided
  snapshot?: DraggableStateSnapshot
  flimAdhesiveList: UseQueryResult<PaginatedResponse<IDFormKeyValue>>
  setSearch: React.Dispatch<React.SetStateAction<string>>
}> = ({
  data,
  handleDelete,
  loading,
  index,
  provided,
  snapshot,
  flimAdhesiveList,
  setSearch,
}) => {
  const isInkType = data?.data?.code === RAW_MATERIAL_FORM_CODE['ink']

  const [searchParams, setSearchParams] = useSearchParams()

  const editId = searchParams.get('edit_id')

  const filmAdhData = data?.data?.layer

  const flimDisplayFields = [
    {
      label: 'Micron',
      value: filmAdhData?.flim_micron || filmAdhData?.poly_micron,
    },
    {
      label: 'Size',
      value: filmAdhData?.flim_material_size || filmAdhData?.poly_material_size,
    },
    {
      label: 'GSM',
      value: getGSM(
        filmAdhData?.flim_micron || filmAdhData?.poly_micron,
        filmAdhData?.flim_density || filmAdhData?.poly_density
      ),
    },
  ]
  const adhDisplayFields = [
    {
      label: 'Material Type',
      value: filmAdhData?.adhesive_material_type?.label || '-',
    },
    {
      label: 'Grade',
      value:
        filmAdhData?.adhesive_adhesive_grade ||
        filmAdhData?.adhesive_hardener_grade ||
        '-',
    },
    { label: 'GSM', value: 2 },
  ]

  const displayFields =
    filmAdhData?.form_code === RAW_MATERIAL_FORM_CODE['flim'] ||
    filmAdhData?.form_code === RAW_MATERIAL_FORM_CODE['poly']
      ? flimDisplayFields
      : adhDisplayFields

  const menuContent = (
    <div className="d-flex flex-column w-max-content px-4">
      <Button
        color={Color.neutral_color.black}
        antdButtonProps={{
          className: 'mb-12',
          onClick: () => {
            searchParams.delete('add_bom')
            searchParams.set('edit_id', data?.id)
            setSearchParams(searchParams)
          },
        }}
        icon={Edit}
        type="ghost"
      >
        Edit
      </Button>
      <Button
        antdButtonProps={{ onClick: () => handleDelete(data?.id), loading }}
        color={Color.indicators.error}
        icon={Trash}
        type="ghost"
      >
        Delete
      </Button>
    </div>
  )

  const dispData = {
    image:
      filmAdhData?.flim_image ||
      filmAdhData?.adhesive_image ||
      filmAdhData?.group_image ||
      filmAdhData?.poly_image,
    name:
      filmAdhData?.name ||
      filmAdhData?.name ||
      filmAdhData?.group_name ||
      filmAdhData?.name,
    sku_code:
      filmAdhData?.flim_sku_code ||
      filmAdhData?.adhesive_sku_code ||
      filmAdhData?.group_sku_code ||
      filmAdhData?.poly_sku_code,
    location:
      filmAdhData?.flim_location ||
      filmAdhData?.adhesive_location ||
      filmAdhData?.group_location ||
      filmAdhData?.poly_location,
  }

  const flimAdhElem = () => (
    <>
      <div className="d-flex w-100">
        <div className="d-flex">
          <Image
            width={60}
            height={84}
            className="rounded-8 overflow-hidden object-fit-cover"
            src={dispData.image}
            alt={dispData.name}
          />
        </div>
        <div className="d-flex flex-column ms-12 h-100 justify-content-around">
          <span className="text-title-medium text-dark fw-semibold">
            {dispData.name}
          </span>
          <div className="d-flex w-100 text-label-medium fw-medium text-grey-700">
            <span className="pe-12">Code: {dispData.sku_code || '-'}</span>
            <span className="ps-12 border-start border-grey-400">
              Location: {dispData.location || '-'}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex w-100 mt-12 w-100 pt-12 border-top border-grey-200">
        {displayFields.map((el, i) => (
          <div
            key={i}
            style={{ width: `${100 / displayFields.length}%` }}
            className={cn('d-flex flex-column', {
              'border-start ps-12 border-grey-200': !(i === 0),
              'pe-12': i === 0,
            })}
          >
            <span className="text-label-small fw-medium text-grey-700">
              {el.label}
            </span>
            <span className="text-body-medium fw-medium text-dark mt-8">
              {el.value}
            </span>
          </div>
        ))}
      </div>
    </>
  )

  const inkElem = () => (
    <div className="d-flex w-100">
      <div className="d-flex">
        <Image
          width={60}
          height={84}
          className="rounded-8 overflow-hidden object-fit-cover"
          src={data?.data?.image}
          alt={data?.data?.name}
        />
      </div>
      <div className="d-flex flex-column ms-12 h-100 justify-content-around">
        <span className="text-title-medium text-dark fw-semibold">
          {data?.data?.name}
        </span>
        <span className="text-label-medium text-grey-700  mt-8">
          GSM : {data?.data?.gsm}
        </span>
      </div>
    </div>
  )

  return (
    <div
      className={cn(
        'd-flex flex-column bg-white position-relative w-100 rounded-16 p-16 w-100 border border-grey-200 mb-16',
        {
          'border-just-blue': snapshot?.isDragging,
        }
      )}
    >
      {!editId && !isInkType && (
        <div className="position-absolute top-0 end-0 mt-16 me-16 ">
          <Popover
            placement="bottomRight"
            title={null}
            trigger={'click'}
            content={menuContent}
            arrow={false}
            align={{ offset: [0, -10] }}
          >
            <KababMenu className="cursor-pointer" size="medium" weight="slim" />
          </Popover>
        </div>
      )}
      {editId === String(data?.id) ? (
        <Add
          flimAdhesiveList={flimAdhesiveList}
          setSearch={setSearch}
          loading={loading}
        />
      ) : (
        <div className="d-flex flex-column w-100">
          <div className="d-flex">
            {!isInkType && (
              <div {...(provided ? provided.dragHandleProps : {})}>
                <DragVertical color={Color.background.dark} className="me-4" />
              </div>
            )}
            <span className="text-title-small fw-medium text-grey-700 mb-16">
              Layer {index + 1}
            </span>
          </div>
          {isInkType ? inkElem() : flimAdhElem()}
        </div>
      )}
    </div>
  )
}

export default BOMItem
