import React, { FC } from 'react'
import { Col } from 'antd'

const DispCard: FC<{ label: string; value: string; colSpan?: number }> = ({
  label,
  value,
  colSpan,
}) => {
  return (
    <Col span={colSpan || 5} className="w-100 d-flex flex-column">
      <span className="text-label-extra-small text-grey-600">{label}</span>
      <span className="text-body-large fw-medium mt-8 text-dark">
        {value || '-'}
      </span>
    </Col>
  )
}

export default DispCard
