import Head from './Head'
import { Table } from 'yolo-design/components'
import { ColumnsType } from 'antd/es/table'
import TableNameCard from '@components/TableNameCard'
import './dispatch.module.css'
import { useParams, useSearchParams } from 'react-router-dom'
import useRouteNavigate from '@hooks/useRouteNavigate'
import { queries } from '@constants/queryKeys'
import { useQueryClient } from '@tanstack/react-query'
import { useDispatchList } from '@services/dispatch'
import dayjs from 'dayjs'
import useDebounce from '@constants/index'

const Dispatch = () => {
  const queryClient = useQueryClient()
  const routeNavigate = useRouteNavigate()
  const [searchParams] = useSearchParams()
  const { orgId } = useParams()
  const search = searchParams.get('search') || ''
  const dispatch_status = searchParams.get('dispatch_status') || '1'
  const searchDebounced = useDebounce(search, 500)

  const { list, lastItemRef, isLoading } = useDispatchList(
    { enabled: !!dispatch_status, staleTime: 0 },
    { orgId, search: searchDebounced, dispatch_status }
  )

  const alteredList = list?.map((el) => ({
    ...el,
    job: el.is_completed ? el.job_data?.job : el.job,
  }))

  const handleView = (column: any) => {
    queryClient.setQueryData(
      queries.dispatch.dispatch_by_id(column?.code).queryKey,
      column
    )

    routeNavigate(
      `dispatch-form?view=view&dispatchId=${column?.id}&job=${column?.job_id}`,
      {
        addOrg: true,
      }
    )
  }

  const columns: ColumnsType<any> = [
    {
      title: <span className="">SL NO</span>,
      dataIndex: 'job',
      render: (value, col, i) => (
        <div ref={(list?.length || 0) - 1 === i ? lastItemRef : null}>
          {`${i + 1}`}
        </div>
      ),
      width: '6%',
      align: 'left',
    },
    {
      title: <span className="">DISPATCH NO</span>,
      dataIndex: 'dispatch_no',
      render: (val) => val || '-',
      align: 'left',
      width: '10%',
    },
    {
      title: <span className="">DATE</span>,
      dataIndex: 'dispatch_date',
      render: (val) => (val ? dayjs(val).format('DD-MMM-YYYY') : '-'),
      align: 'left',
      width: '10%',
    },
    {
      title: <span className="">JOB NAME</span>,
      dataIndex: 'job_name',
      render: (val, rec, i) => {
        return (
          <>
            <TableNameCard
              // handleView={() => handleView(rec)}
              key={i}
              subTitle1={`Job code : ${rec?.job_code ? rec?.job_code : '-'}`}
              subTitle2={`Work Order No : ${
                rec?.work_order_no ? rec?.work_order_no : '-'
              }`}
              image={rec?.job_image}
              title={val}
            />
          </>
        )
      },
    },
    {
      title: <span className="">WORK ORDER NO</span>,
      dataIndex: 'work_order_no',
      render: (val) => val || 0,
      align: 'left',
      width: '12%',
    },
    {
      title: <span className="">TYPE</span>,
      dataIndex: 'dispatch_type',
      render: (val) => (val ? val : '-'),
      align: 'left',
      width: '10%',
    },

    {
      title: <span className="">STATUS</span>,
      dataIndex: 'dispatch_status',
      render: (text, rec) => {
        return (
          <>
            <div
              className="spacer"
              style={{
                width: 'max-content',
                backgroundColor: rec?.color_code,
                borderRadius: '4px',
                padding: '2px 4px',
              }}
            >
              {text}
            </div>
          </>
        )
      },
      align: 'left',
      width: '10%',
    },
    {
      title: <span className="">OUTPUT</span>,
      dataIndex: 'output_quantity',
      render: (val, rec) => (val ? `${val}(${rec?.unit_name})` : '-'),
      align: 'right',
      width: '10%',
    },
  ]

  return (
    <div className="d-flex flex-column w-100">
      <Head />
      <div className="d-flex w-100">
        <Table
          className="custom-table"
          loading={isLoading}
          style={{ padding: '0px 24px', height: '100%', width: '100%' }}
          rowKey={'id'}
          columns={columns}
          dataSource={alteredList || []}
          scroll={{ y: 'calc(100vh - 240px)' }}
          onRow={(data) => ({
            onClick: () => {
              handleView(data)
            },
            style: { cursor: 'pointer' },
          })}
        />
      </div>
    </div>
  )
}

export default Dispatch
