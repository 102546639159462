import { Divider } from 'antd'
import Barcode from 'react-barcode'

const DispatchBarcode = ({
  refDetail,
  dispatchDetail,
  printLabelData,
}: {
  refDetail: any
  dispatchDetail: any
  printLabelData: any
}) => {
  return (
    // <div  ref={refDetail} className={'d-flex flex-column  px-8 pb-0'}>
    //     <span className="text-center text-body-small fw-semibold">
    //       {dispatchDetail?.organization_name}
    //     </span>
    //     <Divider className="my-2 border-black" dashed />
    //       <span className="text-body-small text-truncate ">{`Job Name :${dispatchDetail?.job_name}`}</span>
    //       <span className="text-body-small text-truncate ">{`Job Code :${dispatchDetail?.job_code}`}</span>
    //       <span className="text-body-small text-truncate">{`Customer :${dispatchDetail?.customer_name}`}</span>
    //     <Divider className="my-2 border-black" dashed />

    //     <Barcode
    //       displayValue={true}
    //       value={dispatchDetail?.dispatch_no}
    //       width={2}
    //       height={40}
    //       fontSize={6}
    //     />
    //     <Divider className="my-2 border-black" dashed />

    //   <div className="d-flex justify-content-between gap-2">
    //     <div className="w-50">
    //       <div className="d-flex justify-content-between">
    //         <span className="text-body-small text-truncate">{`Gross Wt:`}</span>
    //         <span className="text-body-small text-truncate">
    //           {printLabelData?.gross_weight}
    //         </span>
    //       </div>
    //       <div>
    //         <div className="d-flex justify-content-between">
    //           <span className="text-body-small text-truncate">{`Tare Wt:`}</span>
    //           <span className="text-body-small text-truncate">
    //             {printLabelData?.tare_weight}
    //           </span>
    //         </div>
    //       </div>

    //       <div>
    //         <div className="d-flex justify-content-between">
    //           <span className="text-body-small text-truncate">{`Net Wt:`}</span>
    //           <span className="text-body-small text-truncate">
    //             {printLabelData?.net_weight}
    //           </span>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="w-50">
    //       <div>
    //         <div className="d-flex justify-content-between">
    //           <span className="text-body-small text-truncate">{`Dispatch No:`}</span>
    //           <span className="text-body-small text-truncate">
    //             {dispatchDetail?.dispatch_no}
    //           </span>
    //         </div>
    //         <div className="d-flex justify-content-between">
    //           <span className="text-body-small text-truncate">{`Sl No:`}</span>
    //           <span className="text-body-small text-truncate">
    //             {printLabelData?.serial_no}
    //           </span>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div
      ref={refDetail}
      className="d-flex flex-column text-body-small fw-semibold px-8 pb-0"
    >
      <div className="d-flex justify-content-center">
        <span className="text-center">{dispatchDetail?.organization_name}</span>
      </div>
      <Divider className="my-0 border-black" dashed />
      <div className="d-flex w-100 text-label-extra-small justify-content-between">
        <span className="text-label-extra-small text-truncate">
          Job Name: <b>{dispatchDetail?.job_name}</b>
        </span>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <span className="text-label-extra-small w-100 text-truncate">
          <b>Job Code:{dispatchDetail?.job_code}</b>
        </span>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <span className="text-label-extra-small w-100 text-truncate">
          <b>Customer:{dispatchDetail?.customer_name}</b>
        </span>
      </div>
      <Divider className="my-0 border-black" dashed />
      <div className="d-flex w-100 justify-content-center">
        {printLabelData?.barcode && (
          <Barcode
            displayValue={true}
            width={1}
            height={30}
            value={printLabelData?.barcode}
            fontSize={5}
            background="transparent"
          />
        )}
      </div>

      <Divider className="my-0 border-black" dashed />

      <div className="d-flex justify-content-between">
        <div>
          <div className="d-flex justify-content-between">
            <span className="mt-2 w-100 text-label-extra-small text-truncate fw-semibold">
              Gross Wt:{' '}
              <b style={{ lineHeight: 1 }} className="text-label-extra-small">
                {printLabelData?.gross_weight}
              </b>{' '}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="mt-2 w-100 text-label-extra-small text-truncate fw-semibold">
              Tare Wt:{' '}
              <b style={{ lineHeight: 1 }} className="text-label-extra-small">
                {printLabelData?.tare_weight}
              </b>{' '}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="mt-2 w-100 text-label-extra-small text-truncate fw-semibold">
              Net Wt:{' '}
              <b style={{ lineHeight: 1 }} className="text-label-extra-small">
                {printLabelData?.net_weight}
              </b>{' '}
            </span>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-between">
            <span className="mt-2 w-100 text-label-extra-small text-truncate fw-semibold">
              Dipatch:{' '}
              <b style={{ lineHeight: 1 }} className="text-label-extra-small">
                {dispatchDetail?.dispatch_no}
              </b>{' '}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="mt-2 w-100 text-label-extra-small text-truncate fw-semibold">
              Serial No:{' '}
              <b style={{ lineHeight: 1 }} className="text-label-extra-small">
                {printLabelData?.serial_no}
              </b>{' '}
            </span>
          </div>
        </div>

        {/* <div className="d-flex">
      {itemDetails?.micron ? (
        <>
          <span className="text-label-extra-small fw-semibold">
            Micron:
            <b style={{ lineHeight: 1 }} className="text-label-medium ">
              {itemDetails?.micron || '-'}
            </b>
            &nbsp;&nbsp;
          </span>
        </>
      ) : (
        <></>
      )}
      {itemDetails?.material_size ? (
        <>
          <span className="text-label-extra-small fw-semibold">
            Size:
            <b style={{ lineHeight: 1 }} className="text-label-medium ">
              {itemDetails?.material_size || '-'}
            </b>
            &nbsp;&nbsp;
          </span>
        </>
      ) : (
        <></>
      )}
    </div>
    <div className="d-flex ">
      {itemDetails?.tar_quantity > 0 ? (
        <>
          <span className="text-label-extra-small fw-semibold">
            Tar:
            <b style={{ lineHeight: 1 }} className="text-label-medium ">
              {`${
                itemDetails?.tar_quantity > 0 ? itemDetails?.tar_quantity : 0
              } ${itemDetails?.unit}` || '-'}
            </b>
            &nbsp;&nbsp;
          </span>
        </>
      ) : (
        <></>
      )}
      {itemDetails?.net_quantity ? (
        <>
          <span className="text-label-extra-small fw-semibold">
            Total:
            <b style={{ lineHeight: 1 }} className="text-label-medium">{`${
              itemDetails?.net_quantity || '-'
            }${itemDetails?.unit}`}</b>
            &nbsp;&nbsp;
          </span>
        </>
      ) : (
        <></>
      )}
    </div> */}
      </div>
    </div>
  )
}

export default DispatchBarcode
