import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'yolo-design/components'
import { IdCard } from 'yolo-design/icons'

const Settings = () => {
  const [selectMenu, setSelectMenu] = useState('1')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const TAB_NAME: any = {
    1: 'form-select',
  }
  const items: any = [
    {
      label: <NavLink to={'form-select'}>Form select fields</NavLink>,
      key: '1',
      icon: IdCard,
      link: 'form-select',
    },
  ]

  useEffect(() => {
    setSelectMenu(
      items?.find((f: any) => f.link === pathname?.split('/')[3])?.key || '1'
    )
  }, [pathname])

  return (
    <div className="d-flex w-100">
      <div
        style={{ flex: `0 0 340px`, maxWidth: '340px' }}
        className="border-end  border-grey-200"
      >
        <div className="d-flex">
          <Menu
            onClick={(el: any) => navigate(TAB_NAME[el])}
            title={'SETTINGS'}
            selectedKey={selectMenu}
            items={items}
          />
        </div>
      </div>
      <div
        style={{ flex: 1 }}
        className="d-flex justify-content-center overflow-scroll"
      >
        <Outlet />
      </div>
    </div>
  )
}

export default Settings
