import { NavigateOptions, To, useNavigate, useParams } from 'react-router-dom'

const useRouteNavigate = () => {
  const navigate = useNavigate()
  const { orgId } = useParams()

  const routeNavigate = (
    to: To,
    options?: NavigateOptions & { addOrg?: boolean }
  ) => {
    const modifiedTo: To = options?.addOrg ? `/${orgId}/${to}` : to

    navigate(modifiedTo, options)
  }

  return routeNavigate
}

export default useRouteNavigate
