import React, { FC } from 'react'
import { imagePlaceholder } from 'yolo-design/assets'
import { Button } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { DoubleChevronLeft } from 'yolo-design/icons'

const TableNameCard: FC<{
  image: string
  title: string
  subTitle1: string
  subTitle2: string

  handleView?: () => void
}> = ({ image, title, subTitle1, subTitle2, handleView }) => {
  return (
    <div
      // ref={list?.length - 1 === i ? lastItemRef : null}
      className="table_content d-flex w-100 justify-content-between pb-4 align-items-center"
    >
      <div className="d-flex">
        <div
          style={{ height: '40px', width: '30px' }}
          className="px-2 d-flex border border-grey-200 rounded-4 bg-white justify-content-center align-items-center"
        >
          <img
            width={20}
            className="h-max-content object-fit-contain"
            src={image || imagePlaceholder}
            alt={title}
          />
        </div>
        <div className="d-flex flex-column ms-12 ">
          <div className="d-flex align-items-center">
            <span className="text-title-small fw-medium text-dark">
              {title}
            </span>
          </div>
          <div className="d-flex gap-2">
            <span className="text-label-small fw-medium text-grey-700 mt-4">
              {subTitle1}
            </span>
            <span className="text-label-small fw-medium text-grey-700 mt-4">
              {subTitle2 || ''}
            </span>
          </div>
        </div>
      </div>
      {handleView && (
        <Button
          size="small"
          type="ghost"
          antdButtonProps={{
            className: 'table_content_view',
            onClick: () => handleView(),
          }}
          icon={DoubleChevronLeft}
          iconPosition="right"
          color={Color.blue.just_blue}
        >
          View Details
        </Button>
      )}
    </div>
  )
}

export default TableNameCard
