import Create from '@components/PurchaseIntent/Create'
import { usePurchaseIndentList } from '@services/purchaseIndent'
import { isLastItem } from '@utils/array'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Drawer, SearchInput, Tab, Table } from 'yolo-design/components'
import { Add } from 'yolo-design/icons'
import cn from 'classnames'
import Image from '@components/Image'
import View from '@components/PurchaseIntent/View'

import {
  individualAvatarOne,
  individualAvatarTwo,
  individualAvatarThree,
  individualAvatarFour,
  individualAvatarFive,
} from 'yolo-design'
import { randomIntFromInterval } from '@utils/string'

const logos: Record<number, string> = {
  1: individualAvatarOne,
  2: individualAvatarTwo,
  3: individualAvatarThree,
  4: individualAvatarFour,
  5: individualAvatarFive,
}

const tabItems = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: '2',
    label: 'Pending',
  },
  {
    key: '3',
    label: 'Completed',
  },
]

const PurchaseIntent = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)

  const search = searchParams.get('search') || ''
  const workStatus = searchParams.get('work_status') || tabItems[0].key
  const viewId = searchParams.get('view_id') || ''

  const {
    list = [],
    lastItemRef,
    refetch,
  } = usePurchaseIndentList(
    {},
    { search, status: workStatus === 'all' ? undefined : workStatus }
  )

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = e.target.value
    if (val) {
      searchParams.set('search', val)
    } else {
      searchParams.delete('search')
    }
    setSearchParams(searchParams)
  }

  const handleCloseCreateDrawer = () => {
    setOpenCreate(false)

    searchParams.delete('edit_id')
    setSearchParams(searchParams)
  }

  const handleTabChange = (key: string) => {
    searchParams.set('work_status', key)
    setSearchParams(searchParams)
  }

  const columns = [
    {
      title: 'Indent Date',
      dataIndex: 'indent_date',
      render: (val: string) => new Date(val).toLocaleDateString(),
      width: '130px',
    },
    {
      title: 'Indent No.',
      dataIndex: 'indent_number',
      render: (val: any, column: any, i: number) => (
        <div ref={isLastItem(list, i) ? lastItemRef : null}>{val}</div>
      ),
      width: '130px',
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      render: (val: string) => (
        <div className="d-flex align-items-center">
          {' '}
          <Image
            src={logos[randomIntFromInterval(1, 5)]}
            alt={val}
            width={32}
            height={32}
          />{' '}
          <span className="ms-4 text-body-medium fw-medium">{val}</span>{' '}
        </div>
      ),
    },
    {
      title: `No of Items`,
      dataIndex: 'items',
      render: (val: any) => {
        return val
      },
      width: '130px',
    },
    {
      title: 'Status',
      dataIndex: 'indent_status_code',
      render: (val: number, col: any) => (
        <span
          className={cn(
            'px-8 py-2 rounded-4 w-max-content h-max-content text-body-small fw-semibold text-uppercase',
            {
              'bg-washed-orange': val === 2,
              'bg-washed-green': val === 3,
              'bg-washed-red': val === 4,
              'bg-washed-neon': val === 5,
            }
          )}
        >
          {col?.indent_status_name}
        </span>
      ),
      width: '130px',
    },
  ]

  return (
    <>
      <div className="d-flex w-100 flex-column p-24">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <Tab
            className="w-100"
            activeKey={workStatus}
            items={tabItems}
            onChange={handleTabChange}
            tabBarStyle={{ margin: 0 }}
          />
          <div className="d-flex">
            <SearchInput
              onFocus={() => setIsSearchFocused(true)}
              onBlur={() => setIsSearchFocused(false)}
              style={{
                marginRight: '16px',
                width: isSearchFocused ? '360px' : '200px',
              }}
              onChange={handleSearch}
              value={search}
              placeholder="Search"
              className="me-16"
            />
            <Button
              antdButtonProps={{
                onClick: () => {
                  setOpenCreate(true)
                },
              }}
              icon={Add}
            >
              New indent
            </Button>
          </div>
        </div>
        <Table
          className="mt-20"
          columns={columns}
          dataSource={list}
          rowKey={'id'}
          onRow={(d) => ({
            onClick: () => {
              searchParams.set('view_id', d.id)
              setSearchParams(searchParams)
            },
          })}
        />
      </div>

      <Drawer
        destroyOnClose
        width={768}
        open={openCreate || !!searchParams.get('edit_id')}
        onClose={handleCloseCreateDrawer}
        customHeader
      >
        <Create
          handleCloseCreateDrawer={handleCloseCreateDrawer}
          refetch={refetch}
        />
      </Drawer>

      <Drawer
        destroyOnClose
        width={800}
        open={!!viewId}
        onClose={() => {
          searchParams.delete('view_id')
          setSearchParams(searchParams)
        }}
        customHeader
      >
        <View refetch={refetch} />
      </Drawer>
    </>
  )
}

export default PurchaseIntent
