import React, { FC, useEffect } from 'react'
import nProgress from 'nprogress'

const LazyLoadProgress: FC = () => {
  useEffect(() => {
    nProgress.start()

    return () => {
      nProgress.done()
    }
  }, [])
  return <></>
}

export default LazyLoadProgress
