import Image from '@components/Image'
import { JobFormFieldsKey } from '@contracts/job'

import { useItemProductList } from '@services/product'
import { FC, useState } from 'react'
import { FormInput, FormSelect, Tooltip } from 'yolo-design/components'
import { Question } from 'yolo-design/icons'

const JobFormFields: FC<{
  name: JobFormFieldsKey
  props?: any
}> = ({ name, props }) => {
  const [searchProduct, setSearchProduct] = useState('')
  const { list, isLoading } = useItemProductList({}, { search: searchProduct })

  return (
    <>
      {name === 'name' && (
        <FormInput
          {...props}
          required
          inputType="text"
          name={'name'}
          title="Job Name"
        />
      )}
      {name === 'job_code' && (
        <FormInput
          {...props}
          inputType="text"
          required
          name={'job_code'}
          title={
            <div className="d-flex align-items-center">
              <span className="text-label-small fw-medium text-dark">
                Job Code
              </span>
              <Tooltip
                className="ms-4"
                placement="right"
                title="Unique Job Code"
              >
                <Question size="small" weight="slim" />
              </Tooltip>
            </div>
          }
        />
      )}
      {name === 'barcode' && (
        <FormInput
          {...props}
          required
          inputType="text"
          name={'barcode'}
          title="Job Barcode"
        />
      )}
      {name === 'linked_product' && (
        <FormSelect
          optionsSelect={list?.map((el: any) => ({
            value: String(el.id),
            label: (
              <div className="d-flex align-items-center">
                <div className="d-flex w-max-content h-max-content rounded-4 overflow-hidden me-8">
                  <Image src={el.image} width={48} height={48} />
                </div>
                <div className="d-flex flex-column">
                  <span>{el.name}</span>
                  <span className="text-label-extra-small">
                    SKU:{el.sku_code}
                  </span>
                </div>
              </div>
            ),
            name: el?.name,
          }))}
          antdSelectProps={{
            onSearch: (e) => {
              setSearchProduct(e)
            },
            loading: isLoading,
            onClear: () => {
              setSearchProduct('')
            },
            onSelect: () => {
              setSearchProduct('')
            },
            filterOption: false,
            allowClear: true,
            placeholder: 'Select a material',
            showSearch: true,
            optionLabelProp: 'name',
          }}
          name={'linked_product'}
        />
      )}
      {name === 'shade_location_name' && (
        <FormInput
          {...props}
          required
          inputType="text"
          name={'shade_location_name'}
          title="Shade Card Location"
        />
      )}
      {name === 'sample_location' && (
        <FormInput
          {...props}
          required
          inputType="text"
          name={'sample_location'}
          title="Sample Location"
        />
      )}
    </>
  )
}

export default JobFormFields
