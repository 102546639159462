import TableNameCard from '@components/TableNameCard'
import { getReqQty } from '@utils/formula'
import { ColumnsType } from 'antd/es/table'
import React, { FC } from 'react'
import { Table } from 'yolo-design/components'

const ColorTable: FC<{
  outputKg: number
  data: Record<string, any>
  wastage?: number
}> = ({ outputKg, data, wastage = 0 }) => {
  const colorData = data?.bill_of_materials?.bom
    ?.filter((f: any) => f?.data?.code === 1)
    ?.map((el: any) => ({ id: el?.id, ...(el?.data || {}) }))

  const totalGSM = data?.bill_of_materials?.total_thickness_gsm

  const totalWaste = wastage

  const columns: ColumnsType<any> = [
    {
      title: 'NAME',
      dataIndex: 'name',
      render: (val, column) => (
        <TableNameCard image={column?.image} title={val} subTitle="" />
      ),
    },
    {
      title: 'LOCATION',
      dataIndex: 'location',
      render: () => '-',
    },
    {
      title: 'COLOR CODE',
      dataIndex: 'code',
      render: () => '-',
    },
    {
      title: 'INK TYPE',
      dataIndex: 'type',
      render: () => '-',
    },
    {
      title: 'INK GRADE',
      dataIndex: 'grade',
      render: () => '-',
    },
    {
      title: 'GSM',
      dataIndex: 'gsm',
      render: () => 1,
    },
    {
      title: 'QTY REQUIRED',
      dataIndex: 'qty',
      onCell: () => {
        return {
          className: 'bg-grey-200',
        }
      },
      render: () =>
        `${getReqQty({ gsm: 1, totalGSM, outputKg, totalWaste })} Kg`,
    },
  ]

  return (
    <div className="d-flex flex-column w-100 mt-32">
      <span className="text-title-medium fw-bold">Color</span>
      <Table
        className="mt-12"
        rowKey={'id'}
        columns={columns}
        dataSource={colorData}
      />
    </div>
  )
}

export default ColorTable
