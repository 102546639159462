import { IClientInfo, IClientUser } from '@contracts/models/customer'
import { PaginatedResponse } from '@contracts/models/shared'
import { GET } from '@lib/AxiosClient'

export const getCustomerList = (
  signal: AbortSignal | undefined,
  params: {
    search?: string
    page: number
    page_size: number
  }
): Promise<PaginatedResponse<IClientUser>> =>
  GET({ url: 'client/client-list/', params, signal })

export const getCustomerInfo = (params: { id: string }): Promise<IClientInfo> =>
  GET({ url: `client/client-info/${params.id}/` })
