import React, { FC } from 'react'
import { Col, Row } from 'antd'
import { Button, FormInput, FormItem } from 'yolo-design/components'
import FormFields from './FormFields'

const EditItem: FC<{
  name: 'ink' | 'cylinder' | 'description'
  handleCancel: () => void
  colorsList?: Array<any>
}> = ({ name, handleCancel, colorsList }) => {
  return (
    <div className="d-flex flex-column w-100">
      <span className="text-title-small fw-medium text-grey-700 mb-16">
        {name === 'ink' ? 'Color' : 'Cylinder'}
      </span>
      <Row gutter={[16, 0]} className="w-100">
        <Col span={24}>
          <FormItem noStyle shouldUpdate>
            {(form) => (
              <>
                {name === 'ink' && (
                  <>
                    <FormFields
                      name="ink"
                      inkInclude={form.getFieldValue('ink')}
                    />
                    <FormFields name="description" />
                  </>
                )}
                {name === 'cylinder' && (
                  <FormFields
                    cylFilterIds={colorsList?.map(
                      (el) => el?.data?.cylinder?.id
                    )}
                    clyInclude={form.getFieldValue('cylinder')}
                    name="cylinder"
                  />
                )}
              </>
            )}
          </FormItem>

          <FormInput andtFormItemProps={{ hidden: true }} name={'order'} />
        </Col>
        <Col span={12}>
          <Button
            block
            type="outlined"
            antdButtonProps={{ htmlType: 'button', onClick: handleCancel }}
          >
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button block type="solid" antdButtonProps={{ htmlType: 'submit' }}>
            Save
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default EditItem
