import { useQuery } from '@tanstack/react-query'
import * as API from './api'
import { queries } from '@constants/queryKeys'
import { UseInfiniteQueryDataType, UseQueryDataType } from '@contracts/query'
import { IClientInfo, IClientUser } from '@contracts/models/customer'
import useInfiniteQueryRef from '@hooks/useInfiniteQueryRef'

export const useCustomerList: UseInfiniteQueryDataType<
  IClientUser,
  { search?: string; page_size?: number; orgId: string }
> = (options, { search, page_size = 10, orgId }) =>
  useInfiniteQueryRef<IClientUser>({
    queryKey: queries.customer.list(search, page_size, orgId).queryKey,
    queryFn: ({ signal, pageParam }) => {
      return API.getCustomerList(signal, { search, page: pageParam, page_size })
    },
    ...options,
  })

export const useCustomerInfo: UseQueryDataType<IClientInfo, { id: string }> = (
  options,
  { id }
) =>
  useQuery({
    queryKey: queries.customer.info(id).queryKey,
    queryFn: () => API.getCustomerInfo({ id }),
    ...options,
  })
