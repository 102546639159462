import { queries } from '@constants/queryKeys'
import {
  UseInfiniteQueryDataType,
  UseMutationType,
  UseQueryDataType,
} from '@contracts/query'
import useInfiniteQueryRef from '@hooks/useInfiniteQueryRef'
import * as API from './api'
import { useMutation, useQuery } from '@tanstack/react-query'
import { IPurIndentReq, IPurIndentRes } from '@contracts/models/purchase'

export const usePurchaseIndentList: UseInfiniteQueryDataType<
  any,
  {
    page_size?: number
    search?: string
    status?: string
  }
> = (options, { page_size = 10, search, status }) => {
  return useInfiniteQueryRef<any>({
    queryKey: queries.purchase.list({
      search,
      page_size,
      status,
    }).queryKey,
    queryFn: ({ signal, pageParam }) =>
      API.getPurchaseIndent(signal, {
        page: pageParam,
        page_size,
        search,
        status,
      }),
    ...options,
  })
}

export const useCreatePurchaseIndent: UseMutationType<any, IPurIndentReq> = (
  options
) =>
  useMutation({
    mutationFn: API.postPurchaseIndent,
    ...options,
  })

export const useEditPurchaseIndent: UseMutationType<
  any,
  { id: string; data: IPurIndentReq }
> = (options) =>
  useMutation({
    mutationFn: API.putPurchaseIndent,
    ...options,
  })

export const useDeletePurchaseIndent: UseMutationType<any, { id: string }> = (
  options
) =>
  useMutation({
    mutationFn: API.deletePurchaseIndent,
    ...options,
  })

export const usePurchaseIndentInfo: UseQueryDataType<
  IPurIndentRes,
  string | number
> = (options, id) =>
  useQuery({
    queryKey: queries.purchase.info(id).queryKey,
    queryFn: () => API.getPurchaseIndentInfo(id),
    enabled: !!id,
    ...options,
  })
