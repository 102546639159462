import React, { FC, RefObject } from 'react'
import styles from '../style.module.scss'
import cn from 'classnames'
import { useOrgInfo } from '@services/org'
import { useSearchParams } from 'react-router-dom'
import { useDFormKeyValue, useDWorkOrder } from '@services/dynamic'
import { convertKgToMtr, getGSM, getReqQty } from '@utils/formula'
import Image from '@components/Image'

const LaminationWorkOrder: FC<{ elRef: RefObject<any> }> = ({ elRef }) => {
  const [searchParams] = useSearchParams()

  const jobId = searchParams.get('job') || ''
  const workId = searchParams.get('work') || ''

  const { data: org } = useOrgInfo()

  const { data: work, isLoading: workLoading } = useDWorkOrder(
    {
      enabled: !!workId,
    },
    { formId: workId }
  )

  const { data: job } = useDFormKeyValue(
    {
      enabled: !!jobId,
    },
    { formId: jobId }
  )

  const data =
    !!workId && workLoading ? {} : work?.is_completed ? work?.job_data : job

  const bomData = data?.bill_of_materials
  const cylinderData = data?.cylinder_details

  const totalWaste = work?.wastage

  const adhesives = [...(bomData?.bom || [])]
    ?.filter((f: any) => f?.data?.layer?.form_type?.code === 3)
    ?.sort((a, b) => (a?.data?.order || 0) - (b?.data?.order || 0))
    ?.splice(0, 2)

  const flims = [...(bomData?.bom || [])]
    ?.filter((f: any) => f?.data?.code === 2 || f?.data?.code === 8)
    ?.sort((a, b) => (a?.data?.order || 0) - (b?.data?.order || 0))

  const secondFlim = flims[1]
  const thirdFlim = flims[2]

  const firstAdhesive = adhesives[0]
  const secondAdhesive = adhesives[1]

  const firstLayer = [...(bomData?.bom || [])]
    ?.filter((f) => f?.data?.code !== 1)
    ?.sort((a, b) => (a?.data?.order || 0) - (b?.data?.order || 0))
    ?.splice(0, 2)?.[0]

  const firstLayerTarget = getReqQty({
    gsm: getGSM(
      firstLayer?.data?.layer?.flim_micron ||
        firstLayer?.data?.layer?.poly_micron,
      firstLayer?.data?.layer?.flim_density ||
        firstLayer?.data?.layer?.poly_density
    ),
    totalGSM: bomData?.total_thickness_gsm,
    outputKg: work?.output_quantity,
    totalWaste,
  })

  const firstLayerTargetMtr = convertKgToMtr({
    flimWidth: cylinderData?.total_printing_width,
    outputKg: Number(firstLayerTarget),
    totalGSM: getGSM(
      firstLayer?.data?.layer?.flim_micron ||
        firstLayer?.data?.layer?.poly_micron,
      firstLayer?.data?.layer?.flim_density ||
        firstLayer?.data?.layer?.poly_density
    ),
  })

  return (
    <table
      ref={elRef}
      className={cn(
        styles.jis_table,
        'bg-white w-100 border fw-medium text-label-small'
      )}
    >
      <colgroup>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
      </colgroup>
      <tbody>
        <tr>
          <td colSpan={3} rowSpan={2}>
            {!!org?.logo && (
              <Image src={org?.logo} width={48} height={48} alt="Logo" />
            )}
          </td>
          <td
            colSpan={18}
            className="text-heading-small text-center fw-semibold"
          >
            {org?.name}
          </td>
        </tr>
        <tr>
          <td colSpan={12} className="text-title-medium fw-medium text-center">
            LAMINATION WORK ORDER
          </td>
          <td colSpan={8} className="text-start text-label-small">
            No: {work?.work_order_number}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Job No
          </td>
          <td colSpan={3} className="text-start">
            {job?.job?.job_code}
          </td>
          <td colSpan={4} className="text-start">
            Coil Size
          </td>
          <td colSpan={3} className="text-start">
            {cylinderData?.job_width}mm
          </td>
          <td colSpan={4} className="text-start">
            F.Width
          </td>
          <td colSpan={3} className="text-start">
            {cylinderData?.total_printing_width}mm
          </td>
          {/* <td colSpan={2} className="text-start">
            No
          </td>
          <td colSpan={3} className="text-start"></td> */}
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Name
          </td>
          <td colSpan={12} className="text-start">
            {job?.job?.name}
          </td>
          <td colSpan={2} className="text-start">
            Date
          </td>
          <td colSpan={3} className="text-start">
            {work?.started_date
              ? new Date(work?.started_date)?.toLocaleDateString('en-IN')
              : '-'}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            M/c.
          </td>
          <td colSpan={4} className="text-start">
            Expert
          </td>
          <td colSpan={5} className="text-start">
            Lamination Target
          </td>
          <td colSpan={3} className="text-start">
            {work?.output_quantity || 0}kg
          </td>
          <td colSpan={5} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start fw-bold bg-grey-200">
            FIRST PASS
          </td>
          <td colSpan={8} className="text-start">
            {firstAdhesive?.data?.layer?.name ||
              firstAdhesive?.data?.layer?.name}
          </td>
          <td colSpan={3} className="text-start">
            Adhesive
          </td>
          <td colSpan={3} className="text-start">
            {getReqQty({
              gsm: adhesives.length * 2,
              totalGSM: bomData?.total_thickness_gsm,
              outputKg: work?.output_quantity,
              totalWaste,
            })}
            kg
          </td>
        </tr>
        <tr className="bg-grey-200">
          <td colSpan={6} className="text-start">
            Second Play Input
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Second Ply Width
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Material Spec.
          </td>
          <td colSpan={4} className="text-start">
            {secondFlim?.data?.layer?.flim_micron ||
              secondFlim?.data?.layer?.poly_micron}
            μm
          </td>
          <td colSpan={6} className="text-start">
            Adhesive Grade
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Material Type
          </td>
          <td colSpan={4} className="text-start">
            {secondFlim?.data?.layer?.name || secondFlim?.data?.layer?.name}
          </td>
          <td colSpan={6} className="text-start">
            Hardner Grade
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Issued Qty.
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Adhesive Used
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Scrap Qty.
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Hardner Used
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Consumption Qty.
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Total GSM
          </td>
          <td colSpan={4} className="text-start">
            {bomData?.total_thickness_gsm}
          </td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Film Width
          </td>
          <td colSpan={4} className="text-start">
            {cylinderData?.total_printing_width}mm
          </td>
          <td colSpan={6} className="text-start">
            Meter Received
          </td>
          <td colSpan={4} className="text-start">
            {firstLayerTargetMtr}
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={20}></td>
        </tr>
        <tr className="bg-grey-200">
          <td colSpan={6} className="text-start">
            First Pass Output
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            No. of Rolls
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Output in Kgs
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Wastage in Kgs
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Target Adhesive GSM
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Actual Adhesive GSM
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start fw-bold bg-grey-200">
            SECOND PASS
          </td>
          <td colSpan={6} className="text-start">
            {secondAdhesive?.data?.layer?.name ||
              secondAdhesive?.data?.layer?.name}
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="bg-grey-200">
          <td colSpan={6} className="text-start">
            First Ply Input
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Second Ply Input
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Material Spec.
          </td>
          <td colSpan={4} className="text-start">
            {thirdFlim?.data?.layer?.flim_micron ||
            thirdFlim?.data?.layer?.poly_micron
              ? `${
                  thirdFlim?.data?.layer?.flim_micron ||
                  thirdFlim?.data?.layer?.poly_micron
                }μm`
              : ''}
          </td>
          <td colSpan={6} className="text-start">
            Material Spec.
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Material Type
          </td>
          <td colSpan={4} className="text-start">
            {thirdFlim?.data?.layer?.name || thirdFlim?.data?.layer?.name}
          </td>
          <td colSpan={6} className="text-start">
            Print/Plain/1st Pass
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Issued Qty.
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Issued Qty.
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Scrap Qty.
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Scrap Qty.
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Consumption Qty.
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Consumption Qty.
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="bg-grey-200">
          <td colSpan={6} className="text-start">
            Second Ply Width
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Final Pass Output
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Adhesive Grade
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            No. of Rolls
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Hardner Grade
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Output in Kgs.
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Adhesive Used
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Wastage in Kgs
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Hardner Used
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Target Adhesive GSM
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={6} className="text-start">
            Solvent Used
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Actual Adhesive GSM
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr
          style={{
            height: '60px',
          }}
          className={styles.blank_row}
        >
          <td colSpan={20}></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={7}>Prepared By</td>
          <td colSpan={7}>Verified By</td>
          <td colSpan={6}>Approved By</td>
        </tr>
      </tbody>
    </table>
  )
}

export default LaminationWorkOrder
