import React, { FC, ReactNode } from 'react'
import { Button } from 'yolo-design/components'
import cn from 'classnames'

type propTypes = {
  label: string | ReactNode
  value: string | ReactNode
  onEdit?: () => void
  hideBorder?: boolean
}

const EditFieldItem: FC<propTypes> = ({
  label,
  value,
  onEdit,
  hideBorder = false,
}) => {
  return (
    <div
      className={cn('d-flex flex-column pb-12 mb-12', {
        'border-bottom border-grey-200': !hideBorder,
      })}
    >
      <span className="text-label-extra-small fw-medium text-grey-600">
        {label}
      </span>
      <div className="d-flex w-100 justify-content-between mt-8">
        <span className="text-body-medium fw-medium text-dark">
          {value || '-'}
        </span>
        {!!onEdit && (
          <Button antdButtonProps={{ onClick: onEdit }} type="ghost">
            Edit
          </Button>
        )}
      </div>
    </div>
  )
}

export default EditFieldItem
