import React, { FC } from 'react'

import TableNameCard from '@components/TableNameCard'
import { ColumnsType } from 'antd/es/table'
import { Table } from 'yolo-design/components'
import { getReqQty } from '@utils/formula'

const AdhesiveTable: FC<{
  outputKg: number
  data: Record<string, any>
  wastage?: number
}> = ({ outputKg, data, wastage = 0 }) => {
  const bomData = data?.bill_of_materials?.bom
    ?.filter((f: any) => f?.data?.code === 3 && f?.data?.code !== 1)
    ?.map((el: any) => ({
      id: el?.id,
      order: el?.data?.order,
      ...(el?.data?.layer || {}),
    }))

  const totalGSM = data?.bill_of_materials?.total_thickness_gsm

  const totalWaste = wastage

  const columns: ColumnsType<any> = [
    {
      title: 'NAME',
      dataIndex: 'name',
      render: (val, column) => (
        <TableNameCard image={column?.adhesive_image} title={val} subTitle="" />
      ),
    },
    {
      title: 'LOCATION',
      dataIndex: 'adhesive_location',
      render: (val) => val || '-',
    },
    {
      title: 'RATIO',
      dataIndex: 'adhesive_ratio',
      render: (val) => val || '-',
    },
    {
      title: 'TYPE',
      dataIndex: 'adhesive_adhesive_type',
      render: (val) => val || '-',
    },
    {
      title: 'GRADE',
      dataIndex: 'adhesive_grade',
      render: (val) => val || '-',
    },
    {
      title: 'GSM',
      dataIndex: 'gsm',
      render: () => 2 || '-',
    },
    {
      title: 'QTY REQUIRED',
      dataIndex: 'qty',
      onCell: () => {
        return {
          className: 'bg-grey-200',
        }
      },
      render: () =>
        `${getReqQty({ gsm: 2, totalGSM, outputKg, totalWaste })} Kg`,
    },
  ]

  return (
    <>
      {bomData?.length === 0 ? (
        <></>
      ) : (
        <div className="d-flex flex-column w-100 mt-32">
          <span className="text-title-medium fw-bold">Adhesive</span>
          <Table
            className="mt-12"
            rowKey={'localId'}
            columns={columns}
            dataSource={bomData
              ?.sort((a: any, b: any) => a?.order - b?.order)
              ?.map((el: any, i: number) => ({
                ...el,
                localId: el.id + '-' + i,
                name: el.name || el.group_name,
              }))}
          />
        </div>
      )}
    </>
  )
}

export default AdhesiveTable
