import TableNameCard from '@components/TableNameCard'
import { getGSM, getReqQty } from '@utils/formula'
import { ColumnsType } from 'antd/es/table'
import React, { FC } from 'react'
import { Table } from 'yolo-design/components'

const FilmTable: FC<{
  outputKg: number
  data: Record<string, any>
  wastage?: number
}> = ({ outputKg, data, wastage = 0 }) => {
  const flimData = data?.bill_of_materials?.bom
    ?.filter((f: any) => f?.data?.code === 2 || f?.data?.code === 8)
    ?.map((el: any) => ({
      id: el?.id,
      order: el?.data?.order,
      ...(el?.data?.layer || {}),
    }))

  const totalGSM = data?.bill_of_materials?.total_thickness_gsm

  const totalWaste = wastage

  const columns: ColumnsType<any> = [
    {
      title: 'NAME',
      dataIndex: 'name',
      render: (val, column) => (
        <TableNameCard image={column?.image} title={val} subTitle="" />
      ),
    },
    {
      title: 'LOCATION',
      dataIndex: 'location',
      render: (val) => val || '-',
    },
    {
      title: 'WIDTH',
      dataIndex: 'material_size',
      render: (val) => val || '-',
    },
    {
      title: 'DENSITY',
      dataIndex: 'density',
      render: (val) => val || '-',
    },
    {
      title: 'MICRON',
      dataIndex: 'micron',
      render: (val) => val || '-',
    },
    {
      title: 'GSM',
      dataIndex: 'gsm',
      render: (_, column) => getGSM(column?.micron, column?.density) || '-',
    },
    {
      title: 'QTY REQUIRED',
      dataIndex: 'qty',
      onCell: () => {
        return {
          className: 'bg-grey-200',
        }
      },
      render: (val, column) =>
        `${getReqQty({
          gsm: getGSM(column?.micron, column?.density),
          totalGSM,
          outputKg,
          totalWaste,
        })} Kg`,
    },
  ]

  return (
    <>
      {flimData?.length === 0 ? (
        <></>
      ) : (
        <div className="d-flex flex-column w-100 mt-32">
          <span className="text-title-medium fw-bold">Film</span>
          <Table
            className="mt-12"
            rowKey={'localId'}
            columns={columns}
            dataSource={flimData
              ?.sort((a: any, b: any) => a?.order - b?.order)
              ?.map((el: any, i: number) => ({
                ...el,
                localId: el.id + '-' + i,
                name: el.name || el.name,
                location: el.flim_location || el.poly_location,
                micron: el.flim_micron || el.poly_micron,
                density: el.flim_density || el.poly_density,
                material_size: el.flim_material_size || el.poly_material_size,
                image: el.flim_image || el.poly_image,
              }))}
          />
        </div>
      )}
    </>
  )
}

export default FilmTable
