import { ProcessFormFieldsKey } from '@contracts/job'
import {
  useDCoreTypeId,
  useDFormOptionList,
  useDFormProcessId,
  useDFormTypeJobId,
  useDInspectionId,
  useDPouchTypeId,
  useDPrintingDirectionId,
  useDPunchId,
  useDSealTypeId,
  useDSlitTypeId,
  useDUnwindingDirectionId,
} from '@services/dynamic'
import { restrictToNumbersAndDots } from '@utils/input'
import React, { FC } from 'react'
import { FormInput, FormSelect } from 'yolo-design/components'

const ProcessFormFields: FC<{
  name: ProcessFormFieldsKey
  props?: any
  onChange?: any
}> = ({ name, props, onChange }) => {
  const formTypeId = useDFormTypeJobId() || ''
  const processId = useDFormProcessId(formTypeId) || ''

  const unwindingDirectionKeyId =
    useDUnwindingDirectionId({ formId: processId }) || ''
  const printingDirectionKeyId =
    useDPrintingDirectionId({ formId: processId }) || ''
  const inspectionKeyId = useDInspectionId({ formId: processId }) || ''
  const coreTypeKeyId = useDCoreTypeId({ formId: processId }) || ''
  const pouchTypeKeyId = useDPouchTypeId({ formId: processId }) || ''
  const punchKeyId = useDPunchId({ formId: processId }) || ''
  const sealTypeId = useDSealTypeId({ formId: processId }) || ''
  const slitTypeKeyId = useDSlitTypeId({ formId: processId }) || ''

  const slitTypeOptions = useDFormOptionList(slitTypeKeyId)
  const unwindingDirectionOptions = useDFormOptionList(unwindingDirectionKeyId)
  const printingDirectionOptions = useDFormOptionList(printingDirectionKeyId)
  const inspectionOptions = useDFormOptionList(inspectionKeyId)
  const coreTypeOptions = useDFormOptionList(coreTypeKeyId)
  const pouchTypeOptions = useDFormOptionList(pouchTypeKeyId)
  const punchOptions = useDFormOptionList(punchKeyId)
  const sealTypeOptions = useDFormOptionList(sealTypeId)

  const isLT100 = (pw: string, lw: string, tw: string) =>
    Number(pw || 0) + Number(lw || 0) + Number(tw || 0) <= 100
  const handleChange = (e: any) => {
    const newValue = e.target.value
    // Call the onChange callback with the new value
    if (onChange) {
      onChange(newValue)
    }
  }
  return (
    <>
      {name === 'unwinding_direction' && (
        <FormSelect
          {...props}
          name={`unwinding_direction`}
          placeHolder="Select From Option"
          title="Unwinding Direction"
          optionsSelect={unwindingDirectionOptions}
        />
      )}
      {name === 'printing_direction' && (
        <FormSelect
          {...props}
          andtFormItemProps={{
            rules: [{ required: true, message: 'Required' }],
          }}
          name={`printing_direction`}
          placeHolder="Select From Option"
          title="Printing Direction"
          optionsSelect={printingDirectionOptions}
        />
      )}
      {name === 'inspection' && (
        <FormSelect
          {...props}
          andtFormItemProps={{
            rules: [{ required: true, message: 'Required' }],
          }}
          name={`inspection`}
          placeHolder="Select From Option"
          title="Inspection"
          optionsSelect={inspectionOptions}
        />
      )}
      {name === 'print_waste' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            rules: [
              { required: true, message: 'Required' },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (
                    !isLT100(
                      value,
                      getFieldValue('lamination_waste'),
                      getFieldValue('trim_slitting_waste')
                    )
                  ) {
                    return Promise.reject('Total exceeds 100%')
                  }

                  return Promise.resolve()
                },
              }),
            ],
            dependencies: ['lamination_waste', 'trim_slitting_waste'],
          }}
          placeHolder="0%"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'print_waste'}
          title={'Print Waste'}
        />
      )}
      {name === 'lamination_waste' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            rules: [
              { required: true, message: 'Required' },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (
                    !isLT100(
                      value,
                      getFieldValue('print_waste'),
                      getFieldValue('trim_slitting_waste')
                    )
                  ) {
                    return Promise.reject('Total exceeds 100%')
                  }

                  return Promise.resolve()
                },
              }),
            ],
            dependencies: ['print_waste', 'trim_slitting_waste'],
          }}
          placeHolder="0%"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'lamination_waste'}
          title={'Lamination Waste'}
        />
      )}
      {name === 'trim_slitting_waste' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            rules: [
              { required: true, message: 'Required' },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (
                    !isLT100(
                      value,
                      getFieldValue('lamination_waste'),
                      getFieldValue('print_waste')
                    )
                  ) {
                    return Promise.reject('Total exceeds 100%')
                  }

                  return Promise.resolve()
                },
              }),
            ],
            dependencies: ['lamination_waste', 'print_waste'],
          }}
          placeHolder="0%"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'trim_slitting_waste'}
          title={'Trim/Slitting/Waste'}
        />
      )}
      {name === 'core_type' && (
        <FormSelect
          {...props}
          andtFormItemProps={{
            rules: [{ required: true, message: 'Required' }],
          }}
          name={`core_type`}
          placeHolder="Select From Option"
          title="Core Type"
          optionsSelect={coreTypeOptions}
        />
      )}
      {name === 'slit_type' && (
        <FormSelect
          {...props}
          andtFormItemProps={{
            rules: [{ required: true, message: 'Required' }],
          }}
          name={`slit_type`}
          placeHolder="Select From Option"
          title="Slit Type"
          optionsSelect={slitTypeOptions}
        />
      )}
      {name === 'bobbin_weight' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            rules: [
              { required: true, message: 'Required' },
              { max: 10, message: 'Max characters ' },
            ],
          }}
          placeHolder=""
          // inputType="number"
          antdInputProps={{
            onChange: handleChange,
          }}
          onInput={restrictToNumbersAndDots}
          name={'bobbin_weight'}
          title={'Bobbin Weight'}
        />
      )}
      {name === 'bobbin_od' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            rules: [
              { required: true, message: 'Required' },
              { max: 10, message: 'Max characters ' },
            ],
          }}
          placeHolder="0mm"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'bobbin_od'}
          title={'Bobbin OD'}
        />
      )}
      {name === 'bobbin_meter' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            rules: [{ required: true, message: 'Required' }],
          }}
          placeHolder="0mm"
          readOnly
          onInput={restrictToNumbersAndDots}
          name={'bobbin_meter'}
          title={'Bobbin Meter'}
        />
      )}

      {name === 'pouch_height' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            rules: [
              { required: true, message: 'Required' },
              { max: 10, message: 'Max characters ' },
            ],
          }}
          placeHolder="0mm"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'pouch_height'}
          title={'Pouch Height'}
        />
      )}
      {name === 'pouch_flop' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            rules: [{ max: 10, message: 'Max characters ' }],
          }}
          placeHolder="0mm"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'pouch_flop'}
          title={'Pouch Flop'}
        />
      )}
      {name === 'pouch_width' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            rules: [
              { required: true, message: 'Required' },
              { max: 10, message: 'Max characters ' },
            ],
          }}
          placeHolder="0mm"
          // inputType="number"
          onInput={restrictToNumbersAndDots}
          name={'pouch_width'}
          title={'Pouch Width'}
        />
      )}
      {name === 'pouch_weight' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            initialValue: 0,
            rules: [
              {
                required: true,
                message: 'Required',
              },
              {
                validator: (_, value) => {
                  if (value <= 0) {
                    return Promise.reject('Invalid')
                  }

                  return Promise.resolve()
                },
              },
            ],
          }}
          readOnly
          placeHolder="0g"
          inputType="number"
          name={'pouch_weight'}
          title={'Pouch Weight'}
        />
      )}
      {name === 'pouch_per_kg' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            initialValue: 0,
          }}
          readOnly
          placeHolder="0 Pouches"
          inputType="number"
          name={'pouch_per_kg'}
          title={'Pouch Per Kg'}
        />
      )}
      {name === 'pouch_type' && (
        <FormSelect
          {...props}
          andtFormItemProps={{
            rules: [{ required: true, message: 'Required' }],
          }}
          name={`pouch_type`}
          placeHolder="Select From Option"
          title="Pouch Type"
          optionsSelect={pouchTypeOptions}
        />
      )}
      {name === 'guest_width' && (
        <FormInput
          {...props}
          placeHolder="0mm"
          inputType="text"
          name={'guest_width'}
          title={'Gusset Width'}
        />
      )}
      {name === 'seal_width' && (
        <FormInput
          {...props}
          andtFormItemProps={{
            rules: [{ required: true, message: 'Required' }],
          }}
          placeHolder="0mm"
          inputType="text"
          name={'seal_width'}
          title={'Seal Width'}
        />
      )}
      {name === 'punch' && (
        <FormSelect
          {...props}
          antdSelectProps={{ allowClear: true, ...props?.antdSelectProps }}
          name={`punch`}
          placeHolder="Select From Option"
          title="Punch (optional)"
          optionsSelect={punchOptions}
        />
      )}
      {name === 'seal_type' && (
        <FormSelect
          {...props}
          antdSelectProps={{ allowClear: true, ...props?.antdSelectProps }}
          name={`seal_type`}
          placeHolder="Select From Option"
          title="Seal Type (Top/Bottom)"
          optionsSelect={sealTypeOptions}
        />
      )}
      {name === 'no_of_holes' && (
        <FormInput
          {...props}
          placeHolder="0"
          inputType="text"
          name={'no_of_holes'}
          title={'No of Holes'}
        />
      )}
    </>
  )
}

export default ProcessFormFields
