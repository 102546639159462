import React, { FC, useEffect, useState } from 'react'
import useRouteNavigate from '@hooks/useRouteNavigate'
import { useOrgUserInfo } from '@services/org'
import { getPasscode, removePasscode, setOrgId, setPasscode } from '@utils/auth'
import nProgress from 'nprogress'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { refKeyOnOrgChng } from '@constants/queryKeys'
import useSentry from '@hooks/useSentry'
import mixpanelService from '@config/mixPanel'

const Middleware: FC = () => {
  const queryClient = useQueryClient()
  const routeNavigate = useRouteNavigate()
  const [isLoaded, setIsLoaded] = useState(false)
  const { pathname, search } = useLocation()
  const { orgId } = useParams()
  const { setUser } = useSentry()
  const passcode = getPasscode()

  const orgUserInfo = useOrgUserInfo({
    enabled: false,
    onSuccess: (data) => {
      const orgList = data.organization
      const orgDetails = data?.organization?.find(
        (el) => String(el?.id) === String(orgId)
      )
      setIsLoaded(true)
      mixpanelService?.userEvent(String(data?.id))
      mixpanelService?.userDetailsEvent({
        name: data?.first_name,
        email: data?.email,
        orgName: orgDetails?.name,
        orgId: orgDetails?.id,
      })
      setPasscode(passcode)

      setUser({ email: data.email, id: data.id })

      if (orgList.length) {
        const pathArr = pathname.split('/')
        const org = orgList?.find((f) => f.id === orgId) || orgList[0]

        setOrgId(org.id)

        if (orgId || pathname === '/') {
          pathArr[1] = org.id
        }

        if (pathname.startsWith('/user')) {
          return routeNavigate(`/${org.id}` + (search || ''))
        }

        routeNavigate(pathArr.join('/') + (search || ''))
      } else {
        routeNavigate('org/create')
      }
      nProgress.done()
    },
    onError: () => {
      setIsLoaded(true)
      removePasscode()
      routeNavigate('/')
      nProgress.done()
    },
  })

  useEffect(() => {
    nProgress.start()
    const passcode = getPasscode()

    if (passcode) {
      setPasscode(passcode)
      orgUserInfo.refetch()
    } else {
      setIsLoaded(true)
      nProgress.done()
      routeNavigate('/')
    }
  }, [])

  useEffect(() => {
    if (orgId) {
      const keys: string[] = refKeyOnOrgChng

      keys.forEach((k) => {
        queryClient.invalidateQueries({ queryKey: [k], type: 'active' })
      })
    }
  }, [orgId])

  return isLoaded ? <Outlet /> : <></>
}

export default Middleware
