import { Divider } from 'antd'
import React, { FC, RefObject } from 'react'
import Barcode from 'react-barcode'
import cn from 'classnames'

const JobShadeCardPrint: FC<{
  elRef?: RefObject<any>
  orgName?: string
  data?: Record<any, any>
}> = ({ elRef, orgName, data }) => {
  const location =
    data?.shade_location_name && data?.job_code
      ? data?.job_code + '-' + data?.shade_location_name
      : ''
  return (
    <div
      ref={elRef}
      className={cn(
        'd-flex flex-column text-body-small fw-semibold px-8 py-4 pb-0'
      )}
    >
      <span className="text-center">{orgName}</span>
      <Divider className="my-4 border-black" dashed />
      <span className="">
        Job Name: <b>{data?.name}</b>{' '}
      </span>
      <span className="mt-4">
        Job Code: <b>{data?.job_code}</b>{' '}
      </span>
      <span className="mt-4">
        Party: <b>{data?.customer_id?.name}</b>{' '}
      </span>
      <span className="mt-4">
        Location: <b>{data?.shade_location_name}</b>{' '}
      </span>
      <div className="d-flex w-100">
        {location && (
          <Barcode width={1.3} height={25} fontSize={14} value={location} />
        )}
      </div>
    </div>
  )
}

export default JobShadeCardPrint
