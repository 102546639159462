import { mergeQueryKeys } from '@lukemorales/query-key-factory'

import { orgQueries } from './org'
import { masterQueries } from './master'
import { customerQueries } from './customer'
import { dynamicQueries } from './dynamic'
import { productQueries } from './product'
import { purchaseQueries } from './purchase'
import { dispatchQueries } from './dispatch'

export const refKeyOnOrgChng = [
  'customer',
  'dynamic',
  'product',
  'purchase',
  'dispatch',
]

export const queries = mergeQueryKeys(
  orgQueries,
  masterQueries,
  dynamicQueries,
  customerQueries,
  productQueries,
  purchaseQueries,
  dispatchQueries
)
