import React, { FC, RefObject } from 'react'
import styles from '../style.module.scss'
import cn from 'classnames'
import { useOrgInfo } from '@services/org'
import { useDFormKeyValue, useDWorkOrder } from '@services/dynamic'
import { useSearchParams } from 'react-router-dom'
import { RAW_MATERIAL_FORM_CODE } from '@constants/dynamic'
import { convertKgToMtr, getGSM, getReqQty } from '@utils/formula'

const PolyWorkOrder: FC<{ elRef: RefObject<any> }> = ({ elRef }) => {
  const [searchParams] = useSearchParams()

  const jobId = searchParams.get('job') || ''
  const workId = searchParams.get('work') || ''

  const { data: org } = useOrgInfo()

  const { data: work, isLoading: workLoading } = useDWorkOrder(
    {
      enabled: !!workId,
    },
    { formId: workId }
  )

  const { data: job } = useDFormKeyValue(
    {
      enabled: !!jobId,
    },
    { formId: jobId }
  )

  const data =
    !!workId && workLoading ? {} : work?.is_completed ? work?.job_data : job

  const jobData = data?.job
  const bomData = data?.bill_of_materials
  const cylinderData = data?.cylinder_details

  const polyData = bomData?.bom?.find(
    (f: any) => f?.data?.layer?.form_code === RAW_MATERIAL_FORM_CODE['poly']
  )?.data?.layer

  const totalWaste = work?.wastage

  const polyReqKg = getReqQty({
    gsm: getGSM(polyData?.poly_micron, polyData?.poly_density),
    totalGSM: bomData?.total_thickness_gsm,
    totalWaste,
    outputKg: work?.output_quantity,
  })

  const polyGSM = getGSM(polyData?.poly_micron, polyData?.poly_density)

  return (
    <table
      ref={elRef}
      className={cn(
        styles.jis_table,
        'bg-white w-100 border text-label-small fw-medium'
      )}
    >
      <colgroup>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
      </colgroup>
      <tbody>
        <tr>
          <td colSpan={3} rowSpan={2}></td>
          <td
            colSpan={18}
            className="text-heading-small text-center fw-semibold"
          >
            {org?.name}
          </td>
        </tr>
        <tr>
          <td colSpan={20} className="text-title-medium fw-medium text-center">
            POLY WORK ORDER
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Job No
          </td>
          <td colSpan={12} className="text-start">
            {jobData?.job_code}
          </td>
          <td colSpan={5} className="text-start text-label-small">
            No: {work?.work_order_number}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Name
          </td>
          <td colSpan={12} className="text-start">
            {jobData?.name}
          </td>
          <td colSpan={2} className="text-start">
            Date
          </td>
          <td colSpan={3} className="text-start">
            {work?.started_date
              ? new Date(work?.started_date)?.toLocaleDateString('en-IN')
              : '-'}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Party
          </td>
          <td colSpan={12} className="text-start">
            {jobData?.customer_id?.name}
          </td>
          <td colSpan={2} className="text-start">
            Date
          </td>
          <td colSpan={3} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Size(mm)
          </td>
          <td colSpan={4} className="text-start">
            {polyData?.poly_material_size} mm
          </td>
          <td colSpan={6} className="text-start">
            Thickness(Mic)
          </td>
          <td colSpan={4} className="text-start">
            {polyData?.poly_micron} μm
          </td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Color
          </td>
          <td colSpan={14} className="text-start">
            {polyData?.name}
          </td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            GSM Range
          </td>
          <td colSpan={14} className="text-start">
            {Number(polyGSM - polyGSM * 0.05).toFixed(2)} -{' '}
            {Number(polyGSM + polyGSM * 0.05).toFixed(2)}
          </td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Qty. In Kgs
          </td>
          <td colSpan={4} className="text-start">
            {polyReqKg} Kg
          </td>
          <td colSpan={6} className="text-start">
            Qty. In Mtr
          </td>
          <td colSpan={4} className="text-start">
            {convertKgToMtr({
              flimWidth: cylinderData?.total_printing_width,
              outputKg: Number(polyReqKg),
              totalGSM: polyGSM,
            })}
          </td>
        </tr>
        <tr className="bg-grey-400">
          <td colSpan={7} className="text-start">
            Inner
          </td>
          <td colSpan={7} className="text-start">
            Middle
          </td>
          <td colSpan={6} className="text-start">
            Outer
          </td>
        </tr>
        <tr>
          <td colSpan={4} className="text-start">
            Grade
          </td>
          <td colSpan={3} className="text-start">
            Qty.
          </td>
          <td colSpan={4} className="text-start">
            Grade
          </td>
          <td colSpan={3} className="text-start">
            Qty.
          </td>
          <td colSpan={4} className="text-start">
            Grade
          </td>
          <td colSpan={3} className="text-start">
            Qty.
          </td>
        </tr>
        {[1, 2, 3, 4, 5, 6].map((el) => (
          <tr key={el} style={{ height: '20px' }}>
            <td colSpan={4} className="text-start"></td>
            <td colSpan={3} className="text-start"></td>
            <td colSpan={4} className="text-start"></td>
            <td colSpan={3} className="text-start"></td>
            <td colSpan={4} className="text-start"></td>
            <td colSpan={3} className="text-start"></td>
          </tr>
        ))}
        <tr className="bg-grey-400">
          <td colSpan={7} className="text-start">
            Inner
          </td>
          <td colSpan={7} className="text-start">
            Middle
          </td>
          <td colSpan={6} className="text-start">
            Outer
          </td>
        </tr>
        <tr>
          <td colSpan={4} className="text-start">
            Grade
          </td>
          <td colSpan={3} className="text-start">
            %
          </td>
          <td colSpan={4} className="text-start">
            Grade
          </td>
          <td colSpan={3} className="text-start">
            %
          </td>
          <td colSpan={4} className="text-start">
            Grade
          </td>
          <td colSpan={3} className="text-start">
            %
          </td>
        </tr>
        {[1, 2, 3, 4, 5, 6].map((el) => (
          <tr key={el} style={{ height: '20px' }}>
            <td colSpan={4} className="text-start"></td>
            <td colSpan={3} className="text-start"></td>
            <td colSpan={4} className="text-start"></td>
            <td colSpan={3} className="text-start"></td>
            <td colSpan={4} className="text-start"></td>
            <td colSpan={3} className="text-start"></td>
          </tr>
        ))}
        <tr>
          <td colSpan={4} className="text-start">
            Remarks
          </td>
        </tr>
        <tr style={{ height: '80px' }} className={styles.blank_row}>
          <td colSpan={20} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={10} className="text-start">
            Operator
          </td>
          <td colSpan={10} className="text-start">
            Supervisor
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default PolyWorkOrder
