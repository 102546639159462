import React, { useEffect, useState } from 'react'
import { Color } from 'yolo-design/constants'
import { DoubleChevronRight, Sync } from 'yolo-design/icons'
import DispCard from './DispCard'
import ColorTable from './Tables/ColorTable'
import FilmTable from './Tables/FilmTable'
import AdhesiveTable from './Tables/AdhesiveTable'
import CylinderTable from './Tables/CylinderTable'
import TableNameCard from '@components/TableNameCard'
import { Col, Row } from 'antd'
import { useSearchParams } from 'react-router-dom'
import {
  useDFormKeyValue,
  useDWorkOrder,
  useJobSearchList,
} from '@services/dynamic'
import { imagePlaceholder } from 'yolo-design/assets'
import { Button, Drawer, FormItem, FormSelect } from 'yolo-design/components'
import { DRAWER_WIDTH, NESTED_DRAWER_WIDTH } from '@constants/index'
import JobInfo from '@components/Customer/Job/Info'
import nProgress from 'nprogress'

const Info = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [jobSearch, setJobSearch] = useState('')

  const jobId = searchParams.get('job') || ''
  const viewJob = searchParams.get('viewJob') || ''
  const view = searchParams.get('view') || 'add'
  const workId = searchParams.get('work') || ''
  const drawerType = searchParams.get('drawer')

  const { data: jobSearchList, isLoading: jobLoading } = useJobSearchList(
    {},
    { search: jobSearch }
  )

  const { data: job, isLoading } = useDFormKeyValue(
    {
      enabled: !!jobId,
    },
    { formId: jobId }
  )

  const { data: workData, isLoading: workLoading } = useDWorkOrder(
    {
      enabled: !!workId,
    },
    { formId: workId }
  )

  const data =
    !!workId && workLoading
      ? {}
      : workData?.is_completed
      ? workData?.job_data
      : job

  const jobData = data?.job
  const bomData = data?.bill_of_materials
  const cylinderData = data?.cylinder_details
  const colorData = data?.color
  const processData = data?.process_and_details

  useEffect(() => {
    if (!!jobId && isLoading) {
      nProgress.start()
    } else {
      nProgress.done()
    }
  }, [isLoading, jobId])

  return (
    <>
      <Col style={{ height: 'calc(100vh - 60px)' }} span={17}>
        <div className="d-flex flex-column w-100 h-100 px-24 py-24 overflow-scroll">
          {jobId ? (
            <div className="d-flex w-100 flex-column">
              <div className="d-flex w-100 justify-content-between">
                <div className="d-flex">
                  <img
                    width={90}
                    height={114}
                    src={jobData?.image || imagePlaceholder}
                    alt="image"
                    className="object-fit-contain border border-2 rounded-12 border-grey-200"
                  />
                  <div className="d-flex flex-column ms-16 justify-content-around">
                    <div className="d-flex flex-column">
                      <span className="text-heading-medium fw-semibold text-dark">
                        {jobData?.name}
                      </span>
                      <span className="text-title-medium fw-medium mt-4 text-grey-700">
                        Job Code: {jobData?.job_code}
                      </span>
                    </div>
                    {!workData?.is_completed && (
                      <Button
                        icon={DoubleChevronRight}
                        iconPosition="right"
                        type="ghost"
                        antdButtonProps={{
                          onClick: () => {
                            searchParams.set('viewJob', 'true')
                            setSearchParams(searchParams)
                          },
                        }}
                      >
                        View Details
                      </Button>
                    )}
                  </div>
                </div>
                {view !== 'view' && (
                  <div className="d-flex">
                    <Button
                      size="extra_large"
                      type="ghost"
                      color={Color.background.dark}
                      icon={Sync}
                      antdButtonProps={{
                        onClick: () => {
                          searchParams.delete('job')
                          setSearchParams(searchParams)
                        },
                      }}
                    />
                  </div>
                )}
              </div>
              <Row
                gutter={[8, 24]}
                className="w-100 mt-20 pb-32 border-bottom border-grey-200"
              >
                <DispCard
                  label="TYPE OF PRINTING"
                  value={bomData?.printing_type?.name}
                />
                <DispCard
                  label="PRINTING DIRECTION"
                  value={processData?.printing_direction?.name}
                />
                <DispCard
                  label="UNWINDING DIRECTION"
                  value={processData?.unwinding_direction?.name}
                />
                <DispCard
                  label="NO OF COLOR"
                  value={colorData?.colors?.length}
                />
                <DispCard
                  label="SLITTING / POUCHING"
                  colSpan={4}
                  value={
                    !processData?.slit_type?.code
                      ? '-'
                      : processData?.slit_type?.code === 2
                      ? processData?.pouch_type?.name
                      : 'Roll Form'
                  }
                />
                <DispCard
                  label="JOB WIDTH"
                  value={`${cylinderData?.job_width || 0}mm`}
                />
                <DispCard
                  label="JOB HEIGHT"
                  value={`${cylinderData?.job_height || 0}mm`}
                />
                <DispCard
                  label="TOTAL PRINTING WIDTH"
                  value={`${cylinderData?.total_printing_width || 0}mm`}
                />
                <DispCard
                  label="TOTAL GSM"
                  value={bomData?.total_thickness_gsm || 0}
                />
                <DispCard
                  label="INSPECTION"
                  colSpan={4}
                  value={processData?.inspection?.name || '-'}
                />
              </Row>
              <FormItem noStyle shouldUpdate>
                {(form) => {
                  const outputKg = form.getFieldValue('output_quantity')
                  const wastage =
                    (view === 'view'
                      ? workData?.wastage
                      : form.getFieldValue('wastage')) || 0

                  return (
                    <>
                      {!!colorData?.colors?.length && (
                        <ColorTable
                          wastage={wastage}
                          data={data}
                          outputKg={outputKg}
                        />
                      )}
                      <FilmTable
                        wastage={wastage}
                        data={data}
                        outputKg={outputKg}
                      />
                      <AdhesiveTable
                        wastage={wastage}
                        data={data}
                        outputKg={outputKg}
                      />
                      {!!colorData?.colors?.length && (
                        <CylinderTable data={data} />
                      )}
                    </>
                  )
                }}
              </FormItem>
            </div>
          ) : (
            <FormSelect
              required
              name={'job'}
              andtFormItemProps={{ className: 'w-100', preserve: false }}
              antdSelectProps={{
                searchValue: jobSearch,
                onSearch: (val) => setJobSearch(val),
                loading: jobLoading,
                showSearch: true,
                optionLabelProp: 'name',
                onSelect: (key) => {
                  searchParams.set('job', key)
                  setSearchParams(searchParams)
                },
                filterOption: false,
              }}
              optionsSelect={jobSearchList?.data?.map((el) => ({
                ...el,
                name: el.data.name,
                value: el.data.id,
                label: (
                  <div className="border-bottom border-grey-200 py-8">
                    <TableNameCard
                      image={el.data.image}
                      title={el.data.name}
                      subTitle={`Code: ${el.data.job_code}`}
                    />
                  </div>
                ),
              }))}
              placeHolder="Enter Recipe Name"
            />
          )}
        </div>
      </Col>

      <Drawer
        destroyOnClose
        push={drawerType === 'nested' ? {} : { distance: NESTED_DRAWER_WIDTH }}
        width={DRAWER_WIDTH}
        customHeader
        onClose={() => {
          searchParams.set('viewJob', 'false')
          setSearchParams(searchParams)
        }}
        titleProps={{}}
        open={viewJob === 'true'}
      >
        <JobInfo initialData={data} />
      </Drawer>
    </>
  )
}

export default Info
