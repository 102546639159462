import React, { FC, RefObject } from 'react'
import cn from 'classnames'
import styles from './style.module.scss'
import { Divider } from 'antd'
import Barcode from 'react-barcode'
import { IDFormKeyValue } from '@contracts/models/dynamic'
import { getTotalPrintWidth } from '@utils/formula'

const JobColorPrint: FC<{
  elRef?: RefObject<any>
  colorData?: IDFormKeyValue
  data?: IDFormKeyValue
  orgName?: string
  position: number
}> = ({ elRef, orgName, colorData, data, position }) => {
  const cylinderCode = colorData?.cylinder?.cylinder_cylinder_code
  const jobData = data?.job
  const jobCode = jobData?.job_code

  return (
    <div
      ref={elRef}
      className={cn(
        'd-flex flex-column text-body-medium fw-medium',
        styles.job
      )}
    >
      <span className="text-center">{orgName}</span>
      <Divider className="my-4 border-black" dashed />
      <div className="d-flex flex-column w-100">
        <div className="d-flex w-100">
          <div className="d-flex w-50">
            {cylinderCode && jobCode && (
              <Barcode
                width={0.6}
                height={30}
                fontSize={14}
                value={`${cylinderCode}-${jobCode}`}
              />
            )}
          </div>
          <div className="d-flex flex-column w-50 ps-4">
            <span className="fw-semibold">
              {colorData?.cylinder?.cylinder_use?.name}
            </span>
            <span className="d-flex mt-2 fw-medium">Job Code: {jobCode}</span>
            {!!colorData?.cylinder?.location && (
              <span className="d-flex mt-2 fw-medium">
                Location: {colorData?.cylinder?.location}
              </span>
            )}
          </div>
        </div>
        <Divider className="mt-2 mb-4 border-black" dashed />
        {/* <span className="text-label-small">
          Barcode: {jobData?.barcode || '-'}
        </span> */}
        <span className="mt-4 text-label-medium fw-medium text-truncate">
          Name: {jobData?.name}
        </span>
        <span className="mt-4 text-label-small text-truncate">
          Party: {jobData?.customer_id?.name}
        </span>
        <div className="d-flex w-100">
          <div className="d-flex flex-column w-50">
            <span className="mt-4 text-label-small">
              Cyl. Size: {colorData?.cylinder?.cylinder_length} x{' '}
              {colorData?.cylinder?.cylinder_circumference}
            </span>
            <span className="mt-4 text-label-small">
              Ptg. Area:{' '}
              {getTotalPrintWidth(
                data?.cylinder_details?.no_of_steps,
                data?.cylinder_details?.job_width
              )}
            </span>
          </div>
          <div className="d-flex flex-column w-50">
            <span className="mt-4 text-label-small text-truncate">
              Color: {colorData?.ink?.name || colorData?.description}({position}
              )
            </span>
            <span className="mt-4 text-label-small text-truncate">
              Colors: {data?.color?.colors?.length || 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobColorPrint
