import Image from '@components/Image'
import React, { FC } from 'react'

const SelectItem: FC<{ name: string; sku: string; image: string }> = ({
  image,
  name,
  sku,
}) => {
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex w-max-content h-max-content rounded-4 overflow-hidden me-8 bg-white p-4">
        <Image src={image} width={48} height={64} />
      </div>
      <div className="d-flex flex-column fw-medium text-dark">
        <span className="text-body-medium fw-semibold">{name}</span>
        <div className="d-flex mt-12">
          <div className="d-flex text-body-small">
            <span className="text-grey-600">Code:&nbsp;</span>
            <span>{sku || '-'}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectItem
