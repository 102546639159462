import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Drawer, Select, Table } from 'yolo-design/components'
import EditOptions from './EditOptions'
import { useDForm, useSelectFields } from '@services/dynamic'
import { ColumnsType } from 'antd/es/table'
import { IStaticSelectFields } from '@contracts/models/dynamic'

const FormSelectFields = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const formFilter = searchParams.get('form') || 'all'
  const formQuery = formFilter === 'all' ? undefined : formFilter
  const editField = searchParams.get('edit_field')

  const editFieldId = searchParams.get('edit_field')
  const { data, isLoading } = useSelectFields({}, { formId: formQuery })
  const selectedField = data?.find((f) => f.id === editFieldId)

  const { data: formsList } = useDForm({}, {})

  const columns: ColumnsType<IStaticSelectFields> = [
    {
      dataIndex: 'form_name',
      title: 'Form',
    },
    {
      dataIndex: 'label',
      title: 'Field Name',
    },
    {
      dataIndex: 'action',
      title: 'Action',
      width: '200px',
      render: (_, col) => {
        return (
          <div className="d-flex align-items-center">
            <Button
              antdButtonProps={{
                onClick: () => {
                  searchParams.set('edit_field', col.id)
                  setSearchParams(searchParams)
                },
              }}
              size="extra_small"
              type="outlined"
            >
              Manage Options
            </Button>
          </div>
        )
      },
    },
  ]

  const handleEditClose = () => {
    searchParams.delete('edit_field')
    setSearchParams(searchParams)
  }

  return (
    <>
      <div className="d-flex flex-column w-100 px-24 py-24">
        <div className="d-flex w-100 justify-content-end">
          <div className="d-flex align-items-center ">
            <span className="text-body-medium fw-medium">Form:&nbsp; </span>
            <Select
              style={{
                width: '250px',
              }}
              value={formFilter}
              options={[
                {
                  value: 'all',
                  label: 'All',
                },
                ...(formsList?.map((el) => ({
                  value: el.id,
                  label: el.name,
                })) || []),
              ]}
              onChange={(value) => {
                searchParams.set('form', value)
                setSearchParams(searchParams)
              }}
            />
          </div>
        </div>
        <Table
          loading={isLoading}
          columns={columns}
          rowKey={'id'}
          dataSource={data || []}
          className="mt-12"
        />
      </div>

      <Drawer
        width={600}
        title={selectedField?.label}
        open={!!editField}
        onClose={handleEditClose}
      >
        <EditOptions />
      </Drawer>
    </>
  )
}

export default FormSelectFields
