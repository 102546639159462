import {
  useDeletePurchaseIndent,
  usePurchaseIndentInfo,
} from '@services/purchaseIndent'
import React, { FC, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Popover, Tooltip } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { DoubleChevronLeft, Edit, Print, Trash } from 'yolo-design/icons'
import cn from 'classnames'
import { Col, Divider, Row } from 'antd'
import Image from '@components/Image'
import PurchaseIndent from '@components/Print/PurchaseIndent'
import { useReactToPrint } from 'react-to-print'
import SkeletonButton from 'antd/es/skeleton/Button'

import {
  individualAvatarOne,
  individualAvatarTwo,
  individualAvatarThree,
  individualAvatarFour,
  individualAvatarFive,
} from 'yolo-design'
import { randomIntFromInterval } from '@utils/string'

const logos: Record<number, string> = {
  1: individualAvatarOne,
  2: individualAvatarTwo,
  3: individualAvatarThree,
  4: individualAvatarFour,
  5: individualAvatarFive,
}

const View: FC<{ refetch: any }> = ({ refetch }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const printCardRef = useRef(null)
  const [isPrinting, setIsPrinting] = useState(false)

  const print = useReactToPrint({
    documentTitle: 'print-card',
    pageStyle: '@page { size: 170mm 210mm; margin: 4mm; }',
    content: () => printCardRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true)
    },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
  })

  const viewId = searchParams.get('view_id') || ''

  const { data, isLoading } = usePurchaseIndentInfo({}, viewId)

  const deletePurIndent = useDeletePurchaseIndent({
    onSuccess: () => {
      refetch()
      searchParams.delete('view_id')
      setSearchParams(searchParams)
    },
  })

  return (
    <div className="d-flex flex-column w-100 position-relative">
      <div className="d-flex w-100 justify-content-between align-items-center border-bottom border-grey-300 p-16 py-12">
        <Button
          type="ghost"
          color={Color.background.dark}
          antdButtonProps={{
            onClick: () => {
              searchParams.delete('view_id')
              setSearchParams(searchParams)
            },
            disabled: isPrinting,
          }}
          icon={DoubleChevronLeft}
        >
          Close
        </Button>
        <div className="d-flex">
          {!!data?.indent_status_code && (
            <>
              {data?.indent_status_code === 2 ? (
                <Popover
                  trigger={'click'}
                  content={
                    <div className="d-flex flex-column px-4">
                      <span className="text-body-medium fw-medium">
                        Are you sure you want to delete this <br /> Purchase
                        Indent?
                      </span>
                      <div className="d-flex mt-12 justify-content-end">
                        <Button
                          size="small"
                          antdButtonProps={{
                            disabled: deletePurIndent.isLoading,
                          }}
                          type="outlined"
                        >
                          No
                        </Button>
                        <Button
                          size="small"
                          antdButtonProps={{
                            className: 'ms-12',
                            loading: deletePurIndent.isLoading,
                            onClick: () => {
                              deletePurIndent.mutate({ id: viewId })
                            },
                          }}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  }
                >
                  <Button
                    antdButtonProps={{
                      className: 'me-20',
                      disabled: isLoading || isPrinting,
                    }}
                    icon={Trash}
                    color={Color.indicators.error}
                    type="ghost"
                  >
                    Delete
                  </Button>
                </Popover>
              ) : (
                <Tooltip title="An Enquiry has created against this Purchase Indent">
                  <Button
                    antdButtonProps={{
                      className: 'me-20',
                      disabled: true,
                    }}
                    icon={Trash}
                    color={Color.indicators.error}
                    type="ghost"
                  >
                    Delete
                  </Button>
                </Tooltip>
              )}
              {data?.indent_status_code === 2 ? (
                <Button
                  antdButtonProps={{
                    className: 'me-20',
                    disabled: isLoading || isPrinting,
                    onClick: () => {
                      searchParams.delete('view_id')
                      searchParams.set('edit_id', viewId)
                      setSearchParams(searchParams)
                    },
                  }}
                  icon={Edit}
                  type="ghost"
                >
                  Edit
                </Button>
              ) : (
                <Tooltip title="An Enquiry has created against this Purchase Indent">
                  <Button
                    antdButtonProps={{
                      className: 'me-20',
                      disabled: true,
                    }}
                    icon={Edit}
                    type="ghost"
                  >
                    Edit
                  </Button>
                </Tooltip>
              )}
            </>
          )}
          <Button
            icon={Print}
            antdButtonProps={{
              disabled: isLoading,
              onClick: print,
              loading: isPrinting,
            }}
            type="ghost"
          >
            Print
          </Button>
        </div>
      </div>
      <div className="d-flex flex-column py-12">
        <div className="d-flex w-100 flex-column px-16">
          {isLoading ? (
            <SkeletonButton active style={{ width: '200px', height: '40px' }} />
          ) : (
            <span className="text-heading-large fw-medium">
              #{data?.indent_number}
            </span>
          )}
        </div>
        <Divider className="my-12" />
        <Row className="px-16">
          <Col span={6}>
            <div className="d-flex flex-column">
              <span className="text-label-small fw-semibold">INDENT DATE</span>
              {isLoading ? (
                <SkeletonButton
                  active
                  className="mt-4"
                  style={{ width: '50px', height: '20px' }}
                />
              ) : (
                <>
                  {!!data?.indent_date && (
                    <span className="mt-8 fw-medium">
                      {new Date(data?.indent_date || '').toLocaleDateString()}
                    </span>
                  )}
                </>
              )}
            </div>
          </Col>
          <Col span={6}>
            <div className="d-flex flex-column">
              <span className="text-label-small fw-semibold">NO OF ITEMS</span>
              {isLoading ? (
                <SkeletonButton
                  active
                  className="mt-4"
                  style={{ width: '50px', height: '20px' }}
                />
              ) : (
                <span className="mt-8 fw-medium">{data?.items?.length}</span>
              )}
            </div>
          </Col>
          <Col span={6}>
            <div className="d-flex flex-column">
              <span className="text-label-small fw-semibold">STATUS</span>
              {isLoading ? (
                <SkeletonButton
                  active
                  className="mt-4"
                  style={{ width: '80px', height: '20px' }}
                />
              ) : (
                <span
                  className={cn(
                    'px-8 py-2 rounded-4 w-max-content text-label-small mt-8 h-max-content fw-bold text-uppercase',
                    {
                      'bg-washed-orange': data?.indent_status_code === 2,
                      'bg-washed-green': data?.indent_status_code === 3,
                      'bg-washed-red': data?.indent_status_code === 4,
                      'bg-washed-neon': data?.indent_status_code === 5,
                    }
                  )}
                >
                  {data?.indent_status_name}
                </span>
              )}
            </div>
          </Col>
          <Col span={6}>
            <div className="d-flex flex-column">
              <span className="text-label-small fw-semibold ">CREATED BY</span>
              {isLoading ? (
                <SkeletonButton
                  active
                  className="mt-4"
                  style={{ width: '100px', height: '20px' }}
                />
              ) : (
                <div className="d-flex align-items-center mt-8">
                  <Image
                    src={logos[randomIntFromInterval(1, 5)]}
                    alt={data?.created_by}
                    width={24}
                    height={24}
                  />{' '}
                  <span className="ms-4 text-body-medium fw-medium">
                    {data?.created_by}
                  </span>{' '}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <div className="d-flex w-100  px-16 py-12 mt-12">
          <div className="d-flex border w-100 p-12">
            <PurchaseIndent elRef={printCardRef} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default View
