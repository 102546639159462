import { preventE, preventMinus, restrictInitialSpace } from 'yolo-design/utils'

export const restrictToNumbersAndDots = (e: any) => {
  const pattern = /^(\d+|\d*\.\d{1,2})$/
  if (!pattern.test(Number(e.target.value))) {
    const value = e.target.value.slice(0, -1)
    e.target.value = value
    e.preventDefault()
  }
  restrictInitialSpace(e)
}
export const formDecimalInputRestriction = (e: any) => {
  const maxLength = e.target.maxLength
  if (maxLength && maxLength > 0 && e.target.value.length > maxLength) {
    e.target.value = e.target.value.slice(0, maxLength)
  }
  const pattern = /^(\d+|\d*\.\d{1,3})$/
  if (!pattern.test(Number(e.target.value))) {
    const value = e.target.value.slice(0, -1)
    e.target.value = value
    e.preventDefault()
  }

  e.target.value = e.target.value.replace(
    /[^0-9.]|(\.(?=.*\.))|(\.[0-9]*\.[0-9]*)|^(\.[0-9]{1,3})|(\.\d{4,})/gi,
    ''
  )
  preventE(e)
  preventMinus(e)
  restrictInitialSpace(e)
}
