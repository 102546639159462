import React, { FC, useEffect, useState } from 'react'
import { IPurIndentReq } from '@contracts/models/purchase'
import { useAllProductList } from '@services/product'
import {
  useCreatePurchaseIndent,
  useEditPurchaseIndent,
  usePurchaseIndentInfo,
} from '@services/purchaseIndent'
import { Divider, Form } from 'antd'
import {
  Button,
  Date as DateComponent,
  FormInput,
  FormItem,
  FormList,
  FormSelect,
  FormWrapper,
  Select,
  Tooltip,
} from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { DoubleChevronLeft, Trash } from 'yolo-design/icons'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'

const Create: FC<{ handleCloseCreateDrawer: () => void; refetch: any }> = ({
  handleCloseCreateDrawer,
  refetch,
}) => {
  const [searchParams] = useSearchParams()
  const [form] = Form.useForm()
  const [search, setSearch] = useState('')
  const [isInitial, setIsInitial] = useState(true)
  const [selItem, setSelItem] = useState<string>()

  const editId = searchParams.get('edit_id') || ''

  const { data: purchaseIndentData, refetch: refetchInfo } =
    usePurchaseIndentInfo({}, editId)

  const { data: products, isLoading } = useAllProductList({}, { search })

  const createPurIndent = useCreatePurchaseIndent({
    onSuccess: () => {
      refetch()
      handleCloseCreateDrawer()
    },
  })

  const editPurIndent = useEditPurchaseIndent({
    onSuccess: () => {
      refetch()
      handleCloseCreateDrawer()
      refetchInfo()
    },
  })

  const handleFinish = (val: IPurIndentReq) => {
    const postData: IPurIndentReq = {
      indent_date: val?.indent_date,
      items: val?.items?.map((el) => ({
        item: el.item,
        quantity: el.quantity,
        unit: el.unit,
      })),
    }

    if (editId) {
      postData.id = editId

      editPurIndent.mutate({ id: editId, data: postData })
    } else {
      createPurIndent.mutate(postData)
    }
  }

  useEffect(() => {
    if (purchaseIndentData) {
      form.setFieldsValue({
        indent_date: dayjs(purchaseIndentData?.indent_date),
        items: purchaseIndentData?.items,
      })
    }
  }, [purchaseIndentData])

  return (
    <div className="d-flex flex-column w-100 position-relative">
      <div className="d-flex w-100 justify-content-between align-items-center border-bottom border-grey-300 p-16 py-8">
        <Button
          type="ghost"
          color={Color.background.dark}
          antdButtonProps={{
            onClick: handleCloseCreateDrawer,
            disabled: createPurIndent.isLoading,
          }}
          icon={DoubleChevronLeft}
        >
          Close
        </Button>
        <Tooltip
          title={
            !form.getFieldValue('items')?.length
              ? 'Select alteast one Item'
              : ''
          }
        >
          <Button
            antdButtonProps={{
              onClick: () => form.submit(),
              disabled: !form.getFieldValue('items')?.length,
              loading: createPurIndent.isLoading,
            }}
          >
            {editId ? 'Save' : 'Create'}
          </Button>
        </Tooltip>
      </div>
      <FormWrapper
        antdFormProps={{
          // style: { height: 'calc(100vh - 155px)' },
          className: 'd-flex flex-column w-100 px-12 pt-16 overflow-scroll',
          form,
          onFinish: handleFinish,
        }}
      >
        <FormItem
          required
          label="Indent Date"
          name={'indent_date'}
          className="w-50"
        >
          <DateComponent />
        </FormItem>
        <Divider className="mt-8 mb-12" />
        <FormItem noStyle shouldUpdate>
          {(form) => {
            const itemsLength = form?.getFieldValue('items')?.length
            return (
              <>
                <div className="d-flex w-100 mb-12 justify-content-between align-items-center">
                  <span className="fw-medium ">Items : {itemsLength || 0}</span>
                  <span
                    onClick={() => {
                      form.setFieldValue('items', [])
                    }}
                    className=" text-label-small fw-semibold text-error-red cursor-pointer"
                  >
                    Clear All Items
                  </span>
                </div>
                <FormList name={'items'}>
                  {(fields, { remove, add }) => {
                    const itemsList = form.getFieldValue('items')
                    return (
                      <>
                        {fields.map((el, i) => {
                          const currentItem = itemsList?.find(
                            (f: any) =>
                              f.item ===
                              form.getFieldValue(['items', el.name, 'item'])
                          )

                          return (
                            <div
                              key={el.key}
                              className="d-flex w-100 mb-16  border-bottom border-grey-200 pt-0 px-12"
                            >
                              <div className="d-flex w-100 flex-column justify-content-center h-100">
                                <span className="fw-medium text-label-large fw-semibold mt-8">
                                  {currentItem.name || currentItem.item_name}
                                </span>
                                <div className="d-flex mt-8">
                                  <span className="fw-semibold pe-12 text-label-small text-dark">
                                    SKU:{' '}
                                    {currentItem.sku_code ||
                                      currentItem.item_sku}
                                  </span>
                                  {!!currentItem.stock_on_hand && (
                                    <span className="fw-semibold  border-start border-grey-600 ps-12 text-label-small text-dark">
                                      Avail Qty: {currentItem.stock_on_hand}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex w-50 gap-2">
                                <FormInput
                                  name={[el.name, 'item']}
                                  inputType="number"
                                  showErrorMessage={false}
                                  andtFormItemProps={{
                                    hidden: true,
                                  }}
                                  required
                                />
                                <div className="d-flex flex-column w-50">
                                  <span className="text-label-small text-grey-700 fw-bold mb-4">
                                    QTY
                                  </span>
                                  <FormInput
                                    name={[el.name, 'quantity']}
                                    inputType="decimal"
                                    showErrorMessage={false}
                                    required
                                    antdInputProps={{
                                      id: `item-qty-${el.key}`,
                                      autoFocus: true,
                                    }}
                                  />
                                </div>
                                <div className="d-flex flex-column w-50">
                                  <span className="text-label-small text-grey-700  fw-bold mb-4">
                                    UNIT
                                  </span>
                                  <FormSelect
                                    name={[el.name, 'unit']}
                                    showErrorMessage={false}
                                    andtFormItemProps={{
                                      initialValue:
                                        currentItem.item_units?.find(
                                          (f: any) => f.is_primary
                                        )?.unit_id,
                                    }}
                                    optionsSelect={currentItem.item_units?.map(
                                      (el: any) => ({
                                        value: el.unit_id,
                                        label: el.unit,
                                      })
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <Button
                                  iconPosition="right"
                                  type="ghost"
                                  color={Color.indicators.error}
                                  icon={Trash}
                                  size="large"
                                  antdButtonProps={{
                                    onClick: () => {
                                      remove(i)
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          )
                        })}
                        <div className="d-flex flex-column w-100 pt-8  rounded-4">
                          <Select
                            // {...(isInitial ? { open: true } : {})}
                            autoFocus
                            allowClear
                            showSearch
                            onSearch={(val) => {
                              setSearch(val)
                            }}
                            onBlur={() => {
                              isInitial && setIsInitial(false)
                            }}
                            value={selItem}
                            loading={isLoading}
                            filterOption={false}
                            className="w-100"
                            size="large"
                            placeholder="Search Items by Name/SKU Code"
                            onSelect={(_, option: any) => {
                              add({
                                ...option,
                                item: option.id,
                              })
                              isInitial && setIsInitial(false)
                              setSelItem('Search Items by Name/SKU Code')
                            }}
                            optionLabelProp={'name'}
                            options={products?.data
                              ?.filter(
                                (f) =>
                                  !form
                                    .getFieldValue('item')
                                    ?.map((el: any) => el.id)
                                    ?.includes(f.id)
                              )
                              ?.map((el) => ({
                                value: el.id,
                                label: (
                                  <div className="d-flex flex-column w-100 border-bottom border-grey-200 pb-8">
                                    <span className="fw-medium">{el.name}</span>
                                    <span className="mt-4 text-label-small text-medium text-grey-500">
                                      {el.sku_code}
                                    </span>
                                  </div>
                                ),
                                ...el,
                              }))}
                          />
                        </div>
                      </>
                    )
                  }}
                </FormList>
              </>
            )
          }}
        </FormItem>
      </FormWrapper>
    </div>
  )
}

export default Create
