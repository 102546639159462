import RouteLink from '@components/RouteLink'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, SearchInput, Tab } from 'yolo-design/components'
import { Add } from 'yolo-design/icons'

const Head = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const search = searchParams.get('search') || ''
  const workStatus = searchParams.get('dispatch_status') || '1'

  const tabItems = [
    {
      key: '1',
      label: 'Active Dispatch',
    },
    {
      key: '2',
      label: 'Completed Dispatch',
    },
  ]

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = e.target.value
    if (val) {
      searchParams.set('search', val)
    } else {
      searchParams.delete('search')
    }
    setSearchParams(searchParams)
  }

  const handleTabChange = (key: string) => {
    searchParams.set('dispatch_status', key)
    setSearchParams(searchParams)
  }

  return (
    <div className="d-flex w-100 justify-content-between align-items-end px-24 py-16">
      <div className="d-flex">
        <Tab
          className="w-100"
          activeKey={workStatus}
          items={tabItems}
          onChange={handleTabChange}
          tabBarStyle={{ margin: 0 }}
        />
      </div>
      <div className="d-flex">
        <SearchInput
          onFocus={() => setIsSearchFocused(true)}
          onBlur={() => setIsSearchFocused(false)}
          style={{
            marginRight: '16px',
            width: isSearchFocused ? '360px' : '250px',
          }}
          onChange={handleSearch}
          value={search}
          placeholder="Search by Name/No"
        />
        <RouteLink to={'dispatch-form/?view=add'} addOrg>
          <Button icon={Add}>New Dispatch</Button>
        </RouteLink>
      </div>
    </div>
  )
}

export default Head
