import { useDFormKey } from '@services/dynamic'
import { IDFormKeyValueReq } from '@contracts/models/dynamic'

const useMapFieldKey = ({ formId }: { formId: string }) => {
  const formKey = useDFormKey({}, { form: formId })

  return (postData: Record<string, any>): IDFormKeyValueReq[] =>
    Object.keys(postData)
      .map((k) => ({
        form_key: formKey?.data?.find((f) => f.name === k)?.id,
        value: postData[k],
      }))
      .filter((f) => f.form_key !== undefined && f.value !== undefined)
}

export default useMapFieldKey
