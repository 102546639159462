import { IRegister, IRegisterRes } from '@contracts/models/user'
import { POST } from '@lib/AxiosClient'

export const postRegister = (data: IRegister): Promise<IRegisterRes> =>
  POST({
    url: 'core/register/',
    headers: { Authorization: null, Organization: null },
    data,
  })

export const postLogout = (): Promise<{ message: string }> =>
  POST({ url: `account/logout/` })
