import React, { FC, useEffect, useRef, useState } from 'react'
import {
  Button,
  Drawer,
  FormWrapper,
  Popover,
  Upload,
} from 'yolo-design/components'
import {
  Add,
  KababMenu,
  Print,
  ShortArrowLeft,
  Trash,
  UploadIcon,
} from 'yolo-design/icons'
import { docIcon } from '@assets/images'
import EditFieldItem from '@components/ItemEdit'
import Image from '@components/Image'
import { useParams, useSearchParams } from 'react-router-dom'
import { Color } from 'yolo-design/constants'
import { Form } from 'antd'
import JobFormFields from '@components/Customer/FormFields/JobFormFields'
import { IDFormKeyValue } from '@contracts/models/dynamic'
import {
  useDDesignFileId,
  useDFormJobId,
  useDFormTypeJobId,
  useDValidateFormKeyValue,
  useDeleteDListItem,
  useUpdateDFormKeyValue,
  useUpsertDListItem,
} from '@services/dynamic'
import useMapFieldKey from '@hooks/useMapFieldKey'
import { queries } from '@constants/queryKeys'
import { useQueryClient } from '@tanstack/react-query'
import { JobFormFieldsKey } from '@contracts/job'
import { useGetProductDetails } from '@services/product'
import { useOrgInfo, useUpload } from '@services/org'
import { getFormData, isLt50M } from '@utils/upload'
import { IUploadRes } from '@contracts/models/org'
import { useCustomerInfo } from '@services/customer'
import nProgress from 'nprogress'
import cn from 'classnames'
import styles from './style.module.scss'
import { defaultMutationErrFn } from '@utils/query'
import JobShadeCardPrint from '@components/Print/JobShadeCard'
import { useReactToPrint } from 'react-to-print'

const BasicInfo: FC<{ data: Record<string, any> }> = ({ data }) => {
  const queryClient = useQueryClient()
  const { customerId = '' } = useParams()
  const [editItem, setEditItem] = useState<JobFormFieldsKey>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [form] = Form.useForm()
  const [reUploadId, setReUploadId] = useState<string>()
  const [isPrinting, setIsPrinting] = useState(false)
  const printRef = useRef(null)
  const jobId = searchParams.get('job') || ''

  // const productList = useItemProductList({}, {})

  const formTypeId = useDFormTypeJobId() || ''
  const jobFormId = useDFormJobId(formTypeId) || ''
  const mapFieldKey = useMapFieldKey({ formId: jobFormId })

  const { data: customerInfo } = useCustomerInfo(
    { enabled: !!customerId },
    { id: customerId }
  )

  const { data: productList } = useGetProductDetails(
    { enabled: !!data?.linked_product },
    { id: data?.linked_product }
  )

  const { data: orgData } = useOrgInfo()

  const orgName = orgData?.name

  const handlePrint = useReactToPrint({
    documentTitle: data?.job_code,
    pageStyle: '@page { size: 80mm 50mm; margin: 0mm;}',
    content: () => printRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true)
    },
    onAfterPrint: () => {
      setIsPrinting(false)
    },
  })

  const designFileKeyId = useDDesignFileId({ formId: jobFormId })

  const updateForm = useUpdateDFormKeyValue({
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: queries.dynamic.form_key_value_list({
          formId: jobFormId,
          search: '',
          customer: customerInfo,
        }).queryKey,
      })
      queryClient.setQueryData<IDFormKeyValue>(
        queries.dynamic.form_key_value(jobId).queryKey,
        (prev) => ({ ...prev, job: data })
      )
      handleClose()
    },
  })

  const handleClose = () => {
    searchParams.delete('drawer')
    setSearchParams(searchParams)
    setEditItem(undefined)
  }

  const handleEdit = (name: JobFormFieldsKey) => {
    searchParams.set('drawer', 'nested')
    setSearchParams(searchParams)
    setEditItem(name)
  }

  const validateFormKeyValue = useDValidateFormKeyValue({
    onSuccess: ({ data }) => {
      updateForm.mutate({ data, formId: jobId })
    },
    onError: (res: any) => {
      defaultMutationErrFn(queryClient, res)

      form.setFields(
        res?.response?.data?.message?.map((el: any) => ({ ...el }))
      )
    },
  })

  const handleSubmit = (val: Partial<Record<JobFormFieldsKey, any>>) => {
    validateFormKeyValue.mutate({
      data: mapFieldKey(val),
      params: {
        entity: jobId,
      },
    })
  }

  const handleRemoveFile = (id: string) => {
    nProgress.start()

    deleteListItem.mutate({ id })
  }

  const handleSuccess = () => {
    queryClient
      .invalidateQueries({
        queryKey: queries.dynamic.form_key_value(jobId).queryKey,
      })
      .finally(() => {
        nProgress.done()
      })
  }

  const upsertListItem = useUpsertDListItem({
    onSuccess: () => {
      handleSuccess()
    },
  })

  const deleteListItem = useDeleteDListItem({
    onSuccess: () => {
      handleSuccess()
    },
  })

  const upload = useUpload({
    onSuccess: (res) => {
      const postData: any = {
        data: res,
      }

      if (reUploadId) {
        postData.id = String(reUploadId)
      }

      if (designFileKeyId && data?.id) {
        nProgress.start()
        upsertListItem.mutate({
          data: postData,
          path: { entityId: data?.id, keyId: designFileKeyId },
        })
      }
      setReUploadId(undefined)
    },
  })

  const menuContent = (id: string) => (
    <div className="d-flex flex-column w-max-content px-4">
      <Upload
        showUploadList={false}
        beforeUpload={(file) => isLt50M(file.size)}
        customRequest={({ file }: any) => {
          setReUploadId(id)
          upload.mutate(getFormData(file))
        }}
      >
        <Button
          color={Color.neutral_color.black}
          antdButtonProps={{
            className: 'mb-12',
          }}
          icon={UploadIcon}
          type="ghost"
        >
          Reupload
        </Button>
      </Upload>

      <Button
        antdButtonProps={{
          onClick: () => handleRemoveFile(id),
        }}
        color={Color.indicators.error}
        icon={Trash}
        type="ghost"
      >
        Delete
      </Button>
    </div>
  )

  useEffect(() => {
    if (editItem) {
      form.setFieldsValue(data)
    }
  }, [editItem])

  return (
    <>
      <div className="d-flex flex-column w-100 p-24">
        <span className="text-title-large fw-medium text-grey-700 mb-16">
          Basic Info
        </span>
        <EditFieldItem
          label="JOB NAME"
          value={data?.name}
          onEdit={() => handleEdit('name')}
        />
        <EditFieldItem
          label="JOB CODE"
          value={data?.job_code}
          onEdit={() => handleEdit('job_code')}
        />
        <EditFieldItem
          label="BARCODE"
          value={data?.barcode}
          onEdit={() => handleEdit('barcode')}
        />
        <EditFieldItem
          label="LINKED PRODUCT"
          value={productList?.name || '-'}
          onEdit={() => handleEdit('linked_product')}
        />
        <EditFieldItem
          hideBorder
          label={
            <div className="d-flex align-items-center">
              <span className="me-4">SHADE CARD LOCATION</span>{' '}
              {!!data?.shade_location_name && (
                <Button
                  type="ghost"
                  size="small"
                  color={Color.background.dark}
                  icon={Print}
                  antdButtonProps={{
                    onClick: handlePrint,
                    loading: isPrinting,
                  }}
                ></Button>
              )}
            </div>
          }
          value={data?.shade_location_name || '-'}
          onEdit={() => handleEdit('shade_location_name')}
        />
        <EditFieldItem
          label="SAMPLE LOCATION"
          value={data?.sample_location}
          onEdit={() => handleEdit('sample_location')}
        />
        <span className="text-title-large fw-medium text-grey-700 mb-16 mt-8">
          Design Details
        </span>
        <div className="d-flex flex-column">
          {data?.design_files?.map((el: { id: string; data: IUploadRes }) => (
            <div
              key={el?.id}
              className="d-flex w-100 justify-content-between border border-grey-200 rounded-8 p-8 mb-16"
            >
              <div className="d-flex align-items-center">
                <div className="d-flex w-max-content">
                  <Image width={36} height={48} src={docIcon} alt="" />
                </div>
                <div className="d-flex flex-column ms-8 pe-8">
                  <a href={el?.data?.file} target="_blank" rel="noreferrer">
                    <span
                      className={cn(
                        styles.file_link,
                        'text-title-small text-break fw-medium text-black truncate-text-2'
                      )}
                    >
                      {el?.data?.name || '-'}
                    </span>
                  </a>
                  <span className="text-label-small fw-medium text-grey-700 mt-8">
                    Size: {el?.data?.size}
                  </span>
                  <span className="text-label-small fw-medium text-grey-700 mt-4">
                    Last Updated:{' '}
                    {new Date(el?.data?.created_at).toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <Popover
                  placement="bottomRight"
                  title={null}
                  trigger={'click'}
                  content={menuContent(el.id)}
                  arrow={false}
                  align={{ offset: [0, -10] }}
                >
                  <KababMenu
                    className="cursor-pointer"
                    size="medium"
                    weight="slim"
                  />
                </Popover>
              </div>
            </div>
          ))}
        </div>
        <Upload
          showUploadList={false}
          beforeUpload={(file) => isLt50M(file.size)}
          customRequest={({ file }: any) => {
            upload.mutate(getFormData(file))
          }}
        >
          <Button
            antdButtonProps={{ loading: upload.isLoading }}
            size="large"
            type="outlined"
            icon={!upload.isLoading ? Add : undefined}
            block
          >
            Upload Design File Here
          </Button>
        </Upload>
      </div>

      <Drawer open={!!editItem} customHeader onClose={handleClose}>
        <div className="d-flex w-100 py-16 px-24 justify-content-between border-bottom border-grey-400 align-items-center">
          <Button
            type="ghost"
            size="large"
            icon={ShortArrowLeft}
            color={Color.background.dark}
            antdButtonProps={{
              onClick: handleClose,
              disabled: updateForm.isLoading,
            }}
          >
            Back
          </Button>
          <Button
            antdButtonProps={{
              onClick: () => form.submit(),
              loading: updateForm.isLoading,
            }}
            type="ghost"
            size="large"
          >
            Save
          </Button>
        </div>
        <div className="d-flex flex-column py-20 px-24 w-100">
          <FormWrapper antdFormProps={{ form, onFinish: handleSubmit }}>
            {editItem && <JobFormFields name={editItem} />}
          </FormWrapper>
        </div>
      </Drawer>

      <div className="d-none">
        <JobShadeCardPrint data={data} orgName={orgName} elRef={printRef} />
      </div>
    </>
  )
}

export default BasicInfo
