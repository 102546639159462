import React, { useEffect } from 'react'
import { Form, Row, message } from 'antd'
import { yoloManufactureLogo } from 'yolo-design/assets'
import { Button, FormWrapper } from 'yolo-design/components'
import Info from './Info'
import Preference from './Preference'
import { useSearchParams } from 'react-router-dom'
import { Check } from 'yolo-design/icons'
import useRouteNavigate from '@hooks/useRouteNavigate'
import {
  useCreateDFormKeyValue,
  useDFormKeyValue,
  useDFormTypeWorkId,
  useDFormWorkId,
  useDWorkOrder,
  useUniqueCode,
  useUpdateDFormKeyValue,
} from '@services/dynamic'
import useMapFieldKey from '@hooks/useMapFieldKey'
import RouteLink from '@components/RouteLink'
import { Color } from 'yolo-design/constants'
import Print from './Print'
import nProgress from 'nprogress'

const WorkView = () => {
  const [form] = Form.useForm()
  const [searchParams, setSearchParams] = useSearchParams()
  const routeNavigate = useRouteNavigate()

  const view = searchParams.get('view') || 'add'
  const jobId = searchParams.get('job') || ''
  const workId = searchParams.get('work') || ''

  const formTypeId = useDFormTypeWorkId()
  const workFormId = useDFormWorkId(formTypeId) || ''
  const mapFieldKey = useMapFieldKey({ formId: workFormId })

  const { data: jobData, isLoading: jobIsLoading } = useDFormKeyValue(
    {
      enabled: !!jobId,
    },
    { formId: jobId }
  )

  const { data, refetch } = useDWorkOrder(
    {
      enabled: !!workId,
    },
    { formId: workId }
  )

  const createWork = useCreateDFormKeyValue({
    onSuccess: (res: any) => {
      routeNavigate(`work-form?view=view&work=${res?.id}&job=${res?.job}`, {
        addOrg: true,
      })
    },
    onSettled: () => {
      nProgress.done()
      message.success({ content: 'Work Order Created!' })
    },
  })

  const updateWork = useUpdateDFormKeyValue({
    onSuccess: (res: any) => {
      refetch()
      routeNavigate(`work-form?view=view&work=${workId}&job=${res?.job}`, {
        addOrg: true,
      })
    },
    onSettled: () => {
      nProgress.done()
      message.success({ content: 'Work Order Updated!' })
    },
  })

  const { data: workCode } = useUniqueCode({ enabled: view === 'add' })

  const handleSave = (values: any) => {
    if (view === 'add') {
      nProgress.start()
      const postData = {
        ...values,
        job: jobId,
        started_date: new Date(),
        is_completed: false,
      }
      createWork.mutate({ formId: workFormId, data: mapFieldKey(postData) })
      return
    }

    if (view === 'edit') {
      nProgress.start()

      const postData = {
        ...values,
        job: jobId,
      }
      updateWork.mutate({ formId: workId, data: mapFieldKey(postData) })
      return
    }
  }

  const handleSetFormFields = (val: any) => {
    const { work_order_number, output_quantity, steps, wastage } = val

    form.setFieldsValue({
      work_order_number,
      output_quantity,
      wastage,
      steps: steps?.map((el: any) => String(el.code)),
    })
  }

  const handleComplete = () => {
    const postData = {
      completed_date: new Date(),
      is_completed: true,
      job_data: jobData,
    }

    updateWork.mutate({ formId: workId, data: mapFieldKey(postData) })
  }

  const handleCancel = () => {
    if (view === 'edit') {
      handleSetFormFields(data)
      searchParams.set('view', 'view')
      setSearchParams(searchParams)
      return
    } else {
      routeNavigate('work', { addOrg: true })
      return
    }
  }

  useEffect(() => {
    if (workCode?.code) {
      form.setFieldValue('work_order_number', workCode?.code)
    }
  }, [workCode?.code])

  useEffect(() => {
    if (data) {
      handleSetFormFields(data)
    }
  }, [data])

  return (
    <>
      <div className="d-flex w-100 flex-column">
        <div className="d-flex w-100 justify-content-between border-bottom border-grey-200 align-items-center py-8 px-24">
          <RouteLink to={'work'} addOrg>
            <img
              width={230}
              height={40}
              src={yoloManufactureLogo}
              alt="inventory"
            />
          </RouteLink>
          <div className="d-flex">
            {view === 'view' ? (
              <div className="d-flex">
                {data?.is_completed ? (
                  <Button
                    color={Color.indicators.success}
                    borderColor={Color.indicators.success}
                    type="outlined"
                    icon={Check}
                    antdButtonProps={{
                      className: 'me-16',
                    }}
                  >
                    Completed
                  </Button>
                ) : (
                  <>
                    <Button
                      type="outlined"
                      antdButtonProps={{
                        className: 'me-16',
                        onClick: () => {
                          searchParams.set('view', 'edit')
                          setSearchParams(searchParams)
                        },
                        disabled: updateWork.isLoading,
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      type="outlined"
                      antdButtonProps={{
                        className: 'me-16',
                        onClick: handleComplete,
                        loading: updateWork.isLoading,
                        disabled: jobIsLoading,
                      }}
                    >
                      Mark as Completed
                    </Button>
                  </>
                )}
                <Print />
              </div>
            ) : (
              <div className="d-flex">
                <Button
                  type="outlined"
                  antdButtonProps={{
                    className: 'me-16',
                    onClick: handleCancel,
                    disabled: createWork.isLoading || updateWork.isLoading,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  antdButtonProps={{
                    onClick: () => form.submit(),
                    loading: createWork.isLoading || updateWork.isLoading,
                  }}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
        <FormWrapper antdFormProps={{ form, onFinish: handleSave }}>
          <Row gutter={0} className="w-100">
            <Info />
            <Preference />
          </Row>
        </FormWrapper>
      </div>
    </>
  )
}

export default WorkView
